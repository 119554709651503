import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getSignalProvidersPage,
  SignalProvidersPageValue
} from '../utils/commonLinkUtil';
import React, { useCallback, useContext } from 'react';
import TenantContext from '../hooks/TenantContext';
import _ from 'lodash';
import { ProcessMapDropdownShowMenuCallback } from './useProcessMapDropdown';
import sortByLocaleCompare from '../utils/sortByLocaleCompare';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useNodeTraits } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import APIGen, { NodeTraitResponseModel } from 'ecto-common/lib/API/APIGen';
import T from 'ecto-common/lib/lang/Language';

const useEquipmentLinkAction = ({
  nodeId,
  showMenu,
  hideMenu
}: {
  nodeId: string;
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
}) => {
  const navigate = useNavigate();
  const params = useParams<NodeParams>();

  const { contextSettings } = useContext(TenantContext);
  const traitsQuery = useNodeTraits();
  const [searchParams] = useSearchParams();

  const [activeTrait, setActiveTrait] =
    React.useState<NodeTraitResponseModel>(null);
  const [targetCoordinates, setTargetCoordinates] = React.useState<{
    x: number;
    y: number;
  }>(null);

  const searchForNodesMutation = APIGen.NodesV2.searchForNodes.useMutation({
    onSuccess: (data) => {
      if (data.nodes.length === 0) {
        hideMenu();
        return;
      }

      const sortedEquipment = sortByLocaleCompare(data.nodes, 'name');
      showMenu(
        sortedEquipment.map((equipment) => ({
          label: equipment.name,
          disableCloseOnClick: true,
          subtitle: beautifyEquipmentName(activeTrait.name),
          icon: <Icons.ArrowRight />,
          action: () => {
            hideMenu();
            navigate(
              getSignalProvidersPage(
                contextSettings.tenantId,
                nodeId,
                // Maintain search params if we're navigating internally to another signal providers page
                params.page === SignalProvidersPageValue ? searchParams : null
              )
            );
          }
        })),
        {
          x: targetCoordinates.x,
          y: targetCoordinates.y
        }
      );
    },
    onError: () => {
      hideMenu();
    }
  });

  return useCallback(
    (event: MouseEvent, equipmentTypeId: string) => {
      if (nodeId == null) {
        return;
      }

      const correspondingTrait = traitsQuery.data?.items?.find(
        (x) => x.equipmentTypeId === equipmentTypeId
      );

      setActiveTrait(correspondingTrait);

      if (correspondingTrait == null) {
        return;
      }

      searchForNodesMutation.mutate({
        pageSize: 10,
        rootNodeId: nodeId,
        nodeTraitIds: [correspondingTrait.id],
        nodePropertySearchRequestModels: [],
        searchPhrase: ''
      });

      showMenu(
        [
          {
            label: T.common.loading,
            subtitle: beautifyEquipmentName(correspondingTrait.name),
            disableCloseOnClick: true,
            icon: <Icons.ArrowRight />,
            action: () => {}
          }
        ],
        {
          x: event.clientX,
          y: event.clientY
        }
      );

      setTargetCoordinates({
        x: event.clientX,
        y: event.clientY
      });
    },
    [nodeId, traitsQuery.data?.items, searchForNodesMutation, showMenu]
  );
};

export default useEquipmentLinkAction;
