import {
  ProcessMapObjectHandle,
  ProcessMapObjectTypes,
  ProcessMapRect,
  ProcessMapRectHandle,
  processMapDeleteConnectionButtonSize,
  smallConnectionCircleRadius
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { Draft, current } from 'immer';
import _ from 'lodash';
import {
  MatrixPair,
  ProcessMapState,
  ProcessMapViewState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import {
  lineConnectionsIterator,
  overlapsProcessMapRectMouseCoord,
  rectConnectionsIterator,
  rectIterator,
  rectLineConnectionsIterator,
  setProcessMapRectCenterRounded,
  symbolConnectionsIterator,
  symbolLineConnectionsIterator
} from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';

export const ProcessMapCopyMarker = 'ProcessMapCopy';

export const ProcessMapEditorActionUtils = {
  updateDraggedConnections(state: Draft<ProcessMapState>) {
    const movedRectIds: string[] = [
      ...state.selectedRectHandles.map((handle) => handle.rectId)
    ];
    let prevMovedRectsLength = -1;

    while (prevMovedRectsLength !== movedRectIds.length) {
      prevMovedRectsLength = movedRectIds.length;

      for (const [lineRect1, lineRect2] of lineConnectionsIterator(
        state.processMap
      )) {
        if (
          movedRectIds.includes(lineRect1.id) &&
          !movedRectIds.includes(lineRect2.id)
        ) {
          setProcessMapRectCenterRounded(
            lineRect2,
            lineRect1.centerX,
            lineRect1.centerY
          );
          movedRectIds.push(lineRect2.id);
        } else if (
          movedRectIds.includes(lineRect2.id) &&
          !movedRectIds.includes(lineRect1.id)
        ) {
          setProcessMapRectCenterRounded(
            lineRect1,
            lineRect2.centerX,
            lineRect2.centerY
          );
          movedRectIds.push(lineRect1.id);
        }
      }

      for (const [
        symbolRect,
        lineRect,
        connectionPointX,
        connectionPointY
      ] of symbolLineConnectionsIterator(state.processMap)) {
        if (
          movedRectIds.includes(symbolRect.id) &&
          !movedRectIds.includes(lineRect.id)
        ) {
          setProcessMapRectCenterRounded(
            lineRect,
            connectionPointX,
            connectionPointY
          );
          movedRectIds.push(lineRect.id);
        } else if (
          movedRectIds.includes(lineRect.id) &&
          !movedRectIds.includes(symbolRect.id)
        ) {
          setProcessMapRectCenterRounded(
            symbolRect,
            symbolRect.centerX + lineRect.centerX - connectionPointX,
            symbolRect.centerY + lineRect.centerY - connectionPointY
          );
          movedRectIds.push(symbolRect.id);
        }
      }

      for (const [
        rect,
        lineRect,
        connectionPointX,
        connectionPointY
      ] of rectLineConnectionsIterator(state.processMap)) {
        if (
          movedRectIds.includes(rect.id) &&
          !movedRectIds.includes(lineRect.id)
        ) {
          setProcessMapRectCenterRounded(
            lineRect,
            connectionPointX,
            connectionPointY
          );
          movedRectIds.push(lineRect.id);
        } else if (
          movedRectIds.includes(lineRect.id) &&
          !movedRectIds.includes(rect.id)
        ) {
          setProcessMapRectCenterRounded(
            rect,
            rect.centerX + lineRect.centerX - connectionPointX,
            rect.centerY + lineRect.centerY - connectionPointY
          );
          movedRectIds.push(rect.id);
        }
      }
    }
  },
  handleMouseDownInConnection: (
    state: Draft<ProcessMapState>,
    mouseDocumentPosition: PointObjectNotation
  ) => {
    let didDeleteLineConnection = false;
    let didDeleteSymbolConnection = false;
    let didDeleteRectConnection = false;

    const zoomScale = state.transform.current.a;

    if (state.showDeleteConnections) {
      for (const [
        lineRect1,
        lineRect2,
        lineConnectionIndex,
        lineObject1,
        lineObject2
      ] of lineConnectionsIterator(state.processMap)) {
        const tmpRect1: ProcessMapRect = {
          centerX: lineRect1.centerX,
          centerY: lineRect1.centerY,
          width: processMapDeleteConnectionButtonSize,
          height: processMapDeleteConnectionButtonSize,
          id: null
        };
        const tmpRect2: ProcessMapRect = {
          centerX: lineRect2.centerX,
          centerY: lineRect2.centerY,
          width: processMapDeleteConnectionButtonSize,
          height: processMapDeleteConnectionButtonSize,
          id: null
        };

        if (
          (state.selectedRectHandles.some(
            (handle) =>
              handle.rectId === lineRect1.id || handle.rectId === lineRect2.id
          ) &&
            overlapsProcessMapRectMouseCoord(
              mouseDocumentPosition.x,
              mouseDocumentPosition.y,
              tmpRect1,
              lineObject1,
              zoomScale
            )) ||
          overlapsProcessMapRectMouseCoord(
            mouseDocumentPosition.x,
            mouseDocumentPosition.y,
            tmpRect2,
            lineObject2,
            zoomScale
          )
        ) {
          state.processMap.lineConnections[
            lineConnectionIndex
          ].markedForDeletion = true;
          didDeleteLineConnection = true;
        }
      }

      for (const [
        symbolConnectionRect,
        symbolObject,
        ,
        ,
        connectionId
      ] of symbolConnectionsIterator(
        state.processMap,
        state.connectionCircleRadius
      )) {
        const hitPoint = overlapsProcessMapRectMouseCoord(
          mouseDocumentPosition.x,
          mouseDocumentPosition.y,
          symbolConnectionRect,
          symbolObject,
          zoomScale
        );

        if (
          hitPoint &&
          state.selectedRectHandles.find(
            (handle) => handle.objectId === symbolObject.id
          ) != null
        ) {
          for (const connection of state.processMap.symbolLineConnections) {
            if (
              connection.symbolObjectHandle.objectId === symbolObject.id &&
              connection.connectionId === connectionId
            ) {
              connection.markedForDeletion = true;
              didDeleteSymbolConnection = true;
            }
          }
        }
      }

      for (const [
        rectConnectionRect,
        rectObject,
        ,
        connectionIndex
      ] of rectConnectionsIterator(
        state.processMap,
        state.connectionCircleRadius
      )) {
        const hitPoint = overlapsProcessMapRectMouseCoord(
          mouseDocumentPosition.x,
          mouseDocumentPosition.y,
          rectConnectionRect,
          rectObject,
          zoomScale
        );

        if (
          hitPoint &&
          state.selectedRectHandles.find(
            (handle) => handle.objectId === rectObject.id
          ) != null
        ) {
          for (const connection of state.processMap.rectLineConnections) {
            if (
              connection.rectObjectHandle.objectId === rectObject.id &&
              connection.connectionIndex === connectionIndex
            ) {
              connection.markedForDeletion = true;
              didDeleteRectConnection = true;
            }
          }
        }
      }
    }

    if (state.showDeleteConnections) {
      state.showDeleteConnections = false;
    }

    if (didDeleteLineConnection) {
      _.remove(
        state.processMap.lineConnections,
        (connection) => connection.markedForDeletion
      );
    }

    if (didDeleteSymbolConnection) {
      _.remove(
        state.processMap.symbolLineConnections,
        (connection) => connection.markedForDeletion
      );
    }

    if (didDeleteRectConnection) {
      _.remove(
        state.processMap.rectLineConnections,
        (connection) => connection.markedForDeletion
      );
    }

    if (didDeleteSymbolConnection || didDeleteLineConnection) {
      ProcessMapEditorActionUtils.pushUndoStack(state);
    }

    return didDeleteLineConnection;
  },
  setTransform(
    state: Draft<ProcessMapState> | Draft<ProcessMapViewState>,
    transform: MatrixPair
  ) {
    state.transform = transform;
    const currentScale = transform.current.a;

    if ('connectionCircleRadius' in state) {
      state.connectionCircleRadius = Math.min(
        smallConnectionCircleRadius * 1.35,
        Math.max(
          smallConnectionCircleRadius,
          smallConnectionCircleRadius * currentScale
        )
      );
    }
  },
  getRectHandles: (state: Draft<ProcessMapState>) => {
    const allRectHandles: ProcessMapRectHandle[] = [
      ...state.selectedRectHandles,
      ...state.hoverRectHandles,
      ...(state.mouseState.rectSizeInfo ? [state.mouseState.rectSizeInfo] : []),
      ...(state.pendingConnectionLine
        ? [state.pendingConnectionLine.lineRectHandle]
        : []),
      ...state.processMap.symbolLineConnections.map(
        (connection) => connection.lineObjectRectHandle
      ),
      ...state.processMap.rectLineConnections.map(
        (connection) => connection.lineObjectRectHandle
      )
    ];

    return allRectHandles;
  },
  getObjectHandles: (state: Draft<ProcessMapState>) => {
    const allObjectHandles: ProcessMapObjectHandle[] = [
      ...(state.pendingConnectionSymbol
        ? [state.pendingConnectionSymbol.symbolHandle]
        : []),
      ...state.processMap.lineConnections.flatMap(
        (connection) => connection.rectHandles
      ),
      ...state.processMap.symbolLineConnections.map(
        (connection) => connection.symbolObjectHandle
      ),
      ...state.processMap.rectLineConnections.map(
        (connection) => connection.rectObjectHandle
      )
    ];

    return allObjectHandles;
  },
  fixHandles(state: Draft<ProcessMapState>) {
    const allRectHandles = ProcessMapEditorActionUtils.getRectHandles(state);

    for (const rectHandle of allRectHandles) {
      rectHandle.objectIndex = state.processMap.objects.findIndex(
        (obj) => obj.id === rectHandle.objectId
      );
      const node = state.processMap.objects[rectHandle.objectIndex];
      rectHandle.rectIndex = node.rects.findIndex(
        (rect) => rect.id === rectHandle.rectId
      );

      if (rectHandle.rectIndex === -1) {
        console.error('ERROR: Rect not found!', rectHandle);
      }
    }

    const allObjectHandles =
      ProcessMapEditorActionUtils.getObjectHandles(state);

    for (const objectHandle of allObjectHandles) {
      objectHandle.objectIndex = state.processMap.objects.findIndex(
        (obj) => obj.id === objectHandle.objectId
      );
    }
  },
  reorderSelected(
    state: Draft<ProcessMapState>,
    mode: 'toback' | 'tofront' | 'forward' | 'back'
  ) {
    const objects = _.uniq(
      state.selectedRectHandles.map(
        (handle) => state.processMap.objects[handle.objectIndex]
      )
    );
    const firstIndex = state.processMap.objects.findIndex((x) =>
      objects.includes(x)
    );

    state.processMap.objects = state.processMap.objects.filter(
      (testObject) => !objects.includes(testObject)
    );

    if (mode === 'toback') {
      state.processMap.objects = [...objects, ...state.processMap.objects];
    } else if (mode === 'tofront') {
      state.processMap.objects.push(...objects);
    } else if (mode === 'forward') {
      state.processMap.objects.splice(firstIndex + 1, 0, ...objects);
    } else if (mode === 'back') {
      state.processMap.objects.splice(
        Math.max(0, firstIndex - 1),
        0,
        ...objects
      );
    }

    ProcessMapEditorActionUtils.fixHandles(state);
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  setUndoIndex(state: Draft<ProcessMapState>, index: number) {
    // console.log('Set undo index to ' + index + ' of ' + original(state.undoStack));
    state.selectedRectHandles = [];
    state.undoStackIndex = index;
    state.processMap = _.cloneDeep(
      current(state).undoStack[state.undoStackIndex]
    );
  },
  onCopy(state: Draft<ProcessMapState>) {
    const lineConnections = _.map(
      _.uniq(
        state.processMap.lineConnections.filter((connection) => {
          return (
            state.selectedRectHandles.some(
              (handle) =>
                handle.objectIndex === connection.rectHandles[0].objectIndex
            ) &&
            state.selectedRectHandles.some(
              (handle) =>
                handle.objectIndex === connection.rectHandles[1].objectIndex
            )
          );
        })
      ),
      (connection) => current(connection)
    );
    const symbolLineConnections = _.map(
      _.uniq(
        state.processMap.symbolLineConnections.filter((connection) => {
          return (
            state.selectedRectHandles.some(
              (handle) =>
                handle.objectIndex === connection.symbolObjectHandle.objectIndex
            ) &&
            state.selectedRectHandles.some(
              (handle) =>
                handle.objectIndex ===
                connection.lineObjectRectHandle.objectIndex
            )
          );
        })
      ),
      (connection) => current(connection)
    );

    const rectLineConnections = _.map(
      _.uniq(
        state.processMap.rectLineConnections.filter((connection) => {
          return (
            state.selectedRectHandles.some(
              (handle) =>
                handle.objectIndex === connection.rectObjectHandle.objectIndex
            ) &&
            state.selectedRectHandles.some(
              (handle) =>
                handle.objectIndex ===
                connection.lineObjectRectHandle.objectIndex
            )
          );
        })
      ),
      (connection) => current(connection)
    );

    const objects = _.map(
      _.uniq(
        state.selectedRectHandles.map(
          (handle) => state.processMap.objects[handle.objectIndex]
        )
      ),
      (object) => current(object)
    );

    const json = JSON.stringify({
      type: ProcessMapCopyMarker,
      data: {
        objects: _.cloneDeep(objects),
        lineConnections: _.cloneDeep(lineConnections),
        symbolLineConnections: _.cloneDeep(symbolLineConnections),
        rectLineConnections: _.cloneDeep(rectLineConnections),
        svgImages: _.cloneDeep(current(state.processMap.svgImages)),
        width: state.processMap.width,
        height: state.processMap.height
      }
    });

    navigator.clipboard.writeText(json);
  },
  selectAll: (state: Draft<ProcessMapState>) => {
    const selectedHandles: ProcessMapRectHandle[] = [];
    for (const [rect, object, objectIndex, rectIndex] of rectIterator(
      state.processMap
    )) {
      selectedHandles.push({
        objectId: object.id,
        rectId: rect.id,
        objectIndex,
        rectIndex
      });
    }

    state.selectedRectHandles = selectedHandles;
  },
  clearSelectedItems(state: Draft<ProcessMapState>) {
    _.remove(state.processMap.objects, (obj) =>
      state.selectedRectHandles.some((handle) => handle.objectId === obj.id)
    );
    _.remove(state.processMap.lineConnections, (obj) =>
      state.selectedRectHandles.some((handle) => {
        return obj.rectHandles.some(
          (objectHandle) => objectHandle.objectId === handle.objectId
        );
      })
    );
    _.remove(state.processMap.symbolLineConnections, (obj) =>
      state.selectedRectHandles.some((handle) => {
        return (
          obj.symbolObjectHandle.objectId === handle.objectId ||
          obj.lineObjectRectHandle.objectId === handle.objectId
        );
      })
    );
    _.remove(state.processMap.rectLineConnections, (obj) =>
      state.selectedRectHandles.some((handle) => {
        return (
          obj.rectObjectHandle.objectId === handle.objectId ||
          obj.lineObjectRectHandle.objectId === handle.objectId
        );
      })
    );

    state.hoverRectHandles = [];
    state.selectedRectHandles = [];
    ProcessMapEditorActionUtils.fixHandles(state);
    ProcessMapEditorActionUtils.pushUndoStack(state);
    ProcessMapEditorActionUtils.trimImageDatabase(state);
  },
  pushUndoStack(state: Draft<ProcessMapState>) {
    const originalState = current(state);
    const undoCandidate = _.cloneDeep(originalState.processMap);

    if (
      state.undoStack.length === 0 ||
      !_.isEqual(state.undoStack[state.undoStack.length - 1], undoCandidate)
    ) {
      state.undoStack = state.undoStack.slice(0, state.undoStackIndex + 1);
      state.undoStack.push(_.cloneDeep(originalState.processMap));
      state.undoStackIndex = state.undoStack.length - 1;
      state.hasChanges = true;
    }
  },
  trimImageDatabase(state: Draft<ProcessMapState>) {
    const activeImageMd5s: string[] = [];
    for (const object of state.processMap.objects) {
      if (object.type === ProcessMapObjectTypes.Symbol) {
        activeImageMd5s.push(object.svgMd5);
      }
    }

    const newSvgImages: Record<string, string> = {};
    for (const imgMd5 of activeImageMd5s) {
      newSvgImages[imgMd5] = state.processMap.svgImages[imgMd5];
    }

    state.processMap.svgImages = newSvgImages;
  },
  addSvg: (state: Draft<ProcessMapState>, data: string, dataMd5: string) => {
    state.processMap.svgImages[dataMd5] = data;
  }
};
