import React, { useMemo } from 'react';
import styles from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker.module.css';
import CopyToClipboardTooltip from '../CopyToClipboardTooltip/CopyToClipboardTooltip';
import {
  useCurrentNode,
  useNodeTraits
} from 'ecto-common/lib/hooks/useCurrentNode';
import APIGen from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';
import T from 'ecto-common/lib/lang/Language';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

const ToolbarNodeProperties = () => {
  const { nodeId, currentNode } = useCurrentNode();

  const allNodeTraitsQuery = useNodeTraits();
  const enums = useCommonSelector((state) => state.general.enums);

  const propertiesQuery =
    APIGen.NodesV2.getNodePropertyValuesWithProperties.useQuery(
      {
        nodeId
      },
      {
        enabled: nodeId != null && nodeId !== ROOT_NODE_ID
      }
    );

  const columns = useMemo(() => {
    const language = getSelectedLanguage();

    const properties = sortByLocaleCompare(propertiesQuery.data ?? [], 'name');
    const ret: React.ReactNode[][] = _.chunk(
      _.map(properties, (property) => {
        const title = property.localization?.[language] ?? property.name;
        const unitName =
          property.unitId != null
            ? enums.units.find((u) => u.id === property.unitId)?.name
            : null;
        const unitSuffix = unitName != null ? ` ${unitName}` : '';

        return (
          <div key={property.propertyId} className={styles.row}>
            <label>{title}:</label>
            <CopyToClipboardTooltip valueToCopy={property.value}>
              {property.value != null ? property.value + unitSuffix : '-'}
            </CopyToClipboardTooltip>
          </div>
        );
      }),
      2
    );

    const nodeTraitStrings = sortByLocaleCompare(
      _.compact(
        currentNode?.nodeTraits.map((traitRelation) => {
          const trait = allNodeTraitsQuery.data?.items?.find(
            (t) => t.id === traitRelation.nodeTraitId
          );

          return trait?.name;
        })
      )
    );

    const nodeTraitInfo =
      nodeTraitStrings.length > 0 ? (
        <div className={styles.row} key="traitInfo">
          <label>{T.nodes.nodetraits}: </label>
          <div className={styles.tagContainer}>
            <TagsGroup tags={nodeTraitStrings} />
          </div>
        </div>
      ) : null;

    let lastColumn = ret[ret.length - 1];

    if (lastColumn == null || lastColumn.length === 2) {
      lastColumn = [nodeTraitInfo];
      ret.push(lastColumn);
    } else {
      lastColumn.push(nodeTraitInfo);
    }

    return ret;
  }, [
    allNodeTraitsQuery.data?.items,
    currentNode?.nodeTraits,
    enums.units,
    propertiesQuery.data
  ]);

  return (
    <div className={styles.properties}>
      {columns.map((column, idx) => (
        <div key={idx}>{column}</div>
      ))}
    </div>
  );
};

export default ToolbarNodeProperties;
