import React, { useCallback, useContext } from 'react';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteModal from 'ecto-common/lib/ConfirmDeleteDialog/DeleteModal';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import PresentationAPIGen from '../API/PresentationAPIGen';

const removePersonalDashboardPromise = (
  contextSettings: ApiContextSettings,
  id: string
) => {
  return IdentityServiceAPIGenV2.User.deleteUserDashboard.promise(
    contextSettings,
    { dashboardId: id },
    null
  );
};

type DeleteDashboardType = { id?: string; name?: string };

interface DashboardDeleteModalProps {
  deleteItem?: DeleteDashboardType;
  onModalClose(): void;
  onSuccess?(deleteItem: DeleteDashboardType): void;
  handlePersonalDashboards?: boolean;
}

const DashboardDeleteModal = ({
  deleteItem,
  onModalClose,
  onSuccess,
  handlePersonalDashboards
}: DashboardDeleteModalProps) => {
  const { contextSettings } = useContext(TenantContext);

  const deleteDashboardMutationOld = useMutation({
    mutationFn: () => {
      return removePersonalDashboardPromise(contextSettings, deleteItem.id);
    },

    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.dashboards.remove.success);
      onModalClose();
      onSuccess?.(deleteItem);
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.dashboards.remove.failure);
    }
  });
  const queryClient = useQueryClient();

  const deleteDashboardMutation =
    PresentationAPIGen.TenantDashboards.deleteTenantDashboard.useMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey:
            PresentationAPIGen.TenantDashboards.listTenantDashboards.path(
              contextSettings
            )
        });

        toastStore.addSuccessToast(T.admin.dashboards.remove.success);
        onModalClose();
        onSuccess?.(deleteItem);
      },
      meta: { errorString: T.admin.dashboards.remove.failure }
    });

  const isPending =
    deleteDashboardMutationOld.isPending || deleteDashboardMutation.isPending;

  const onConfirmDelete = useCallback(() => {
    if (handlePersonalDashboards) {
      deleteDashboardMutationOld.mutate();
    } else {
      deleteDashboardMutation.mutate({ id: deleteItem.id });
    }
  }, [
    deleteDashboardMutation,
    deleteDashboardMutationOld,
    deleteItem?.id,
    handlePersonalDashboards
  ]);

  return (
    <DeleteModal
      isLoading={isPending}
      deleteItem={deleteItem}
      onConfirmDelete={onConfirmDelete}
      onModalClose={onModalClose}
      itemName={deleteItem?.name}
    />
  );
};

export default React.memo(DashboardDeleteModal);
