import { useMemo } from 'react';
import { NodeType } from '../API/APIGen';
import T from '../lang/Language';
import _ from 'lodash';
import { useNodeTraits } from 'ecto-common/lib/hooks/useCurrentNode';

const useNodeTypes = () => {
  const nodeTraitsQuery = useNodeTraits();

  const legacySuffix = ' (v1)';
  return useMemo(
    () => [
      { type: NodeType.Building, name: T.nodetypes.building + legacySuffix },
      { type: NodeType.Site, name: T.nodetypes.site + legacySuffix },
      { type: NodeType.Equipment, name: T.nodetypes.equipment + legacySuffix },

      ...(nodeTraitsQuery.data?.items ?? []).map((trait) => ({
        type: trait.id,
        name: trait.name
      }))
    ],
    [nodeTraitsQuery.data]
  );
};
export default useNodeTypes;
