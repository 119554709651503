import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import TimeRangeSelector, {
  TimeRangeSelectOption
} from 'ecto-common/lib/TimeRangeSelector/TimeRangeSelector';
import _ from 'lodash';
import { TIME_RANGE_OPTIONS } from 'ecto-common/lib/TimeRangeSelector/TimeRangeSelector';
import { TimeRangeContextType } from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import moment, { Moment } from 'moment/moment';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import T from 'ecto-common/lib/lang/Language';
type DateFuncType = (now: Moment | string | number, sign: -1 | 1) => Moment;

const createStartOfDateFunction = (
  amount: number,
  unit: moment.unitOfTime.DurationConstructor,
  samplingInterval: moment.unitOfTime.StartOf
): DateFuncType => {
  return (now: Moment | string, sign: -1 | 1) =>
    moment(now)
      .add(amount * sign, unit)
      .startOf(samplingInterval);
};

export const timeRangeOffsetFunctions: Record<string, DateFuncType> = {
  [TimeRangeOptions.HOUR]: createStartOfDateFunction(1, 'hour', 'hour'),
  [TimeRangeOptions.DAY]: createStartOfDateFunction(1, 'day', 'day'),
  [TimeRangeOptions.WEEK]: createStartOfDateFunction(7, 'day', 'day'),
  [TimeRangeOptions.MONTH]: createStartOfDateFunction(1, 'month', 'month'),
  [TimeRangeOptions.YEAR]: createStartOfDateFunction(1, 'year', 'year'),
  [TimeRangeOptions.FIVE_YEARS_BACK]: createStartOfDateFunction(
    5,
    'years',
    'year'
  )
};

const getTimeRangesFromConsumers = (
  consumers: React.MutableRefObject<Record<string, TimeRangeOptions[]>>
): TimeRangeOptions[] => {
  const allTimeRanges = _.flatMap(consumers.current);
  return _.uniqBy(allTimeRanges, _.identity);
};

const getHasFreeRangeSelector = (
  consumers: React.MutableRefObject<Record<string, TimeRangeOptions[]>>
) => {
  return _.some(_.values(consumers.current), (x) => x.length === 0);
};

/**
 * Handles a time range selector, and whether or not it should be visible.
 * Use this toghether with the TimeRangeContext and the selector will toggle visiblity depending on usage of the panels
 * @returns {[{addTimeRangeConsumer: function, removeTimeRangeConsumer: function, timeRangeOption: string}, selector]}
 */
const useTimeRangeSelector = (): [TimeRangeContextType, React.ReactNode] => {
  const [timeRangeOption, setTimeRangeOption] = useSearchParamState(
    'range-option',
    TimeRangeOptions.DAY
  );
  const [referenceDate, setReferenceDate] = useSearchParamState(
    'range-date',
    null
  );

  const [timeRangeVisibility, setTimeRangeVisibility] = useState(false);
  const [allTimeRanges, setAllTimeRanges] = useState<string[]>([]);
  const [hasFreeRangeSelector, setHasFreeRangeSelector] = useState(false);
  const consumers = useRef<Record<string, TimeRangeOptions[]>>({});
  const isAlive = useRef(false);
  useEffect(() => {
    isAlive.current = true;
    return () => {
      isAlive.current = false;
    };
  });

  const timeRangeOptions: TimeRangeSelectOption[] = useMemo(() => {
    let options = TIME_RANGE_OPTIONS;

    if (!hasFreeRangeSelector) {
      options = _.filter(TIME_RANGE_OPTIONS, (option) =>
        allTimeRanges.includes(option.value)
      );
    }

    // This only works for pre-aggregated data, so only show if if a source actually has defined data
    // for this period.
    if (!allTimeRanges.includes(TimeRangeOptions.FIVE_YEARS_BACK)) {
      options = _.reject(options, { value: TimeRangeOptions.FIVE_YEARS_BACK });
    }

    return options;
  }, [allTimeRanges, hasFreeRangeSelector]);

  useEffect(() => {
    if (
      timeRangeOptions.length > 0 &&
      _.find(timeRangeOptions, { value: timeRangeOption }) == null
    ) {
      setTimeRangeOption(_.head(timeRangeOptions).value as TimeRangeOptions);
    }
  }, [setTimeRangeOption, timeRangeOption, timeRangeOptions]);

  const referenceDateMoment = useMemo(() => {
    return referenceDate ? moment(referenceDate) : null;
  }, [referenceDate]);

  const setReferenceDateMoment = useCallback(
    (date: Moment | ((newDate: Moment) => Moment)) => {
      if (_.isFunction(date)) {
        setReferenceDate(date(referenceDateMoment)?.toISOString());
      } else {
        setReferenceDate(date?.toISOString());
      }
    },
    [referenceDateMoment, setReferenceDate]
  );

  const moveTime = useCallback(
    (direction: -1 | 1) => {
      const nextDate = timeRangeOffsetFunctions[timeRangeOption]?.(
        referenceDateMoment ?? moment(),
        direction
      );
      setReferenceDate(nextDate?.toISOString());
    },
    [referenceDateMoment, setReferenceDate, timeRangeOption]
  );

  const selector = useMemo(() => {
    return (
      <>
        {timeRangeVisibility && (
          <Flex direction={LayoutDirection.HORIZONTAL}>
            <FlexItem>
              <ToolbarItem>
                <ToolbarMenu>
                  <ToolbarMenuButton
                    tooltipText={T.common.back}
                    icon={<Icons.NavigationArrowLeft />}
                    onClick={() => moveTime(-1)}
                  />
                  <ToolbarMenuButton
                    tooltipText={T.common.forward}
                    icon={<Icons.NavigationArrowRight />}
                    onClick={() => moveTime(1)}
                  />
                </ToolbarMenu>
              </ToolbarItem>
            </FlexItem>
            <FlexItem>
              <TimeRangeSelector
                options={timeRangeOptions}
                value={timeRangeOption as TimeRangeOptions}
                referenceDate={referenceDateMoment}
                onReferenceDateChanged={setReferenceDateMoment}
                onValueChanged={setTimeRangeOption}
              />
            </FlexItem>
          </Flex>
        )}
      </>
    );
  }, [
    timeRangeVisibility,
    timeRangeOptions,
    timeRangeOption,
    referenceDateMoment,
    setReferenceDateMoment,
    setTimeRangeOption,
    moveTime
  ]);

  const addTimeRangeConsumer = useCallback(
    (consumer: string, specifiedTimeRanges: TimeRangeOptions[]) => {
      consumers.current[consumer] = specifiedTimeRanges;
      _.defer(() => {
        if (isAlive.current) {
          setTimeRangeVisibility(_.keys(consumers.current).length > 0);
          setAllTimeRanges(getTimeRangesFromConsumers(consumers));
          setHasFreeRangeSelector(getHasFreeRangeSelector(consumers));
        }
      });
    },
    []
  );

  const removeTimeRangeConsumer = useCallback((consumer: string) => {
    delete consumers.current[consumer];
    _.defer(() => {
      if (isAlive.current) {
        setTimeRangeVisibility(_.keys(consumers.current).length > 0);
        setAllTimeRanges(getTimeRangesFromConsumers(consumers));
        setHasFreeRangeSelector(getHasFreeRangeSelector(consumers));
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      referenceDate: referenceDateMoment,
      timeRangeOption: timeRangeOption as TimeRangeOptions,
      addTimeRangeConsumer,
      removeTimeRangeConsumer
    }),
    [
      referenceDateMoment,
      timeRangeOption,
      addTimeRangeConsumer,
      removeTimeRangeConsumer
    ]
  );

  return [value, selector];
};

export default useTimeRangeSelector;
