import React from 'react';
import _ from 'lodash';
import {
  ProcessMapSymbolObject,
  processMapRotationOptions
} from '../ProcessMapViewConstants';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ProcessMapSymbolRuleEditor } from 'ecto-common/lib/ProcessMaps/ProcessMapSymbolRuleEditor';
import { ProcessMapSymbolEditor } from 'ecto-common/lib/ProcessMaps/ProcessMapSymbolEditor';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';

export const symbolSections = (
  previewNodeId: string,
  svgImages: Record<string, string>,
  library: SymbolModel[],
  states: string[]
): ModelFormSectionType<ProcessMapSymbolObject>[] => {
  return [
    {
      label: T.admin.processmaps.objecteditor.symbolobject,
      lines: _.compact([
        {
          models: [
            {
              key: (input) => input.svgMd5,
              modelType: ModelType.CUSTOM,
              render: (props) => (
                <ProcessMapSymbolEditor
                  {...props}
                  svgImages={svgImages}
                  library={library}
                />
              )
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.flipvertical,
              key: (input) => input.flipVertical,
              modelType: ModelType.BOOL
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.fliphorizontal,
              key: (input) => input.flipHorizontal,
              modelType: ModelType.BOOL
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.rotation,
              key: (input) => input.rotation,
              modelType: ModelType.OPTIONS,
              options: processMapRotationOptions
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.scale,
              key: (input) => input.scale,
              modelType: ModelType.NUMBER,
              placeholder: '1.0'
            }
          ]
        },
        states?.length > 0 && {
          models: [
            {
              label: T.admin.processmaps.objecteditor.symbolrules,
              key: (input) => input.symbolRules,
              modelType: ModelType.CUSTOM,
              render: (props, model) => (
                <ProcessMapSymbolRuleEditor
                  {...props}
                  nodeId={previewNodeId}
                  states={states}
                  keyPath={getPathFromModelKeyFunc(model.key).join('-')}
                />
              )
            }
          ]
        }
      ])
    },
    {
      label: T.admin.processmaps.objecteditor.hideitem,
      initiallyCollapsed: false,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignal,
              key: (input) => input.hideWhenNoSignalTypeId,
              modelType: ModelType.SIGNAL_TYPE,
              isClearable: true,
              helpText: T.admin.processmaps.objecteditor.hiderequiredsignalhelp,
              nodeId: previewNodeId
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignalid,
              key: (input) => input.hideWhenNoSignalId,
              modelType: ModelType.SIGNAL,
              isClearable: true,
              helpText:
                T.admin.processmaps.objecteditor.hiderequiredsignalidhelp,
              nodeId: previewNodeId
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderulesignals,
              key: (input) => input.hideWhenNoSignalsForRules,
              visible: (input) => input.symbolRules?.length > 0,
              modelType: ModelType.BOOL,
              helpText: T.admin.processmaps.objecteditor.hiderulesignalshelp
            }
          ]
        }
      ]
    }
  ];
};
