import React, {
  Fragment,
  useCallback,
  useMemo,
  useSyncExternalStore
} from 'react';
import classNames from 'classnames';
import styles from './ProcessMapObjectViews.module.css';
import {
  ProcessMapRectObject,
  ProcessMapRectResizeArea,
  ProcessMapRectResizeAreas,
  ProcessMapViewSignal,
  connectionCircleRadiusPixels,
  defaultRectTitleSettings,
  defaultTextSettings,
  processMapDeleteConnectionButtonSize
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import colors from 'ecto-common/lib/styles/variables/colors';
import { ProcessMapRectShapes } from '../ProcessMapViewConstants';
import {
  ConnectionModelPoints,
  evaluateProcessMapColorRule,
  getRectConnectionPoints
} from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import removeIcon from '../ProcessMapDeleteIcon.svg';
import {
  ProcessMapObjectsProps,
  ProcessMapOverlayObjectProps
} from 'ecto-common/lib/ProcessMap/ProcessMapObjectProps';
import _ from 'lodash';
import { getTextStyle } from 'ecto-common/lib/ProcessMap/View/ProcessMapTextView';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';

export const ProcessMapRectView = React.memo(
  ({
    allSignalsBySignalTypeOrSignalId,
    node: nodeIn,
    signalData,
    objectIndex,
    selectedRectHandles,
    isHovering,
    onMouseOut,
    onMouseOver,
    onClick
  }: ProcessMapObjectsProps) => {
    const node = nodeIn as ProcessMapRectObject;
    const amongSelected = selectedRectHandles.some(
      (handle) => handle.objectIndex === objectIndex && handle.rectIndex === 0
    );
    let fillColorFromRule: string = null;
    let signal: ProcessMapViewSignal = null;
    let isWritableSignal = false;

    if (node.fillColorRuleName != null) {
      fillColorFromRule = evaluateProcessMapColorRule(
        node.fillColorRuleName,
        node.fillColorRuleSignalTypeIds,
        node.fillColorRuleSignalIds,
        allSignalsBySignalTypeOrSignalId,
        signalData
      );

      const signalIdOrTypeId =
        _.find(
          node.fillColorRuleSignalTypeIds,
          (id) => allSignalsBySignalTypeOrSignalId[id] != null
        ) ??
        _.find(
          node.fillColorRuleSignalIds,
          (id) => allSignalsBySignalTypeOrSignalId[id] != null
        );

      if (signalIdOrTypeId != null) {
        signal = allSignalsBySignalTypeOrSignalId[signalIdOrTypeId];
        isWritableSignal = signalData[signal?.signalId]?.isWritable;
      }
    }

    const _onClick = useCallback(
      (event: MouseEvent) => {
        onClick?.(event, node, signal, isWritableSignal);
      },
      [onClick, node, signal, isWritableSignal]
    );

    const _onMouseOver = useCallback(
      (event: MouseEvent) => {
        onMouseOver?.(event, node, signal, isWritableSignal);
      },
      [onMouseOver, node, signal, isWritableSignal]
    );

    const _onMouseOut = useCallback(
      (event: MouseEvent) => {
        onMouseOut?.(event, node, signal, isWritableSignal);
      },
      [onMouseOut, node, signal, isWritableSignal]
    );

    const isDragging = amongSelected;

    const rect = node.rects[0];

    let rotationTransform = '';

    if (node.rotation != null) {
      rotationTransform = ' rotate(' + node.rotation + ')';
    }
    const shape = node.shape ?? ProcessMapRectShapes.Rectangle;

    const sharedProps = {
      className: classNames(
        isDragging && styles.dragging,
        isHovering && styles.hovering
      ),
      x: -rect.width / 2.0,
      y: -rect.height / 2.0,
      width: rect.width,
      height: rect.height,
      stroke: node.strokeColor,
      strokeWidth: node.strokeWidth,
      fill: fillColorFromRule ?? node.fillColor ?? 'transparent',
      rx:
        shape === ProcessMapRectShapes.Circle
          ? rect.width / 2.0
          : (node.cornerRadius ?? 0),
      ry:
        shape === ProcessMapRectShapes.Circle
          ? rect.height / 2.0
          : (node.cornerRadius ?? 0),
      shapeRendering: 'auto',
      onMouseOver: (event: React.MouseEvent<SVGElement, MouseEvent>) =>
        _onMouseOver?.(event.nativeEvent),
      onMouseOut: (event: React.MouseEvent<SVGElement, MouseEvent>) =>
        _onMouseOut?.(event.nativeEvent),
      onClick: (event: React.MouseEvent<SVGElement, MouseEvent>) =>
        _onClick?.(event.nativeEvent),
      style: { cursor: onClick ? 'pointer' : 'default' }
    };

    const points = [
      `${0},${-rect.height / 2}`,
      `${rect.width / 2},${rect.height / 2}`,
      `${-rect.width / 2},${rect.height / 2}`
    ].join(' ');

    const darkModeEnabled = useSyncExternalStore(
      darkModeStore.subscribe,
      darkModeStore.getSnapshot
    );
    const lines = useMemo(() => {
      return (node.text ?? '').split('\n');
    }, [node.text]);

    const titleFontSize =
      node.labelTextSettings?.fontSize || defaultTextSettings.fontSize;
    const lineHeight = 1.2;
    const totalTextHeight = titleFontSize * lineHeight * lines.length;

    const offsetPixels = titleFontSize * lineHeight * 0.25;

    const startY = Math.floor(
      (rect.height - totalTextHeight) / 2 + titleFontSize - offsetPixels
    );

    return (
      <>
        <g
          transform={
            'translate(' +
            rect.centerX +
            ', ' +
            rect.centerY +
            ')' +
            rotationTransform
          }
        >
          {shape === ProcessMapRectShapes.Triangle && (
            <polygon points={points} {...sharedProps} />
          )}
          {shape !== ProcessMapRectShapes.Triangle && <rect {...sharedProps} />}

          {node.text != null && (
            <svg
              width={Math.round(rect.width)}
              height={Math.round(rect.height)}
              onMouseOver={(event) => _onMouseOver?.(event.nativeEvent)}
              onMouseOut={(event) => _onMouseOut?.(event.nativeEvent)}
              onClick={(event) => _onClick?.(event.nativeEvent)}
              x={Math.round(-rect.width / 2.0)}
              y={Math.round(-rect.height / 2.0)}
            >
              <text
                x="50%"
                y={startY}
                dominantBaseline="middle"
                textAnchor="middle"
                className={styles.text}
                style={{
                  userSelect: 'none',
                  cursor: onClick ? 'pointer' : 'default'
                }}
              >
                {lines.map((line, idx) => (
                  <tspan
                    fontSize={titleFontSize + 'px'}
                    textAnchor="middle"
                    x="50%"
                    key={line + idx}
                    dx={0}
                    dy={
                      idx === 0
                        ? undefined
                        : Math.ceil(titleFontSize * lineHeight)
                    }
                    style={getTextStyle(
                      {
                        ...defaultRectTitleSettings,
                        ...node.labelTextSettings
                      },
                      darkModeEnabled
                    )}
                  >
                    {line === '' ? ' ' : line}
                  </tspan>
                ))}
              </text>
            </svg>
          )}
        </g>
      </>
    );
  }
);

export const ProcessMapRectViewOverlay = React.memo(
  ({
    node,
    selectedRectHandles,
    objectIndex,
    zoom,
    isHovering,
    setRectResizeElement,
    rectLineConnections,
    connectionCircleRadius,
    pendingConnectionRect,
    showDeleteConnections,
    draggingSingleLinePoint
  }: ProcessMapOverlayObjectProps) => {
    const amongSelected = selectedRectHandles.some(
      (handle) => handle.objectId === node.id
    );

    const renderConnectionPoints =
      (isHovering && draggingSingleLinePoint && !amongSelected) ||
      (amongSelected && showDeleteConnections);
    const rectNode = node as ProcessMapRectObject;

    const rect = node.rects[0];
    const topLeftX = rect.centerX - rect.width / 2.0;
    const topLeftY = rect.centerY - rect.height / 2.0;

    const onMouseDown = useCallback(
      (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setRectResizeElement(
          objectIndex,
          0,
          e.currentTarget.getAttribute('data-area') as ProcessMapRectResizeArea
        );
      },
      [objectIndex, setRectResizeElement]
    );

    const connectionPoints = getRectConnectionPoints(rectNode);
    const radius = connectionCircleRadiusPixels / zoom;

    if (!amongSelected && !isHovering) {
      return null;
    }

    return (
      <>
        {renderConnectionPoints && (
          <g
            transform={'translate(' + rect.centerX + ', ' + rect.centerY + ')'}
            key={node.id}
          >
            <rect
              x={-rect.width * 0.5}
              y={-rect.height * 0.5}
              width={rect.width}
              height={rect.height}
              fill={'rgba(0, 0, 0, 0.05)'}
            />

            {connectionPoints.map((connection, idx) => {
              const connectionPointCoords = ConnectionModelPoints(
                false,
                false,
                rectNode.rotation,
                rect,
                connection
              );

              const showDeleteConnection =
                showDeleteConnections &&
                rectLineConnections.some(
                  (rectConnection) =>
                    rectConnection.connectionIndex === idx &&
                    rectConnection.rectObjectHandle.objectId === node.id
                );
              const isPending =
                pendingConnectionRect?.rectHandle.objectId === node.id &&
                pendingConnectionRect.connectionIndex === idx;

              const circleRadius = connectionCircleRadius / zoom;
              const deleteSize = processMapDeleteConnectionButtonSize / zoom;
              return (
                <Fragment key={idx}>
                  <rect
                    shapeRendering="auto"
                    x={connectionPointCoords.x - circleRadius - rect.centerX}
                    y={connectionPointCoords.y - circleRadius - rect.centerY}
                    width={circleRadius * 2}
                    height={circleRadius * 2}
                    rx={circleRadius}
                    ry={circleRadius}
                    fill={
                      isPending ? colors.primary1Color : colors.accent1Color
                    }
                  />
                  {showDeleteConnection && (
                    <image
                      href={removeIcon}
                      x={
                        connectionPointCoords.x -
                        deleteSize * 0.5 -
                        rect.centerX
                      }
                      y={
                        connectionPointCoords.y -
                        deleteSize * 0.5 -
                        rect.centerY
                      }
                      width={deleteSize}
                      height={deleteSize}
                      shapeRendering="auto"
                    />
                  )}
                </Fragment>
              );
            })}
          </g>
        )}

        {!renderConnectionPoints && (
          <>
            <circle
              onMouseDown={onMouseDown}
              cx={topLeftX}
              cy={topLeftY}
              r={radius}
              fill={colors.accent1Color}
              data-area={ProcessMapRectResizeAreas.TopLeft}
              shapeRendering="auto"
              style={{ cursor: 'nw-resize' }}
            />
            <circle
              onMouseDown={onMouseDown}
              cx={topLeftX + rect.width}
              cy={topLeftY + rect.height}
              r={radius}
              fill={colors.accent1Color}
              shapeRendering="auto"
              data-area={ProcessMapRectResizeAreas.BottomRight}
              style={{ cursor: 'se-resize' }}
            />
          </>
        )}
      </>
    );
  }
);
