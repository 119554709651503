import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import UUID from 'uuidjs';
import useFormInputWithValidation from 'ecto-common/lib/ModelForm/useFormInputWithValidation';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import _ from 'lodash';
import { modelFormSectionsToModels } from 'ecto-common/lib/ModelForm/formUtils';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

export interface ItemWithId {
  id?: string;
}

interface ModelFormDialogProps<
  ValueType extends ItemWithId,
  SaveAsArray extends boolean,
  EnvironmentType extends object
> {
  title?: string;
  onConfirmClick?(): void;
  models?: ModelDefinition<ValueType>[];
  sections?: ModelFormSectionType<ValueType>[];
  input?: ValueType;
  editTitle?: React.ReactNode | ((input: ValueType) => React.ReactNode);
  addTitle?: React.ReactNode | ((input: ValueType) => React.ReactNode);
  actionText?: string;
  isSavingInput?: boolean;
  saveInput: SaveAsArray extends true
    ? (input: Array<ValueType>) => void
    : (input: ValueType) => void;
  onModalClose: () => void;
  isDeletingInput?: boolean;
  deleteInput?(input: ValueType): void;
  nameKey?: string;
  saveAsArray?: SaveAsArray;
  isLoading?: boolean;
  isObjectNew?(...args: unknown[]): boolean;
  environment?: EnvironmentType;
}

function ModelFormDialog<
  ValueType extends ItemWithId,
  SaveAsArray extends boolean = true,
  EnvironmentType extends object = object
>({
  models = [],
  sections = undefined,
  input,
  editTitle = undefined,
  addTitle,
  isSavingInput,
  saveInput,
  isDeletingInput = false,
  deleteInput = undefined,
  onModalClose,
  nameKey = 'name',
  saveAsArray = true as SaveAsArray,
  isLoading = false,
  isObjectNew = undefined,
  actionText = T.common.save,
  environment,
  ...actionModalProps
}: ModelFormDialogProps<ValueType, SaveAsArray, EnvironmentType>) {
  const [editedInput, setEditedInput] = useState<ValueType>(null);
  const [isValidForm, setIsValidForm] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const _deleteInput = useCallback(() => {
    if (isConfirmingDelete) {
      deleteInput(input);
    } else {
      setIsConfirmingDelete(true);
    }
  }, [isConfirmingDelete, deleteInput, input]);

  const clearConfirmingDelete = useCallback(
    () => setIsConfirmingDelete(false),
    []
  );

  useEffect(() => {
    if (input === null) {
      setIsConfirmingDelete(false);
    }

    setEditedInput(input ? _.cloneDeep(input) : null);
  }, [input]);

  const hasId = editedInput?.id != null;
  const isNew = isObjectNew != null ? isObjectNew(editedInput) : !hasId;

  const confirm = useCallback(() => {
    // TODO: This should probably just send a single item?
    const newObject: ValueType = {
      ...editedInput,
      id: hasId ? editedInput.id : UUID.generate()
    };

    // TODO: Improve typescript handling here
    if (saveAsArray) {
      // @ts-expect-error
      saveInput([newObject]);
    } else {
      // @ts-expect-error
      saveInput(newObject);
    }
  }, [editedInput, saveInput, saveAsArray, hasId]);

  const allModels = useMemo(() => {
    if (sections != null) {
      return modelFormSectionsToModels(sections);
    }

    return models;
  }, [models, sections]);

  const onUpdateInput = useFormInputWithValidation(
    editedInput,
    setEditedInput,
    setIsValidForm,
    allModels
  );
  const hasChanges =
    isNew ||
    (editedInput &&
      input &&
      editedInput.id === input.id &&
      !_.isEqual(editedInput, input));

  const editTitleContent = _.isFunction(editTitle)
    ? editTitle(editedInput)
    : editTitle;
  const addTitleContent = _.isFunction(addTitle)
    ? addTitle(editedInput)
    : addTitle;

  return (
    <>
      <ActionModal
        isOpen={isLoading || editedInput != null}
        onModalClose={onModalClose}
        headerIcon={isNew ? Icons.Add : Icons.Edit}
        onConfirmClick={confirm}
        title={isNew ? addTitleContent : editTitleContent}
        actionText={actionText}
        disableActionButton={!isValidForm || !hasChanges}
        isLoading={isSavingInput || isDeletingInput || isLoading}
        leftSideButton={
          deleteInput && (
            <DeleteButton disabled={isNew} onClick={_deleteInput}>
              {T.common.delete}
            </DeleteButton>
          )
        }
        {...actionModalProps}
      >
        <div>
          {editedInput && (
            <>
              <ModelForm
                input={editedInput}
                models={models}
                sections={sections}
                onUpdateInput={onUpdateInput}
                environment={environment}
              />
            </>
          )}
        </div>
      </ActionModal>
      <ConfirmDeleteDialog
        onDelete={_deleteInput}
        itemName={_.get(input, nameKey)}
        onModalClose={clearConfirmingDelete}
        isOpen={isConfirmingDelete && editedInput != null}
        isLoading={isDeletingInput}
      />
    </>
  );
}

export default typedMemo(ModelFormDialog);
