import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import styles from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric.module.css';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';
import { getSignalColor } from 'ecto-common/lib/SignalSelector/StockChart.config';
import {
  ChartSignal,
  ChartSignalSettingsType
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { getSignalNameWithSignalType } from 'ecto-common/lib/SignalSelector/SignalUtils';
import SignalItemCell from './SignalItemCell';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { getSignalAggregationModels } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import { modelFormSectionsToModels } from 'ecto-common/lib/ModelForm/formUtils';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ColorPickerDialog from 'ecto-common/lib/ColorPickerDialog/ColorPickerDialog';
import { ColorResult } from 'react-color';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  FullSignalProviderResponseModel,
  GraphicalRepresentation,
  SamplingInterval
} from 'ecto-common/lib/API/APIGen';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import Icons from 'ecto-common/lib/Icons/Icons';

const getSignalItem = (signal: ChartSignal) => {
  if (!signal.item.signalTypeId) {
    // TODO: I think this is a workaround for older collectinos that don't have signalTypeId
    // We should remove it - signals in the group should never be set.
    const signalInfo = _.find(
      (signal.group as FullSignalProviderResponseModel).signals ?? [],
      { signalId: signal.item.signalId }
    );

    return { ...signal.item, ...signalInfo };
  }

  return signal.item;
};

interface SelectedSignalsTableProps {
  selectedSignals?: ChartSignal[];
  onDeleteAllSignalsClick?: MouseEventHandler<HTMLButtonElement>;
  onRemoveClick?(signal: ChartSignal): void;
  getNodeName?(signal: ChartSignal): string;
  onColorChange?(color: ColorResult, index: number): void;
  showSignalType?: boolean;
  onSignalSettingsChanged?(key: string[], value: unknown, index: number): void;
  onCreateStandardAggregations?(editedSignalIndex: number): void;
}

type ChartSignalWithInset = ChartSignal & {
  inset: boolean;
  originalSignal: ChartSignal;
};

const signalIsRaw = (signal: ChartSignal) => {
  return (
    signal.settings?.samplingInterval == null ||
    signal.settings?.samplingInterval === SamplingInterval.Raw
  );
};

export const SelectedSignalsTable = ({
  selectedSignals,
  onDeleteAllSignalsClick,
  onRemoveClick,
  getNodeName,
  onColorChange,
  onSignalSettingsChanged,
  onCreateStandardAggregations,
  showSignalType = false
}: SelectedSignalsTableProps) => {
  const [editSignalIndex, setEditSignalIndex] = useState<number>(-1);
  const editSignal =
    editSignalIndex === -1 ? null : selectedSignals[editSignalIndex];

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const groupedRows = useMemo(() => {
    const groupedSignals = _.groupBy(
      selectedSignals,
      (signal) => signal.item.signalId
    );

    return _.flatMap(Object.values(groupedSignals), (value) => {
      // Make sure the raw signal (if any) is always first
      const allSignals = value.sort((a, b) => {
        const aOrder = signalIsRaw(a) ? 0 : 1;
        const bOrder = signalIsRaw(b) ? 0 : 1;
        return aOrder - bOrder;
      });

      return allSignals.map((signal, index) => {
        return {
          ...signal,
          inset: index !== 0,
          originalSignal: signal
        };
      });
    });
  }, [selectedSignals]);

  const signalColor =
    editSignal != null ? getSignalColor(editSignal, editSignal.index) : null;

  const _onColorChange = useCallback(
    (color: ColorResult) => {
      onColorChange?.(color, editSignalIndex);
    },
    [editSignalIndex, onColorChange]
  );

  const _onSignalSettingsChanged = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string[], value: any) => {
      onSignalSettingsChanged?.(key, value, editSignalIndex);
    },
    [editSignalIndex, onSignalSettingsChanged]
  );

  const signalSettings = editSignal?.settings ?? {};

  const placeholderGraphicalRepresentation =
    T.graphs.representations[
      editSignal?.item?.graphicalRepresentation?.toLowerCase() as unknown as keyof typeof T.graphs.representations
    ] ?? '';

  const sections: ModelFormSectionType<ChartSignalSettingsType>[] = [
    {
      lines: [
        {
          models: [
            ...getSignalAggregationModels(
              false,
              true,
              T.graphs.settingplaceholder
            )
          ]
        },
        {
          models: [
            {
              modelType: ModelType.OPTIONS,
              label: T.admin.equipmenttemplates.graphicalrepresentation,
              key: (input) => input.graphicalRepresentation,
              placeholder: placeholderGraphicalRepresentation,
              options: enumValues(GraphicalRepresentation).map((value) => {
                return {
                  label:
                    T.graphs.representations[
                      value.toLowerCase() as unknown as keyof typeof T.graphs.representations
                    ] ?? value,
                  value
                };
              })
            }
          ]
        }
      ]
    }
  ];

  const isValid = modelFormIsValid(
    modelFormSectionsToModels(sections),
    signalSettings
  );

  const footer = onColorChange && onSignalSettingsChanged && (
    <div className={styles.footer}>
      <ModelForm
        sections={sections}
        input={signalSettings}
        onUpdateInput={_onSignalSettingsChanged}
      />
    </div>
  );

  const columns = [
    {
      dataKey: 'name',
      dataFormatter: (
        _val: unknown,
        signal: ChartSignalWithInset,
        signalIdx: number
      ) => {
        return (
          <SignalItemCell
            title={getSignalNameWithSignalType(
              getSignalItem(signal),
              showSignalType,
              signalTypesMap,
              signalUnitTypesMap
            )}
            inset={signal.inset}
            name={getNodeName(signal)}
            color={
              onColorChange
                ? getSignalColor(signal, signal.index ?? signalIdx)
                : null
            }
            aggregation={signal.settings?.aggregation}
            samplingInterval={signal.settings?.samplingInterval}
            showAggregation={onSignalSettingsChanged != null}
          />
        );
      }
    },
    ...standardColumns({
      extraButtons:
        onCreateStandardAggregations != null
          ? [
              {
                icon: <Icons.Duplicate />,
                action(_item, index) {
                  onCreateStandardAggregations(index);
                },
                tooltipText: T.graphs.createaggregations
              }
            ]
          : [],
      onEdit:
        onColorChange || onSignalSettingsChanged
          ? (_signal: ChartSignal, row: number) => {
              setEditSignalIndex(row);
            }
          : null,
      onDelete: (signal: ChartSignalWithInset) => {
        onRemoveClick(signal.originalSignal);
      }
    })
  ];

  return (
    <>
      <span className={styles.selectedSignalsHeader}>
        <span className={styles.selectedSignalsHeaderLabel}>
          {T.operatorchart.signalpicker.selectedsignals.header}
        </span>

        <Button
          onClick={onDeleteAllSignalsClick}
          children={<>{T.common.clear}</>}
          compact
          className={styles.selectedSignalsHeaderButton}
        />
      </span>
      <DataTable
        disableHeader
        inline
        className={styles.dataTable}
        columns={columns}
        data={groupedRows}
      />

      <ColorPickerDialog
        isValid={isValid}
        isOpen={editSignal != null}
        onClose={() => setEditSignalIndex(-1)}
        onChange={_onColorChange}
        color={signalColor}
        title={editSignal?.item?.name ?? undefined}
        footer={footer}
      />
    </>
  );
};
