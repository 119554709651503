import _ from 'lodash';
import {
  ProcessMapRectObject,
  processMapColorRules,
  ProcessMapRectShapes,
  processMapRotationOptions,
  defaultRectTitleSettings
} from '../ProcessMapViewConstants';
import {
  ModelFormLineType,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  textAlignmentOption,
  textStyleSections
} from 'ecto-common/lib/ProcessMap/Model/commonModels';

const processMapColorRuleOptions = Object.keys(processMapColorRules).map(
  (key) => ({
    label: key,
    value: key
  })
);

const colorRuleArgumentOptionLines = (
  argLabel: string,
  ruleName: string,
  previewNodeId: string
): ModelFormLineType<ProcessMapRectObject>[] => {
  if (ruleName == null) {
    return [];
  }

  const rule = processMapColorRules[ruleName];
  const ruleLines: ModelFormLineType<ProcessMapRectObject>[] = [];

  for (let i = 0; i < rule.numSignalValues; i++) {
    ruleLines.push({
      models: [
        {
          key: (input) => input.fillColorRuleSignalTypeIds[i],
          label:
            argLabel +
            ' ' +
            T.format(
              T.admin.processmaps.objecteditor.signaltypeindexformat,
              i + 1
            )
              .join('')
              .toLowerCase(),
          modelType: ModelType.SIGNAL_TYPE,
          nodeId: previewNodeId,
          isClearable: true
        }
      ]
    });
    ruleLines.push({
      models: [
        {
          key: (input) => input.fillColorRuleSignalIds[i],
          label:
            argLabel +
            ' ' +
            T.format(
              T.admin.processmaps.objecteditor.signalidindexformat,
              i + 1
            )
              .join('')
              .toLowerCase(),
          helpText: T.admin.processmaps.objecteditor.hiderequiredsignalidhelp,
          modelType: ModelType.SIGNAL,
          nodeId: previewNodeId,
          isClearable: true
        }
      ]
    });
  }

  return ruleLines;
};

const shapeOptions = [
  {
    label: T.admin.processmaps.objecteditor.rectshapes.rectangle,
    value: ProcessMapRectShapes.Rectangle
  },
  {
    label: T.admin.processmaps.objecteditor.rectshapes.triangle,
    value: ProcessMapRectShapes.Triangle
  },
  {
    label: T.admin.processmaps.objecteditor.rectshapes.circle,
    value: ProcessMapRectShapes.Circle
  }
];

export const rectSections = (
  previewNodeId: string,
  fillColorRule: string
): ModelFormSectionType<ProcessMapRectObject>[] => {
  return [
    {
      label: T.admin.processmaps.objecteditor.shapeobject,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.rectshapes.title,
              key: (input) => input.shape,
              modelType: ModelType.OPTIONS,
              options: shapeOptions,
              placeholder: T.admin.processmaps.objecteditor.rectshapes.rectangle
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.strokewidth,
              key: (input) => input.strokeWidth,
              modelType: ModelType.NUMBER
            },
            {
              label: T.admin.processmaps.objecteditor.cornerradius,
              key: (input) => input.cornerRadius,
              modelType: ModelType.NUMBER,
              visible: (input) =>
                input.shape == null ||
                input.shape === ProcessMapRectShapes.Rectangle
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.rotation,
              key: (input) => input.rotation,
              modelType: ModelType.OPTIONS,
              options: processMapRotationOptions
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.strokecolor,
              key: (input) => input.strokeColor,
              modelType: ModelType.COLOR
            },
            {
              label: T.admin.processmaps.objecteditor.fillcolor,
              key: (input) => input.fillColor,
              modelType: ModelType.COLOR
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.fillcolorrule,
              key: (input) => input.fillColorRuleName,
              modelType: ModelType.OPTIONS,
              options: processMapColorRuleOptions,
              isClearable: true
            }
          ]
        },
        ...colorRuleArgumentOptionLines(
          T.admin.processmaps.objecteditor.fillcolor,
          fillColorRule,
          previewNodeId
        )
      ]
    },
    {
      label: T.admin.processmaps.objecteditor.textobject,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.text,
              key: (input) => input.text,
              modelType: ModelType.TEXT,
              multiline: true,
              rows: 4
            }
          ]
        },
        {
          models: [textAlignmentOption]
        }
      ]
    },
    ...textStyleSections<ProcessMapRectObject>(
      T.admin.processmaps.objecteditor.labeltextstyle,
      (input) => input.labelTextSettings,
      defaultRectTitleSettings
    )
  ];
};
