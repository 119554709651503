import React from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import HelpPaths from 'ecto-common/help/tocKeys';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import ProcessMapSignalsTable from './ProcessMapSignalsTable';

const EmptyNodes: NodeV2ResponseModel[] = [];

type LocationMapPanelDataType = {
  signals: LastSignalValuesDataSourceResult;
};

type LocationMapPanelProps = CustomPanelProps & {
  data: LocationMapPanelDataType;
};

const ProcessMapSignalsTablePanel = ({ data }: LocationMapPanelProps) => {
  const nodes = data?.signals?.nodes ?? EmptyNodes;

  return <ProcessMapSignalsTable nodeList={nodes} signals={data?.signals} />;
};

const optionalSignalModels: ModelDefinition<SignalInputType>[] = [
  {
    key: (input) => input.displayName,
    modelType: ModelType.TEXT,
    label: T.signals.displayname,
    placeholder: T.signals.displaynameplaceholder
  }
];

export const ProcessMapSignalsTablePanelData = {
  helpPath: HelpPaths.docs.dashboard.dashboards.map,
  helpText: T.admin.dashboards.panels.helptexts.signalstable,
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS_LAST_VALUE]: {
      minItems: 0,
      optionalSignalModels
    }
  },
  emptyTargets: {
    signals: {
      sourceType: DataSourceTypes.SIGNALS_LAST_VALUE,
      useSiblings: false,
      childrenOnly: true
    }
  }
};

export default React.memo(ProcessMapSignalsTablePanel);
