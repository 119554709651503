import { useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import useLatestSignalValues from 'ecto-common/lib/hooks/useLatestSignalValues';
import APIGen from 'ecto-common/lib/API/APIGen';
import { FullSignalProviderResponseModel } from '../API/APIGen';
import { Moment } from 'moment';
import { Base64 } from 'js-base64';
import {
  getExternalSignalIds,
  getSignalTypeIds,
  migrateProcessMapDocument
} from './ProcessMapViewUtils';

import {
  ProcessMapDocument,
  emptyProcessMapDocument
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';
import {
  ProcessMapDataType,
  ProcessMapNodeDataType
} from '../ProcessMaps/ProcessMapDataHandling';
import { usePresentationItemQuery } from '../utils/presentationLibrary';
import PresentationAPIGen from '../API/PresentationAPIGen';

export const extractSignalStateNames = (svg: string) => {
  const regex = /symbol-state="(.*?)"/gm;
  let m;

  const signalStateNames = [];
  while ((m = regex.exec(svg)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m.length === 2) {
      signalStateNames.push(m[1]);
    }
  }

  return _.uniq(signalStateNames);
};

export const useProcessMapSignals = ({
  nodeId,
  signalProviders,
  onlyMappedSignals,
  selectedImageId = null,
  fromDate = null,
  externalDocument = null
}: {
  nodeId: string;
  signalProviders: FullSignalProviderResponseModel[];
  onlyMappedSignals: boolean;
  selectedImageId?: string;
  fromDate?: Moment;
  externalDocument?: ProcessMapDocument;
}): {
  isLoading: boolean;
  error: boolean;
  image: ProcessMapDocument;
  selectedImage: ProcessMapNodeDataType;
  images: ProcessMapNodeDataType[];
  signalData: LastSignalValuesResultWithMetadata;
  mappedSignalTypeIds: string[];
} => {
  const { query: relationQuery } = usePresentationItemQuery({
    queryHook: PresentationAPIGen.Nodes.getPictures.useQuery,
    nodeId,
    options: {
      enabled: nodeId != null && !externalDocument,
      meta: { errorString: T.equipment.errorfetchingprocessmap }
    }
  });

  const {
    image,
    images,
    selectedImage,
    specificSignalIds,
    mappedSignalTypeIds
  } = useMemo(() => {
    const selection = selectedImageId ?? relationQuery.data.items?.[0]?.id;
    const selectedImageData = relationQuery.data.items?.find(
      (data: ProcessMapDataType) => data.id === selection
    );

    if (externalDocument == null && selectedImageData == null) {
      return {
        image: null,
        images: [],
        selectedImage: null,
        specificSignalIds: [],
        mappedSignalTypeIds: []
      };
    }
    let loadedDocument: ProcessMapDocument = externalDocument;

    if (loadedDocument == null) {
      const decoded = Base64.decode(selectedImageData.data);
      if (!_.isEmpty(decoded)) {
        try {
          loadedDocument = JSON.parse(decoded);
        } catch (e) {
          console.error(e);
          loadedDocument = _.cloneDeep(emptyProcessMapDocument);
        }
      } else {
        loadedDocument = _.cloneDeep(emptyProcessMapDocument);
      }
    } else {
      loadedDocument = _.cloneDeep(externalDocument);
    }

    migrateProcessMapDocument(loadedDocument);

    return {
      image: loadedDocument,
      images: relationQuery.data?.items ?? [],
      selectedImage: selectedImageData,
      specificSignalIds: getExternalSignalIds(loadedDocument),
      mappedSignalTypeIds: getSignalTypeIds(loadedDocument)
    };
  }, [externalDocument, relationQuery.data, selectedImageId]);

  const specificSignalIdProvidersQuery =
    APIGen.Signals.getProvidersBySignalIds.useQuery(
      {
        signalIds: specificSignalIds
      },
      {
        enabled: specificSignalIds.length > 0
      }
    );

  const { signalIds, signalProvidersIds, allSignalIds } = useMemo(() => {
    const allProviders = _.concat(
      signalProviders,
      specificSignalIdProvidersQuery.data ?? []
    );

    if (onlyMappedSignals) {
      const mappedSignalIds = _(allProviders)
        .flatMap('signals')
        .filter(
          (signal) =>
            mappedSignalTypeIds.includes(signal.signalTypeId) ||
            specificSignalIds.includes(signal.signalId)
        )
        .map('signalId')
        .value();

      return {
        signalIds: mappedSignalIds,
        signalProvidersIds: null as string[],
        allSignalIds: mappedSignalIds
      };
    }

    return {
      signalIds: null,
      signalProvidersIds: _.uniq(_.map(allProviders, 'signalProviderId')),
      allSignalIds: _.uniq(
        _.flatMap(allProviders, (provider) =>
          _.map(provider.signals, 'signalId')
        )
      )
    };
  }, [
    onlyMappedSignals,
    signalProviders,
    specificSignalIdProvidersQuery.data,
    mappedSignalTypeIds,
    specificSignalIds
  ]);

  const _signalData = useLatestSignalValues(
    signalProvidersIds,
    signalIds,
    allSignalIds,
    fromDate
  );

  return {
    isLoading: !externalDocument && relationQuery.isLoading,
    error: relationQuery.isError,
    image,
    images,
    selectedImage,
    signalData: _signalData,
    mappedSignalTypeIds
  };
};
