import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import { ResourceType } from 'ecto-common/lib/constants';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ToolType } from 'ecto-common/lib/API/APIGen';
import { ResourceModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

export enum ToolSignalProviders {
  POWER_CONTROL = 'PowerControl',
  LINEAR_OPTIMIZATION = 'LinearOptimization',
  COMFORT = 'Comfort',
  NETMORE = 'Netmore',
  POWER_DELTA_CONTROL = 'PowerDeltaControl',
  BATTERY_CHARGING_THRESHOLD = 'BatteryChargingThreshold',
  DEMAND_SIDE_MANAGEMENT = 'DemandSideManagement'
}

export const ToolSignalProviderToResourceType = {
  [ToolSignalProviders.POWER_CONTROL]: [ResourceType.POWER_CONTROL],
  [ToolSignalProviders.LINEAR_OPTIMIZATION]: [ResourceType.CORE],
  [ToolSignalProviders.COMFORT]: [ResourceType.SMART_COMFORT],
  [ToolSignalProviders.POWER_DELTA_CONTROL]: [ResourceType.CORE],
  [ToolSignalProviders.NETMORE]: [ResourceType.SMART_COMFORT],
  [ToolSignalProviders.BATTERY_CHARGING_THRESHOLD]: [ResourceType.LOBA],
  [ToolSignalProviders.DEMAND_SIDE_MANAGEMENT]: [ResourceType.CORE]
} as const;

export const ToolSignalProviderTranslations = Object.freeze({
  [ToolSignalProviders.POWER_CONTROL]: T.admin.equipment.tool.powercontrol,
  [ToolSignalProviders.LINEAR_OPTIMIZATION]:
    T.admin.equipment.tool.linearoptimisation,
  [ToolSignalProviders.COMFORT]: T.admin.equipment.tool.comfort,
  [ToolSignalProviders.POWER_DELTA_CONTROL]:
    T.admin.equipment.tool.powerdeltacontrol,
  [ToolSignalProviders.BATTERY_CHARGING_THRESHOLD]:
    T.admin.equipment.tool.batterychargingthreshold,
  [ToolSignalProviders.NETMORE]: T.admin.integration.netmore.title,
  [ToolSignalProviders.DEMAND_SIDE_MANAGEMENT]:
    T.admin.demandsidemanagement.title
});

// Translations for the server enum (not related to the ToolType)
export const ToolEnumTranslations: Record<ToolType, React.ReactNode> = {
  [ToolType.Unknown]: '',
  [ToolType.PowerControlHeatingTracking]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.heating +
      ' ' +
      T.admin.equipment.algorithmtype.tracking
  ),
  [ToolType.PowerControlHeatingBasic]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.heating +
      ' ' +
      T.admin.equipment.algorithmtype.basic
  ),
  [ToolType.PowerControlHeatingTransmit]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.heating +
      ' ' +
      T.admin.equipment.algorithmtype.transmit
  ),
  [ToolType.PowerControlCoolingTracking]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.cooling +
      ' ' +
      T.admin.equipment.algorithmtype.tracking
  ),
  [ToolType.PowerControlCoolingBasic]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.cooling +
      ' ' +
      T.admin.equipment.algorithmtype.basic
  ),
  [ToolType.PowerControlCoolingTransmit]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.cooling +
      ' ' +
      T.admin.equipment.algorithmtype.transmit
  ),
  [ToolType.PowerControlHeatingTrackingVentilation]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.heating +
      ' ' +
      T.admin.equipment.algorithmtype.trackingventilation
  ),

  [ToolType.PowerControlCoolingTrackingVentilation]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.cooling +
      ' ' +
      T.admin.equipment.algorithmtype.trackingventilation
  ),

  [ToolType.LinearOptimisation]: T.admin.equipment.linearoptimisation,
  [ToolType.DemandSideManagementHeating]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.heating
  ),
  [ToolType.DemandSideManagementCooling]: T.format(
    T.admin.equipment.powercontrolformat,
    T.admin.equipment.powercontroltype.cooling
  )
};

export const ToolTypeAccessRight: Record<ToolType, ResourceType[]> = {
  [ToolType.Unknown]: [ResourceType.CORE],
  [ToolType.PowerControlHeatingTracking]: [ResourceType.POWER_CONTROL],
  [ToolType.PowerControlHeatingBasic]: [ResourceType.POWER_CONTROL],
  [ToolType.PowerControlHeatingTransmit]: [ResourceType.POWER_CONTROL],
  [ToolType.PowerControlCoolingTracking]: [ResourceType.POWER_CONTROL],
  [ToolType.PowerControlCoolingBasic]: [ResourceType.POWER_CONTROL],
  [ToolType.PowerControlCoolingTransmit]: [ResourceType.POWER_CONTROL],
  [ToolType.PowerControlHeatingTrackingVentilation]: [
    ResourceType.POWER_CONTROL
  ],
  [ToolType.PowerControlCoolingTrackingVentilation]: [
    ResourceType.POWER_CONTROL
  ],
  [ToolType.LinearOptimisation]: [ResourceType.CORE],
  [ToolType.DemandSideManagementHeating]: [ResourceType.CORE],
  [ToolType.DemandSideManagementCooling]: [ResourceType.CORE]
};

export const getToolTypeEnumResourcesByUserAccess = (
  toolTypes: ToolType[],
  tenantResources: ResourceModel[]
) => {
  return _.filter(toolTypes, (toolType) => {
    if (ToolTypeAccessRight?.[toolType]?.length === 0) {
      return true;
    }

    return _.find(ToolTypeAccessRight?.[toolType], (_toolType) => {
      return hasAccessToResource(_toolType, tenantResources);
    });
  });
};
