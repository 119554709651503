import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useSyncExternalStore
} from 'react';
import _ from 'lodash';

import EventHubService, {
  EventHubTopics,
  EventHubServiceContext,
  EventHubTopic
} from 'ecto-common/lib/EventHubConnection/EventHubService';
import { isRootNodeId } from 'ecto-common/lib/utils/locationUtils';
import { getBuildingStatusQueryParameter } from 'ecto-common/lib/utils/buildingStatusUtil';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { useAlarmCountUpdates } from '../Alarms/V2/useAlarmUpdates';
import { AlarmSeverityLevelsV2, AlarmSeverityV2MapToV1 } from '../constants';

const handleSubscription = (
  service: EventHubService,
  topic: EventHubTopic,
  onChanged: (data: unknown) => void,
  onError: (err: unknown) => void,
  ...params: unknown[]
) => {
  if (service == null) {
    console.error('Event hub service is invalid');
    return;
  }

  const token = service.subscribe(topic, onChanged, onError, params);

  return () => {
    service.unsubscribe(token);
  };
};

export const useSignalUpdateEventHubSubscription = (
  signalProviderIds: string[],
  signalIds: string[],
  onValuesChanged: (newValues: SignalValueType[]) => void
) => {
  const service = useContext(EventHubServiceContext);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );
  const useEIoT = featureFlagState['eiot-signals'] === true;

  useEffect(() => {
    if (signalProviderIds?.length > 0 || signalIds?.length > 0) {
      return handleSubscription(
        service,
        EventHubTopics.SIGNALS_CHANGED,
        onValuesChanged,
        _.noop,
        signalProviderIds ?? [],
        signalIds ?? [],
        true,
        useEIoT
      );
    }
  }, [signalProviderIds, signalIds, onValuesChanged, service, useEIoT]);
};

const InitialAlarmCounts = {
  bySeverity: {},
  totalCount: {},
  maxSeverityPerAlarm: {},
  isLoading: true,
  hasError: false
};

const EmptyBuildingStatuses: BuildingStatus[] = [];

export type AlarmCountEvent = {
  nodeId: string;
  alarmCounts: {
    severity: number;
    count: number;
  }[];
};

const mapSeverity = (severity: number) => {
  const severityLevelV2 = _.findLast(
    AlarmSeverityLevelsV2,
    (level) => severity <= level
  );
  return AlarmSeverityV2MapToV1[severityLevelV2];
};

export const useAlarmCountEventHubSubscription = (
  _grid: string,
  nodeIds: string[],
  includeRecursive = false,
  buildingStatuses: BuildingStatus[] = EmptyBuildingStatuses
) => {
  const [alarmCounts, setAlarmCounts] = useState({ ...InitialAlarmCounts });

  useEffect(() => {
    setAlarmCounts({ ...InitialAlarmCounts });
  }, [nodeIds, buildingStatuses]);

  const onAlarmCountsChanged = useCallback((countsList: AlarmCountEvent[]) => {
    const bySeverityUpdates = _(countsList)
      .keyBy('nodeId') // Convert list of [{ nodeId, alarmCounts }] to { nodeId: { nodeId, alarmCounts } }
      .mapValues('alarmCounts') // Convert { nodeId: { nodeId, alarmCounts } } to { nodeId: alarmCounts }
      .mapValues((x) =>
        _(x) // alarmCounts = [{ severity, count }]
          .keyBy('severity') // Replace [{ severity, count }] list with { severity: { severity, count }
          .mapValues('count') // Replace { severity: { severity, count } with { severity: count }
          .value()
      )
      .value();

    setAlarmCounts((oldAlarmCounts) => {
      const bySeverity = {
        ...oldAlarmCounts.bySeverity,
        ...bySeverityUpdates
      };

      return {
        bySeverity,
        totalCount: _.mapValues(bySeverity, (nodeAlarmCount) =>
          _.sum(_.map(nodeAlarmCount))
        ),
        maxSeverityPerAlarm: _.mapValues(bySeverity, (severitySet) =>
          _.parseInt(_.min(_.keys(severitySet)))
        ),
        isLoading: false,
        hasError: false
      };
    });
  }, []);

  const onAlarmCountsChangedV2 = useCallback(
    (countsList: AlarmCountEvent[]) => {
      onAlarmCountsChanged(
        _.map(countsList, (counts) => ({
          ...counts,
          alarmCounts: _.map(counts.alarmCounts, (alarmCount) => ({
            ...alarmCount,
            severity: mapSeverity(alarmCount.severity)
          }))
        }))
      );
    },
    [onAlarmCountsChanged]
  );

  useAlarmCountUpdates({
    nodeIds,
    isRecursive: includeRecursive,
    onAlarmCountsChanged: onAlarmCountsChangedV2
  });

  return alarmCounts;
};

export const useAlarmEventHubSubscription = (
  grid: string,
  nodeId: string,
  onAlarmsChanged: () => void,
  buildingStatuses = EmptyBuildingStatuses
) => {
  const _buildingStatuses = getBuildingStatusQueryParameter(buildingStatuses);
  const service = useContext(EventHubServiceContext);

  useEffect(() => {
    return handleSubscription(
      service,
      EventHubTopics.ALARMS_CHANGED,
      onAlarmsChanged,
      _.noop,
      grid,
      isRootNodeId(nodeId) ? null : nodeId,
      _buildingStatuses
    );
  }, [nodeId, grid, onAlarmsChanged, service, _buildingStatuses]);
};
