import {
  NodeV2ResponseModel,
  NodeSearchResultsResponseModel,
  NodeTraitNodeRelationResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps,
  DataTableSectionHeader,
  DataTableSectionHeaderType
} from 'ecto-common/lib/DataTable/DataTable';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import React, { useMemo } from 'react';
import _ from 'lodash';
import styles from './PageTreeView.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import { getNodeParents } from 'ecto-common/lib/LocationTreeView/useNodeTree';
import {
  nodeIsBuilding,
  nodeIsEquipment,
  useNodeTraits
} from '../hooks/useCurrentNode';

type LocationSearchProps = {
  isLoading: boolean;
  searchResults: NodeSearchResultsResponseModel;
  onNodeSelected: (nodeId: string) => void;
};

const LocationSearch = ({
  isLoading,
  searchResults,
  onNodeSelected
}: LocationSearchProps) => {
  const traitsQuery = useNodeTraits();

  const columns = useMemo<DataTableColumnProps<NodeV2ResponseModel>[]>(() => {
    return [
      {
        dataKey: '_unused',
        width: 20,
        maxWidth: 20,
        dataFormatter: (_unused, item) => {
          let icon = <Icons.Site />;

          if (nodeIsBuilding(item)) {
            icon = <Icons.Building />;
          } else if (nodeIsEquipment(item)) {
            icon = <Icons.Equipment />;
          }

          return icon;
        }
      },
      {
        dataKey: 'name',
        linkColumn: true
      },
      {
        dataKey: 'nodeTraits',
        dataFormatter: (
          traitRelations: NodeTraitNodeRelationResponseModel[]
        ) => {
          const traits = sortByLocaleCompare(
            _.compact(
              _.map(traitRelations, ({ nodeTraitId }) => {
                return _.find(traitsQuery.data?.items, { id: nodeTraitId });
              })
            ),
            'name'
          );

          return (
            <div className={styles.tags}>
              <TagsGroup
                className={styles.traitTag}
                tags={sortByLocaleCompare(traits, 'name').map((t) => t.name)}
              />
            </div>
          );
        }
      }
    ];
  }, [traitsQuery.data]);

  const searchTableData: (NodeV2ResponseModel | DataTableSectionHeaderType)[] =
    useMemo(() => {
      const ret: (NodeV2ResponseModel | DataTableSectionHeaderType)[] = [];

      let prevParentId = null;

      for (const item of searchResults?.nodes ?? []) {
        if (prevParentId !== item.parentId) {
          const [, allParents] = getNodeParents(item.parentId, (otherNodeId) =>
            searchResults?.parents.find(
              (otherParent) => otherParent.nodeId === otherNodeId
            )
          );

          const parentText = allParents
            .map((parent) => parent.name)
            .reverse()
            .join(' > ');

          ret.push(
            DataTableSectionHeader(
              <div className={styles.searchSectionHeader}>{parentText}</div>
            )
          );
          prevParentId = item.parentId;
        }

        ret.push(item);
      }

      return ret;
    }, [searchResults?.nodes, searchResults?.parents]);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner size={SpinnerSize.SMALL} />
      </div>
    );
  }

  const onClickRow = (node: NodeV2ResponseModel) => {
    onNodeSelected(node.nodeId);
  };

  return (
    <div className={styles.searchResults}>
      <DataTable
        columns={columns}
        onClickRow={onClickRow}
        data={searchTableData}
        isLoading={isLoading}
        disableHeader
        inline
        smaller
      />
    </div>
  );
};

export default React.memo(LocationSearch);
