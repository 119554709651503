import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import UrlContext, {
  GetAlarmUrlFunction,
  GetSignalsUrlFunction
} from 'ecto-common/lib/hooks/UrlContext';
import HelpPaths from 'ecto-common/help/tocKeys';
import {
  AlarmEventModel,
  AlarmModel,
  NodePathItem
} from 'ecto-common/lib/API/AlarmsAPIGen';
import _ from 'lodash';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import AcknowledgeButtonV2 from 'ecto-common/lib/Alarms/V2/AcknowledgeButtonV2';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import Button from 'ecto-common/lib/Button/Button';
import AlarmConfirmAllModalV2 from 'ecto-common/lib/Alarms/V2/AlarmConfirmAllModalV2';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import AlarmHistoryModalV2 from 'ecto-common/lib/Alarms/V2/AlarmHistoryModalV2';
import queryString from 'query-string';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import AlarmsAPIGen from 'ecto-common/lib/API/AlarmsAPIGen';
import { createAlarmListEventQuery, createAlarmListQuery } from './listQuery';
import {
  AlarmEvent,
  AlarmUpdateContext
} from 'ecto-common/lib/Alarms/V2/useAlarmUpdates';
import { useCurrentNode, useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import AlarmDetailsModal from './AlarmDetailsModal';
import AlarmDetailsColumn from './AlarmDetailsColumn';
import ActiveAlarmColumn from './ActiveAlarmColumn';
import StartDateColumn from './StartDateColumn';
import AcknowledgedAtText from './AcknowledgedAtText';
import SeverityColumn from './SeverityColumn';

type AlarmViewV2Props = {
  nodeId: string;
};
const subPages = {
  eventList: 'eventList',
  alarms: 'alarms'
} as const;

const severityColumn = {
  label: T.alarms.columns.severity,
  dataKey: 'severity',
  width: 98,
  minWidth: 98,
  flexGrow: 0,
  flexShrink: 0,
  canSort: true,
  dataFormatter: (severity: number) => <SeverityColumn severity={severity} />
};

const nameColumn = {
  dataKey: 'message',
  label: T.common.message
};

const nodePathColumn = (
  tenantId: string,
  getAlarmUrl: GetAlarmUrlFunction,
  getSignalsUrl: GetSignalsUrlFunction
) => ({
  label: T.alarms.columns.equipment,
  dataKey: 'nodePath',
  minWidth: 115,
  flexGrow: 2,
  canSort: false,
  dataFormatter: (nodePath: NodePathItem[]) => {
    return (
      <AlarmDetailsColumn
        tenantId={tenantId}
        nodePath={nodePath}
        getAlarmUrl={getAlarmUrl}
        getSignalsUrl={getSignalsUrl}
      />
    );
  }
});

const isActiveColumn = (
  dataKey: 'firstActiveAtSinceLastAcknowledgement' | 'startDate',
  showAlarmHistory: (sourceUri: string, startDate?: string) => void
) => ({
  label: T.alarms.columns.firstactivedate,
  dataKey,
  minWidth: 105,
  flexGrow: 1,
  canSort: true,
  dataFormatter: (alarmDate: string, alarm: AlarmModel) => {
    return (
      <StartDateColumn
        alarm={alarm}
        alarmDate={alarmDate}
        showAlarmHistory={showAlarmHistory}
      />
    );
  }
});

const getAlarmModelColumns = (
  node: NodeV2ResponseModel,
  tenantId: string,
  getAlarmUrl: GetAlarmUrlFunction,
  getSignalsUrl: GetSignalsUrlFunction,
  showAlarmHistory: (sourceUri: string, startDate?: string) => void
): DataTableColumnProps<AlarmModel>[] => [
  severityColumn,
  nameColumn,
  nodePathColumn(tenantId, getAlarmUrl, getSignalsUrl),
  {
    label: T.alarms.columns.status,
    dataKey: 'isActive',
    minWidth: 80,
    flexGrow: 1,
    canSort: true,
    dataFormatter: (_unused: boolean, alarm: AlarmModel) => (
      <ActiveAlarmColumn alarm={alarm} showAlarmHistory={showAlarmHistory} />
    )
  },
  isActiveColumn('firstActiveAtSinceLastAcknowledgement', showAlarmHistory),
  {
    label: T.alarms.columns.acknowledge,
    dataKey: 'acknowledgedAt',
    flexGrow: 0,
    minWidth: 200,
    canSort: true,
    dataFormatter: (
      acknowledgedAt: string,
      alarm: AlarmModel | AlarmEventModel
    ) => {
      const isAcknowledged = acknowledgedAt != null;

      if (isAcknowledged) {
        return (
          <AcknowledgedAtText
            key={alarm.sourceUri}
            alarm={alarm}
            isAcknowledged={isAcknowledged}
          />
        );
      }

      return (
        <AcknowledgeButtonV2 key={alarm.sourceUri} node={node} alarm={alarm} />
      );
    }
  }
];

const getAlarmEventModelColumns = (
  tenantId: string,
  getAlarmUrl: GetAlarmUrlFunction,
  getSignalsUrl: GetSignalsUrlFunction,
  showAlarmHistory: (sourceUri: string, startDate?: string) => void
): DataTableColumnProps<AlarmEventModel>[] => [
  severityColumn,
  nameColumn,
  nodePathColumn(tenantId, getAlarmUrl, getSignalsUrl),
  isActiveColumn('startDate', showAlarmHistory),
  {
    label: T.common.enddate,
    dataKey: 'endDate',
    minWidth: 105,
    flexGrow: 1,
    canSort: true,
    dataFormatter: (alarmDate: string, alarm: AlarmModel) => {
      if (alarmDate) {
        return (
          <StartDateColumn
            alarm={alarm}
            alarmDate={alarmDate}
            showAlarmHistory={showAlarmHistory}
          />
        );
      }
      return '-';
    }
  },
  {
    label: T.alarms.columns.acknowledge,
    dataKey: 'acknowledgedAt',
    flexGrow: 0,
    minWidth: 200,
    canSort: true,
    dataFormatter: (acknowledgedAt: string, alarm: AlarmEventModel) => {
      const isAcknowledged = acknowledgedAt != null;
      return (
        <AcknowledgedAtText alarm={alarm} isAcknowledged={isAcknowledged} />
      );
    }
  }
];

const AlarmEventListView = ({
  nodeId,
  searchFilter,
  onSortChange,
  sortDirection,
  orderBy
}: {
  nodeId: string;
  searchFilter: string;
  onSortChange: (orderBy: string, direction: string) => void;
  sortDirection: SortDirectionType;
  orderBy: string;
}) => {
  const _orderBy = orderBy ?? 'startDate';
  const listQuery = AlarmsAPIGen.Alarms.listAlarmEvents.useInfiniteQuery(
    createAlarmListEventQuery({
      nodeId,
      searchFilter,
      orderBy: _orderBy,
      sortDirection
    }),
    {}
  );

  const { currentNode } = useCurrentNode();
  const { alarmEvents } = useContext(AlarmUpdateContext);

  useEffect(() => {
    const listener = (arg: AlarmEvent) => {
      if (arg.nodeId === currentNode?.nodeId) {
        listQuery.refetch();
      }
    };

    alarmEvents.addListener(listener);

    return () => {
      alarmEvents.removeListener(listener);
    };
  }, [alarmEvents, listQuery, currentNode]);

  const [historyItemData, setHistoryItemData] =
    useState<[string, string]>(null);

  const dataTableData = useMemo(() => {
    return _.flatMap(listQuery.data?.pages, 'items');
  }, [listQuery.data?.pages]);

  const { getAlarmUrlV2, getSignalsUrl } = useContext(UrlContext);
  const { tenantId } = useContext(TenantContext);

  const showAlarmHistory = useCallback(
    (sourceUri: string, startDate: string) => {
      setHistoryItemData([sourceUri, startDate]);
    },
    []
  );

  const listColumns = useMemo(() => {
    return getAlarmEventModelColumns(
      tenantId,
      getAlarmUrlV2,
      getSignalsUrl,
      showAlarmHistory
    );
  }, [getAlarmUrlV2, getSignalsUrl, showAlarmHistory, tenantId]);

  const [historyItemUri, historyItemOccuredAt] = historyItemData ?? [
    null,
    null
  ];
  const historyItem = dataTableData.find((x) => x.sourceUri === historyItemUri);

  return (
    <>
      <DataTable<AlarmEventModel>
        columns={listColumns}
        data={dataTableData}
        isLoading={listQuery.isLoading}
        onSortChange={onSortChange}
        onUserScrolledToEndOfTable={
          listQuery.hasNextPage ? listQuery.fetchNextPage : null
        }
        sortBy={orderBy}
        sortDirection={sortDirection}
      />
      <AlarmHistoryModalV2
        nodeId={nodeId}
        sourceUri={historyItemUri}
        occuredAt={historyItemOccuredAt}
        onModalClose={() => setHistoryItemData(null)}
        name={historyItem?.properties?.signalName}
      />
    </>
  );
};

/**
 * Alarms missing things:
 *
 * No way of getting updates. We need a websocket connection to the backend to update alarms incrementally. Since we
 * use a continuation token to get the next page of alarms, we can't just use the same query to get updates. We need to
 * get fine grained updates regarding each alarm so we can patch the list we have.
 * *
 * Properties on alarm is not typed, it's just a dictionary. This is used for essential things such as the name of the
 * alarm. If we can't make that mandatory then it will be a challenge to show a list of alarms.
 *
 * alarmEnergyManagerOffline type of alarms seems to be missing
 *
 * Severity is not granular enough, we do not support MEDIUM HIGH / MEDIUM LOW yet.
 *
 */
export const AlarmListView = ({
  nodeId,
  searchFilter,
  onSortChange,
  orderBy,
  sortDirection
}: {
  nodeId: string;
  searchFilter: string;
  onSortChange: (orderBy: string, direction: string) => void;
  orderBy: string;
  sortDirection: SortDirectionType;
}) => {
  const { node } = useNode(nodeId);
  const _orderBy =
    orderBy ?? 'isActive desc, firstActiveAtSinceLastAcknowledgement';

  const alarmsQuery = AlarmsAPIGen.Alarms.listAlarms.useInfiniteQuery(
    createAlarmListQuery({
      nodeId,
      searchFilter,
      orderBy: _orderBy,
      sortDirection
    }),
    {}
  );

  const { alarmEvents } = useContext(AlarmUpdateContext);

  useEffect(() => {
    const listener = (arg: AlarmEvent) => {
      if (arg.nodeId === node?.nodeId) {
        alarmsQuery.refetch();
      }
    };

    alarmEvents.addListener(listener);

    return () => {
      alarmEvents.removeListener(listener);
    };
  }, [alarmEvents, alarmsQuery, node]);

  const [detailItem, setDetailItem] = useState<AlarmModel>(null);
  const [historyItemUri, setHistoryItemUri] = useState<string>(null);

  const { getAlarmUrlV2, getSignalsUrl } = useContext(UrlContext);
  const { tenantId } = useContext(TenantContext);

  const showAlarmHistory = useCallback((sourceUri: string) => {
    setHistoryItemUri(sourceUri);
  }, []);

  const showAlarmInfo = useCallback((alarm: AlarmModel) => {
    setDetailItem(alarm);
  }, []);

  const listColumns = useMemo(() => {
    return getAlarmModelColumns(
      node,
      tenantId,
      getAlarmUrlV2,
      getSignalsUrl,
      showAlarmHistory
    );
  }, [node, getAlarmUrlV2, getSignalsUrl, showAlarmHistory, tenantId]);

  const dataTableData = useMemo(() => {
    return _.flatMap(alarmsQuery.data?.pages, 'items');
  }, [alarmsQuery.data?.pages]);

  const historyItem = dataTableData.find((x) => x.sourceUri === historyItemUri);

  return (
    <>
      <DataTable<AlarmModel>
        data={dataTableData}
        columns={listColumns}
        onSortChange={onSortChange}
        isLoading={alarmsQuery.isLoading}
        onClickRow={showAlarmInfo}
        sortBy={orderBy}
        sortDirection={sortDirection}
        onUserScrolledToEndOfTable={
          alarmsQuery.hasNextPage ? alarmsQuery.fetchNextPage : null
        }
      />
      <AlarmDetailsModal
        tenantId={tenantId}
        node={node}
        getAlarmUrl={getAlarmUrlV2}
        getSignalsUrl={getSignalsUrl}
        showAlarmHistory={showAlarmHistory}
        alarm={detailItem}
        onModalClose={() => setDetailItem(null)}
      />
      <AlarmHistoryModalV2
        nodeId={nodeId}
        sourceUri={historyItemUri}
        onModalClose={() => setHistoryItemUri(null)}
        name={historyItem?.properties?.signalName}
      />
    </>
  );
};

const AlarmViewV2 = ({ nodeId }: AlarmViewV2Props) => {
  const navigate = useNavigate();
  // const [searchFilter, setSearchFilter] = useState<string>(null);

  const [showConfirmAllDialog, onShowConfirmAllDialog, onHideConfirmAllDialog] =
    useDialogState('alarms-confirm-all');

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const showEventList = queryParams.showEvents === 'true';

  const updateSubpage = useCallback(
    (subPage: string) => {
      navigate({
        search: queryString.stringify({
          ..._.omit(queryParams, ['orderBy', 'sortDirection']),
          showEvents: subPage === subPages.eventList
        })
      });
    },
    [navigate, queryParams]
  );

  const setSearchFilter = useCallback(
    (newSearch: string) => {
      navigate({
        search: queryString.stringify({
          ...queryParams,
          searchFilter: newSearch
        })
      });
    },
    [navigate, queryParams]
  );

  const onSortChange = useCallback(
    (orderBy: string, sortDirection: string) => {
      navigate({
        search: queryString.stringify({
          ...queryParams,
          orderBy,
          sortDirection
        })
      });
    },
    [navigate, queryParams]
  );

  const searchFilter = queryParams.searchFilter as string;
  const orderBy = queryParams.orderBy as string;
  const sortDirection =
    (queryParams.sortDirection as SortDirectionType) ?? 'desc';

  const toolbarItems = (
    <>
      <ToolbarSearch value={searchFilter} onChange={setSearchFilter} />
      <ToolbarFlexibleSpace />

      <ToolbarItem>
        <SegmentControl>
          <SegmentControlItem
            active={!showEventList}
            onClick={() => updateSubpage(subPages.alarms)}
          >
            <Icons.AlarmSignalClusters />
            {T.alarms.section.signalclusters}
          </SegmentControlItem>

          <SegmentControlItem
            active={showEventList}
            onClick={() => updateSubpage(subPages.eventList)}
          >
            <Icons.AlarmEventList />
            {T.alarms.section.eventlist}
          </SegmentControlItem>
        </SegmentControl>
      </ToolbarItem>

      <ToolbarFlexibleSpace />

      <ToolbarItem>
        <Button onClick={onShowConfirmAllDialog} disabled={showEventList}>
          <Icons.Checkmark />
          {T.alarms.page.heading.acknowledgeallalarms}
        </Button>
      </ToolbarItem>
    </>
  );

  const { locationRoute } = useContext(UrlContext);

  const urlBuilder = (_tenantId: string, newNodeId: string) => {
    const newParams = matchPath<NodeParams, string>(
      locationRoute.path,
      window.location.pathname
    ).params;

    return `/${_tenantId}/home/${newNodeId}/${newParams.page}`;
  };

  return (
    <ToolbarContentPage
      title={T.alarms.title}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.operator.alarms}
      urlBuilder={urlBuilder}
    >
      {!showEventList && (
        <AlarmListView
          nodeId={nodeId}
          searchFilter={searchFilter}
          onSortChange={onSortChange}
          orderBy={orderBy}
          sortDirection={sortDirection}
        />
      )}
      {showEventList && (
        <AlarmEventListView
          nodeId={nodeId}
          searchFilter={searchFilter}
          onSortChange={onSortChange}
          orderBy={orderBy}
          sortDirection={sortDirection}
        />
      )}

      <AlarmConfirmAllModalV2
        nodeId={nodeId}
        isOpen={showConfirmAllDialog}
        onModalClose={onHideConfirmAllDialog}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(AlarmViewV2);
