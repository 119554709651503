import React from 'react';
import _ from 'lodash';

import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import EditButton from 'ecto-common/lib/Button/EditButton';
import { DataTableRowMinHeight } from 'ecto-common/lib/DataTable/DataTableRow';
import {
  NoDataMessageHeight,
  NoDataMessageTextOnlyHeight
} from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import dimensions from 'ecto-common/lib/styles/dimensions';
import DropdownButton, {
  DropdownButtonOptionType
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import Icons from 'ecto-common/lib/Icons/Icons';
import AddButton from 'ecto-common/lib/Button/AddButton';
import DuplicateButton from 'ecto-common/lib/Button/DuplicateButton';
import ShareButton from 'ecto-common/lib/Button/ShareButton';
import UsersButton from 'ecto-common/lib/Button/UsersButton';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import StandardButton from 'ecto-common/lib/Button/StandardButton';

export const DoubleIconColumnWidth =
  dimensions.iconButtonWidth * 2 + dimensions.smallMargin * 2;

interface StandardTableButtonProps<ObjectType> {
  onAction?(item: ObjectType, index: number): void;
  item?: ObjectType;
  index?: number;
}

type StandardEditButtonProps<ObjectType> =
  StandardTableButtonProps<ObjectType> & {
    createEditOptions?(item: ObjectType): DropdownButtonOptionType[];
    shouldDisableEdit?(item: ObjectType): boolean;
  };

function StandardEditButton<ObjectType>({
  onAction,
  createEditOptions,
  item,
  index,
  shouldDisableEdit
}: StandardEditButtonProps<ObjectType>) {
  const options: DropdownButtonOptionType[] = createEditOptions
    ? createEditOptions(item)
    : [];

  if (options.length > 1) {
    return (
      <DropdownButton isIconButton options={options}>
        <Icons.Edit />
      </DropdownButton>
    );
  }

  return (
    <EditButton
      isIconButton
      disabled={shouldDisableEdit && shouldDisableEdit(item)}
      onClick={(e) => {
        e.stopPropagation();
        if (onAction) {
          onAction(item, index);
        } else if (options.length === 1) {
          _.head(options).action(e);
        }
      }}
    />
  );
}

function StandardUsersButton<ObjectType>({
  onAction,
  item,
  index
}: StandardTableButtonProps<ObjectType>) {
  return (
    <UsersButton
      isIconButton
      onClick={(e) => {
        e.stopPropagation();
        onAction(item, index);
      }}
    />
  );
}

function StandardDuplicateButton<ObjectType>({
  onAction,
  item,
  index,
  tooltipText,
  shouldDisableDuplicate
}: StandardTableButtonProps<ObjectType> & {
  tooltipText?: string;
  shouldDisableDuplicate?(item: ObjectType): boolean;
}) {
  return (
    <DuplicateButton
      isIconButton
      tooltipText={tooltipText}
      disabled={shouldDisableDuplicate && shouldDisableDuplicate(item)}
      onClick={(e) => {
        e.stopPropagation();
        onAction(item, index);
      }}
    />
  );
}

type StandardDeleteButtonProps<ObjectType> =
  StandardTableButtonProps<ObjectType> & {
    shouldDisableDelete?(item: ObjectType): boolean;
  };

function StandardDeleteButton<ObjectType>({
  onAction,
  item,
  index,
  shouldDisableDelete
}: StandardDeleteButtonProps<ObjectType>) {
  return (
    <DeleteButton
      isIconButton
      disabled={shouldDisableDelete && shouldDisableDelete(item)}
      onClick={(e) => {
        e.stopPropagation();
        onAction(item, index);
      }}
    />
  );
}

function StandardShareButton<ObjectType>({
  onAction,
  item,
  index
}: StandardTableButtonProps<ObjectType>) {
  return (
    <ShareButton
      isIconButton
      onClick={(e) => {
        e.stopPropagation();
        onAction(item, index);
      }}
    />
  );
}

type StandardAddButtonProps<ObjectType> =
  StandardTableButtonProps<ObjectType> & {
    shouldDisableAdd?(item: ObjectType): boolean;
  };

function StandardAddButton<ObjectType>({
  onAction,
  item,
  index,
  shouldDisableAdd
}: StandardAddButtonProps<ObjectType>) {
  return (
    <AddButton
      isIconButton
      disabled={shouldDisableAdd && shouldDisableAdd(item)}
      onClick={(e) => {
        e.stopPropagation();
        onAction(item, index);
      }}
    />
  );
}

type ButtonColumn<ItemType> = {
  icon: JSX.Element;
  action(item: ItemType, index: number): void;
  enabled?(item: ItemType, index: number): boolean;
  tooltipText?: string;
};

function CustomTableButton<ObjectType>({
  icon,
  action,
  enabled,
  index,
  item,
  tooltipText
}: ButtonColumn<ObjectType> & { index: number; item: ObjectType }) {
  return (
    <StandardButton
      key={index}
      isIconButton
      disabled={enabled != null && !enabled(item, index)}
      tooltipText={tooltipText}
      onClick={(e) => {
        e.stopPropagation();
        if (e.target instanceof HTMLElement) {
          e.target.blur();
        }

        action(item, index);
      }}
      icon={icon}
    />
  );
}

export function buttonListColumn<ObjectType>(
  buttons: ButtonColumn<ObjectType>[]
): DataTableColumnProps<ObjectType> {
  const columnWidth =
    dimensions.iconButtonWidth * buttons.length + dimensions.smallMargin * 2;

  return {
    label: null,
    dataKey: '_unused__buttonListColumn',
    minWidth: columnWidth,
    maxWidth: columnWidth,
    dataFormatter: (unused: unknown, item: ObjectType, index: number) => (
      <div style={{ display: 'flex' }}>
        {buttons.map((button, idx) => (
          <CustomTableButton
            key={idx}
            icon={button.icon}
            action={button.action}
            enabled={button.enabled}
            index={index}
            item={item}
            tooltipText={button.tooltipText}
          />
        ))}
      </div>
    )
  };
}

type StandardColumnsProps<ObjectType> = {
  onEdit?(item: ObjectType, index: number): void;
  createEditOptions?(item: ObjectType): DropdownButtonOptionType[];
  onDelete?(item: ObjectType, index: number): void;
  shouldDisableDelete?(item: ObjectType): boolean;
  shouldDisableEdit?(item: ObjectType): boolean;
  onDuplicate?(item: ObjectType, index: number): void;
  shouldDisableDuplicate?(item: ObjectType): boolean;
  onShare?(item: ObjectType, index: number): void;
  onClickEditUsers?(item: ObjectType, index: number): void;
  onAdd?(item: ObjectType, index: number): void;
  shouldDisableAdd?(item: ObjectType): boolean;
  duplicateTooltipText?: string;
  extraButtons?: Array<ButtonColumn<ObjectType>>;
};

export const widthForTableColumnButtons = (numButtons: number) =>
  dimensions.iconButtonWidth * numButtons + dimensions.smallMargin * 2;

export function standardColumns<ObjectType>({
  onEdit = undefined,
  createEditOptions = undefined,
  onDelete = undefined,
  shouldDisableDelete = undefined,
  shouldDisableEdit = undefined,

  shouldDisableDuplicate = undefined,
  duplicateTooltipText = T.common.duplicate,
  onDuplicate = undefined,
  onShare = undefined,
  onClickEditUsers = undefined,
  onAdd = undefined,
  shouldDisableAdd = undefined,
  extraButtons = undefined
}: StandardColumnsProps<ObjectType>): DataTableColumnProps<ObjectType>[] {
  if (onEdit && createEditOptions) {
    console.error('You should specify either onEdit or createEditOptions');
    return [];
  }

  const numExtraButtons = extraButtons?.length ?? 0;

  const showEditButton = onEdit || createEditOptions;
  const numButtons =
    _.filter(
      [showEditButton, onDelete, onDuplicate, onShare, onClickEditUsers, onAdd],
      (x) => !!x
    ).length + numExtraButtons;
  const columnWidth = widthForTableColumnButtons(numButtons);

  return [
    {
      label: null,
      dataKey: '_unused__buttonListColumn',
      minWidth: columnWidth,
      maxWidth: columnWidth,
      dataFormatter: (unused: unknown, item: ObjectType, index: number) => (
        <div style={{ display: 'flex' }}>
          {extraButtons &&
            extraButtons.map((button, idx) => (
              <CustomTableButton
                key={idx}
                icon={button.icon}
                action={button.action}
                enabled={button.enabled}
                index={index}
                item={item}
                tooltipText={button.tooltipText}
              />
            ))}
          {onShare && (
            <StandardShareButton<ObjectType>
              index={index}
              item={item}
              onAction={onShare}
            />
          )}
          {showEditButton && (
            <StandardEditButton<ObjectType>
              index={index}
              item={item}
              onAction={onEdit}
              createEditOptions={createEditOptions}
              shouldDisableEdit={shouldDisableEdit}
            />
          )}
          {onClickEditUsers && (
            <StandardUsersButton<ObjectType>
              index={index}
              item={item}
              onAction={onClickEditUsers}
            />
          )}
          {onDuplicate && (
            <StandardDuplicateButton<ObjectType>
              index={index}
              item={item}
              onAction={onDuplicate}
              tooltipText={duplicateTooltipText}
              shouldDisableDuplicate={shouldDisableDuplicate}
            />
          )}
          {onDelete && (
            <StandardDeleteButton<ObjectType>
              index={index}
              item={item}
              onAction={onDelete}
              shouldDisableDelete={shouldDisableDelete}
            />
          )}
          {onAdd && (
            <StandardAddButton<ObjectType>
              index={index}
              item={item}
              onAction={onAdd}
              shouldDisableAdd={shouldDisableAdd}
            />
          )}
        </div>
      )
    }
  ];
}

export function truncateColumns<ObjectType>(
  columns: DataTableColumnProps<ObjectType>[],
  truncateText = true
): DataTableColumnProps<ObjectType>[] {
  return _.map(columns, (col) => ({ ...col, truncateText }));
}

export function addOrEditColumnsColumn<ObjectType>(
  onEdit: (item: ObjectType, index: number) => void,
  createEditOptions: (item: ObjectType) => DropdownButtonOptionType[],
  onDelete: (item: ObjectType, index: number) => void,
  onAdd: (item: ObjectType, index: number) => void,
  shouldDisableDelete: (item: ObjectType) => boolean,
  shouldShowAdd: (item: ObjectType, index: number) => boolean
): DataTableColumnProps<ObjectType>[] {
  return [
    {
      label: null,
      dataKey: '_unused__editAddAndDeleteButton',
      minWidth: DoubleIconColumnWidth,
      maxWidth: DoubleIconColumnWidth,
      dataFormatter: (unused: unknown, item: ObjectType, index: number) => {
        if (shouldShowAdd(item, index)) {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StandardAddButton index={index} item={item} onAction={onAdd} />
            </div>
          );
        }
        return (
          <div style={{ display: 'flex' }}>
            <StandardEditButton
              index={index}
              item={item}
              onAction={onEdit}
              createEditOptions={createEditOptions}
            />
            <StandardDeleteButton
              index={index}
              item={item}
              onAction={onDelete}
              shouldDisableDelete={shouldDisableDelete}
            />
          </div>
        );
      }
    }
  ];
}

export function checkboxColumn<ObjectType>({
  label = null,
  rowOnChange = undefined,
  rowIsChecked,
  checkAll = undefined,
  checkAllIsChecked = false,
  width = 40
}: {
  label?: React.ReactNode;
  rowOnChange?: (item: ObjectType) => void;
  rowIsChecked?: boolean | ((item: ObjectType) => boolean);
  checkAll?: (checked: boolean) => void;
  checkAllIsChecked?: boolean;
  width?: number;
}) {
  const _label = checkAll ? (
    <Checkbox checked={checkAllIsChecked} onChange={checkAll} />
  ) : (
    label
  );

  const _onChange = (_checked: boolean, item: ObjectType) => {
    rowOnChange?.(item);
  };

  return {
    label: _label,
    dataKey: '_unused__checkbox',
    width,
    flexGrow: 0,
    minWidth: 32,
    dataFormatter: (unused: unknown, item: ObjectType) => {
      return (
        <Checkbox
          checked={
            _.isFunction(rowIsChecked) ? rowIsChecked(item) : rowIsChecked
          }
          onChange={(checked) => _onChange(checked, item)}
        />
      );
    }
  };
}

/**
 * Calculates a minimum height that is needed to display a DataTable. This is useful if you want
 * auto-sizing dialogs that do not expand once the data is loaded.
 *
 * Note that the actual size might be larger if some columns overflow with text. To guarantee
 * that they stay within this size, consider adding the truncateText property to the column
 * definitions. This will make the text truncate with ellipsis if the text is too wide to fit.
 *
 * If there is no data in the table, the NoDataMessage might expand the height beyond the value returned here.
 * Consider setting showNoticeHeaders to false in order to get a smaller message if this is important.
 *
 * @param pageSize The max number of rows that will be shown in the table
 * @param withCheckbox If any of the columns contain a checkbox
 * @param withCheckboxInHeader If the header contains a checkbox (i.e. check all)
 * @param withButtons If any of the columns contains a button
 * @param withSelect If any of the columns contains a select control
 * @param showNoticeHeaders If showNoticeHeaders is enabled, i.e. large or small notices
 * @param disableHeader If the data table header is disabled
 * @returns {number} A minimum height that will display all of the cells in the data table.
 */
export const calculateDataTableMinHeight = ({
  pageSize,
  withCheckbox = false,
  withCheckboxInHeader = false,
  withButtons = false,
  withSelect = false,
  showNoticeHeaders = true,
  disableHeader = false
}: {
  pageSize: number;
  withCheckbox?: boolean;
  withCheckboxInHeader?: boolean;
  withButtons?: boolean;
  withSelect?: boolean;
  showNoticeHeaders?: boolean;
  disableHeader?: boolean;
}) => {
  let rowHeight = DataTableRowMinHeight.STANDARD;

  if (withSelect) {
    rowHeight = DataTableRowMinHeight.WITH_SELECT;
  } else if (withButtons) {
    rowHeight = DataTableRowMinHeight.WITH_BUTTONS;
  } else if (withCheckbox) {
    rowHeight = DataTableRowMinHeight.WITH_CHECKBOX;
  }

  let minHeight = pageSize * rowHeight;

  if (!disableHeader) {
    if (withCheckboxInHeader) {
      minHeight += DataTableRowMinHeight.WITH_CHECKBOX;
    } else {
      minHeight += DataTableRowMinHeight.STANDARD;
    }
  }

  if (showNoticeHeaders) {
    minHeight = Math.max(minHeight, NoDataMessageHeight);
  } else {
    minHeight = Math.max(minHeight, NoDataMessageTextOnlyHeight);
  }

  return minHeight;
};
