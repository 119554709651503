import React, { KeyboardEvent } from 'react';
import classNames from 'classnames';
import styles from './LocationTreeView.module.css';
import { ArrowIcon } from 'ecto-common/lib/Icon/index';
import _ from 'lodash';
import { LocationTreeViewNode } from 'ecto-common/lib/LocationTreeView/LocationTreeView';

// Use graphical ASCII characters as constants to make it easy to debug print tree
const COLUMN_BLANK = ' ';
const COLUMN_VERTICAL = '|';
const COLUMN_VERTICAL_HALF_TOP = '.';
const COLUMN_VERTICAL_HALF_BOTTOM = ':';
const COLUMN_HORIZONTAL = '-';
const COLUMN_DOT = '*';
const COLUMN_DOT_LINE = '+';

const BASE_COLUMN_STYLES: Record<string, string> = {
  [COLUMN_BLANK]: styles.column,
  [COLUMN_VERTICAL]: classNames(styles.column, styles.verticalColumn),
  [COLUMN_VERTICAL_HALF_TOP]: classNames(
    styles.column,
    styles.midHalfVerticalColumn
  ),
  [COLUMN_VERTICAL_HALF_BOTTOM]: classNames(
    styles.column,
    styles.halfVerticalColumn
  ),
  [COLUMN_HORIZONTAL]: classNames(styles.column, styles.horizontalColumn)
};

export type LocationTreeViewRowType = {
  prefix: string;
  node: LocationTreeViewNodeWithChildren;
  parentNode: LocationTreeViewNodeWithChildren;
  searchPath: string;
};

export type LocationTreeViewNodeWithChildren = LocationTreeViewNode & {
  children: LocationTreeViewNodeWithChildren[];
};

/**
 *  Returns a list of rows for the location tree view. Each row has a "prefix list" - this is basically
 *  a list of columns that is used to draw the hierarchical lines of the tree. These are then used to
 *  create divs which makes up the visual appearance of the lines.
 */
export const getTreeRowsForNodes = (
  nodes: LocationTreeViewNodeWithChildren[],
  prefix: string,
  parentNode: LocationTreeViewNodeWithChildren,
  isLastChild: boolean,
  expandedState: Record<string, boolean>
): LocationTreeViewRowType[] => {
  if (parentNode) {
    if (isLastChild) {
      prefix = prefix.concat(COLUMN_BLANK);
    } else {
      prefix = prefix.concat(COLUMN_VERTICAL);
    }
  }

  return _.flatMap(nodes, (node, idx: number) => {
    let subPrefix;
    const childrenArray = node.children;
    const nodeIsExpanded = !expandedState || expandedState[node.nodeId];
    const dot =
      nodeIsExpanded && childrenArray.length > 0 ? COLUMN_DOT_LINE : COLUMN_DOT;

    if (idx === 0 && parentNode == null) {
      if (nodes.length > 1) {
        subPrefix = COLUMN_VERTICAL_HALF_TOP + COLUMN_HORIZONTAL + dot;
      } else {
        subPrefix = COLUMN_BLANK + COLUMN_BLANK + dot;
      }
    } else if (idx === nodes.length - 1) {
      subPrefix = COLUMN_VERTICAL_HALF_BOTTOM + COLUMN_HORIZONTAL + dot;
    } else {
      subPrefix = COLUMN_VERTICAL + COLUMN_HORIZONTAL + dot;
    }

    const initialNode = {
      prefix: prefix.concat(subPrefix),
      node: node,
      parentNode,
      searchPath: ''
    };

    if (nodeIsExpanded) {
      return [
        initialNode,
        ...getTreeRowsForNodes(
          childrenArray,
          prefix,
          node,
          idx === nodes.length - 1,
          expandedState
        )
      ];
    }

    return [initialNode];
  });
};

const renderPrefix = (prefix: string, dotElement: React.ReactNode) => {
  return _.map(prefix, (part, idx) => {
    switch (part) {
      case COLUMN_DOT:
        return (
          <div key={idx} className={styles.dotContainer}>
            {dotElement}
          </div>
        );
      case COLUMN_DOT_LINE:
        return (
          <div key={idx} className={styles.dotContainer}>
            {[
              dotElement,
              <div key={idx} className={styles.midHalfVerticalColumn} />
            ]}
          </div>
        );
      default:
        return (
          <div key={idx} className={styles.columnContainer}>
            <div key={idx} className={BASE_COLUMN_STYLES[part]} />
          </div>
        );
    }
  });
};

interface LocationTreeViewRowProps {
  node?: LocationTreeViewNodeWithChildren;
  parentNode?: object;
  prefix?: string;
  expandAll?: boolean;
  allowSelectingRootNodes?: boolean;
  expandPath?(path: string, toggle: boolean): void;
  expanded?: boolean;
  multiSelect?: boolean;
  multiSelectFilter?(node: LocationTreeViewNodeWithChildren): boolean;
  onChangeSelectedState?(parentId: string, isSelected: boolean): void;
  onKeyUp?: (
    event: KeyboardEvent<HTMLDivElement>,
    node: LocationTreeViewNodeWithChildren
  ) => void;
  selectLocation?(
    location: LocationTreeViewNodeWithChildren,
    isSelected: boolean
  ): void;
  selected?: boolean;
  isEvenRow?: boolean;
  renderRowIcons?: (node: LocationTreeViewNodeWithChildren) => React.ReactNode;
  renderRowSideIcons?: (
    node: LocationTreeViewNodeWithChildren
  ) => React.ReactNode;
  hasChildren: boolean;
}

const LocationTreeViewRow = ({
  node,
  parentNode,
  prefix,
  expandAll,
  allowSelectingRootNodes,
  expandPath,
  expanded,
  multiSelect,
  multiSelectFilter,
  onChangeSelectedState,
  onKeyUp,
  selectLocation,
  selected,
  isEvenRow,
  renderRowIcons,
  renderRowSideIcons,
  hasChildren
}: LocationTreeViewRowProps) => {
  const rowStyle = { paddingLeft: 0 };

  let showCheckbox = multiSelect;

  if (multiSelectFilter) {
    showCheckbox = multiSelectFilter(node);
  }
  const icon: React.ReactNode = renderRowIcons && renderRowIcons(node);

  const showTreeDot =
    icon == null &&
    (!showCheckbox || (parentNode == null && !allowSelectingRootNodes));

  let dotElement = null;
  let checkboxElement = null;

  if (showTreeDot) {
    dotElement = (
      <div
        key={'dot' + node.nodeId}
        className={classNames(
          styles.treeDot,
          !showCheckbox && selected && styles.selectedBackground
        )}
      />
    );
  } else if (showCheckbox) {
    checkboxElement = (
      <input
        type="checkbox"
        onChange={(e) => onChangeSelectedState(node.nodeId, e.target.checked)}
        checked={selected}
      />
    );
    dotElement = (
      <div
        key={'dot' + node.nodeId}
        className={classNames(
          styles.treeDot,
          styles.checkboxDot,
          !showCheckbox && selected && styles.selectedBackground
        )}
      >
        {checkboxElement}
      </div>
    );
  }

  const selectedClass = !multiSelect && selected && styles.selected;

  const suffix: React.ReactNode = '';

  return (
    <div
      id={node.nodeId}
      key={node.nodeId}
      tabIndex={0}
      onKeyUp={(e) => onKeyUp(e, node)}
      onClick={(e) =>
        e.target === e.currentTarget && selectLocation(node, selected)
      }
      style={rowStyle}
      className={classNames(
        styles.row,
        !showCheckbox && selected && styles.selected,
        isEvenRow && styles.evenRow,
        selectedClass
      )}
    >
      {prefix && renderPrefix(prefix, dotElement)}
      <div
        className={classNames(styles.text, parentNode == null && styles.strong)}
        onClick={() => selectLocation(node, selected)}
        data-test={node.name}
      >
        {!prefix && showCheckbox && checkboxElement}
        {icon && (
          <div
            className={classNames(
              styles.iconElement,
              showCheckbox && styles.iconElementCheckbox
            )}
          >
            {icon}
          </div>
        )}

        <div>
          {' '}
          {node.name} {suffix}
        </div>
      </div>
      {renderRowSideIcons && (
        <div
          className={styles.sideIconsContainer}
          onClick={() => selectLocation(node, selected)}
        >
          {renderRowSideIcons(node)}
        </div>
      )}
      {!expandAll && (
        <div
          onClick={() => expandPath(node.nodeId, true)}
          className={classNames(
            styles.arrowContainer,
            hasChildren && styles.visible,
            selected && !multiSelect && styles.selected
          )}
        >
          <ArrowIcon
            className={classNames(
              styles.arrow,
              expanded && styles.arrowExpanded
            )}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationTreeViewRow);
