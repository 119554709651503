import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';
import colors from 'ecto-common/lib/styles/variables/colors';
import { ConnectionModel } from 'ecto-common/lib/API/PresentationAPIGen';
import T from 'ecto-common/lib/lang/Language';
import {
  SignalResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

export type ProcessMapViewSignal =
  SignalProviderSignalWithProviderResponseModel & {
    signalType: SignalTypeResponseModel;
    unit: UnitResponseModel;
    rawSignal: SignalResponseModel;
  };

export const ProcessMapRectShapes = {
  Rectangle: 'rectangle',
  Circle: 'circle',
  Triangle: 'triangle'
};

export type ProcessMapRectShape = ObjectValues<typeof ProcessMapRectShapes>;

export const connectionCircleRadiusPixels = 8;

export type ProcessMapRect = {
  id: string;
  centerX: number;
  centerY: number;
  width: number;
  height: number;
};

export type ProcessMapLineRect = ProcessMapRect & {
  isRounded?: boolean;
};

export const ProcessMapTextAlignments = {
  Left: 'left',
  Center: 'center',
  Right: 'right'
} as const;

export type ProcessMapTextAlignment = ObjectValues<
  typeof ProcessMapTextAlignments
>;

export const ProcessMapObjectTypes = {
  Symbol: 'symbol',
  Line: 'line',
  Signal: 'signal',
  Rect: 'rect',
  Text: 'text'
} as const;

export type ProcessMapObjectType = ObjectValues<typeof ProcessMapObjectTypes>;

export const ProcessMapRuleTypes = {
  LessThan: '<',
  LessThanOrEqual: '<=',
  Equal: '=',
  GreaterThanOrEqual: '>=',
  GreaterThan: '>',
  NotEqual: '!='
} as const;

export type ProcessMapRuleType = ObjectValues<typeof ProcessMapRuleTypes>;

export const ProcessMapRuleTypeOptions: GenericSelectOption<ProcessMapRuleType>[] =
  Object.values(ProcessMapRuleTypes).map((value) => ({
    label: value,
    value
  }));

export type ProcessMapRule = {
  type: ProcessMapRuleType;
  signalTypeId: string;
  signalId?: string;
  value: number;
};

export type ProcessMapSymbolRule = {
  state: string;
  condition: ProcessMapRule;
};

export const ProcessMapActionTypes = {
  EquipmentType: 'equipment_type',
  Node: 'node_id',
  Signal: 'signal',
  SignalDialog: 'signal_dialog'
} as const;

export type ProcessMapActionType = ObjectValues<typeof ProcessMapActionTypes>;

export const ActionTypeTranslations = {
  [ProcessMapActionTypes.EquipmentType]: T.signals.equipmenttype,
  [ProcessMapActionTypes.Signal]: T.alarms.columns.signal,
  [ProcessMapActionTypes.Node]: T.admin.processmaps.clickaction.node,
  [ProcessMapActionTypes.SignalDialog]: T.admin.processmaps.signaldialog.type
} as const;

export type EquipmentProcessMapAction = {
  type: typeof ProcessMapActionTypes.EquipmentType;
  equipmentType: string;
};

export type SignalProcessMapAction = {
  type: typeof ProcessMapActionTypes.Signal;
};

export const ProcessMapSignalRuleFunctions = {
  Visible: 'visible',
  Enabled: 'enabled'
} as const;

export type ProcessMapSignalRuleFunction = ObjectValues<
  typeof ProcessMapSignalRuleFunctions
>;

export type SignalDialogRule = {
  signalId: string;
  compareSignalId: string;
  operator: ProcessMapRuleType;
  function: ProcessMapSignalRuleFunction;
  expectedValue: number;
};

export type SignalDialogProcessMapAction = {
  type: typeof ProcessMapActionTypes.SignalDialog;
  signalIds?: string[];
  signalRules?: SignalDialogRule[];
};

export type NodeProcessMapAction = {
  type: typeof ProcessMapActionTypes.Node;
  nodeId: string;
  previewSignalIds?: string[];
};

type ProcessMapAction =
  | EquipmentProcessMapAction
  | SignalProcessMapAction
  | NodeProcessMapAction
  | SignalDialogProcessMapAction;

export type BaseProcessMapObject = {
  id: string;
  type: ProcessMapObjectType;
  rects: ProcessMapRect[];
  action?: ProcessMapAction;
};

export type ProcessMapSymbolObject = BaseProcessMapObject & {
  type: typeof ProcessMapObjectTypes.Symbol;
  typeId: string;
  svgMd5: string;
  states: string[];
  connections: ConnectionModel[];
  rotation?: 0 | 90 | 180 | 270;
  flipVertical?: boolean;
  flipHorizontal?: boolean;
  scale?: number;
  symbolRules: ProcessMapSymbolRule[];
  originalWidth: number;
  originalHeight: number;
  hideWhenNoSignalsForRules?: boolean;
  hideWhenNoSignalId?: string;
  hideWhenNoSignalTypeId?: string;
};

export const ProcessMapLineCapStyles = {
  None: 'None',
  Arrow: 'Arrow',
  Circle: 'Circle'
} as const;

export type ProcessMapLineCapStyle = ObjectValues<
  typeof ProcessMapLineCapStyles
>;

export const ProcessMapLineModes = {
  Straight: 'straight',
  Path: 'path'
} as const;

export type ProcessMapLineMode = ObjectValues<typeof ProcessMapLineModes>;

export type ProcessMapLineObject = Omit<BaseProcessMapObject, 'rects'> & {
  type: typeof ProcessMapObjectTypes.Line;
  rects: ProcessMapLineRect[];
  mode?: ProcessMapLineMode;
  lineWidth?: number;
  endLineCapStyle?: ProcessMapLineCapStyle;
  startLineEndcapStyle?: ProcessMapLineCapStyle;
  color?: string;
  flipMidpoint?: boolean;
  dashed?: boolean;
};

export type ProcessMapTextSettings = {
  fontSize?: number;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  textColor?: string;
};

export type ProcessMapSignalTextObject = BaseProcessMapObject & {
  type: typeof ProcessMapObjectTypes.Signal;
  labelTextSettings: ProcessMapTextSettings;
  valueTextSettings: ProcessMapTextSettings;
  signalTypeId?: string;
  signalId?: string;
  hideLabel?: boolean;
  hideValue?: boolean;
  textAlignment?: ProcessMapTextAlignment;
};

export type ProcessMapRectObject = BaseProcessMapObject & {
  type: typeof ProcessMapObjectTypes.Rect;
  strokeWidth?: number;
  strokeColor?: string;
  fillColor?: string;
  cornerRadius?: number;
  fillColorRuleName?: string;
  fillColorRuleSignalTypeIds?: string[];
  fillColorRuleSignalIds?: string[];
  shape?: ProcessMapRectShape;
  rotation?: 0 | 90 | 180 | 270;
  labelTextSettings?: ProcessMapTextSettings;
  text?: string;
  textAlignment?: ProcessMapTextAlignment;
};

export type ProcessMapTextObject = BaseProcessMapObject & {
  type: typeof ProcessMapObjectTypes.Text;
  text: string;
  textSettings: ProcessMapTextSettings;
  textAlignment?: ProcessMapTextAlignment;
  hideWhenNoSignalTypeId?: string;
  hideWhenNoSignalId?: string;
};

export type ProcessMapLineConnection = {
  rectHandles: [ProcessMapRectHandle, ProcessMapRectHandle];
  markedForDeletion?: boolean;
};

export type ProcessMapRectLineConnection = {
  rectObjectHandle: ProcessMapObjectHandle;
  lineObjectRectHandle: ProcessMapRectHandle;
  connectionIndex: number;
  markedForDeletion?: boolean;
};

export type ProcessMapSymbolLineConnection = {
  symbolObjectHandle: ProcessMapObjectHandle;
  lineObjectRectHandle: ProcessMapRectHandle;
  connectionId: string;
  markedForDeletion?: boolean;
};

export const defaultTextSettings: ProcessMapTextSettings = {
  fontSize: 14,
  bold: false,
  italic: false
};

export const defaultSignalTitleSettings: ProcessMapTextSettings = {
  fontSize: 12,
  bold: true,
  italic: false,
  underline: true,
  textColor: null
};

export const defaultRectTitleSettings: ProcessMapTextSettings = {
  fontSize: 12,
  bold: true,
  italic: false,
  underline: false,
  textColor: null
};

export const defaultSignalSubtitleSettings: ProcessMapTextSettings = {
  fontSize: 12,
  bold: false,
  italic: false,
  textColor: null
};

export type ProcessMapObject =
  | ProcessMapSymbolObject
  | ProcessMapLineObject
  | ProcessMapTextObject
  | ProcessMapSignalTextObject
  | ProcessMapRectObject;
export type ProcessMapObjectHandle = {
  objectIndex: number;
  objectId: string;
};

export type ProcessMapRectHandle = {
  objectIndex: number;
  rectIndex: number;
  objectId: string;
  rectId: string;
};

export type ProcessMapDocument = {
  width: number;
  height: number;
  objects: ProcessMapObject[];
  rectLineConnections: ProcessMapRectLineConnection[];
  lineConnections: ProcessMapLineConnection[];
  symbolLineConnections: ProcessMapSymbolLineConnection[];
  svgImages: Record<string, string>;
};

export const DOCUMENT_WIDTH = 700;
export const DOCUMENT_HEIGHT = 600;

export type ProcessMapPendingConnectionLine = {
  lineRectHandle: ProcessMapRectHandle;
};

export type ProcessMapPendingConnectionSymbol = {
  symbolHandle: ProcessMapObjectHandle;
  connectionId: string;
};

export type ProcessMapPendingConnectionRect = {
  rectHandle: ProcessMapObjectHandle;
  connectionIndex: number;
};

export const smallConnectionCircleRadius = 7;
export const lineRectHeight = 14;

export const emptyProcessMapDocument: ProcessMapDocument = {
  width: DOCUMENT_WIDTH,
  height: DOCUMENT_HEIGHT,
  objects: [],
  lineConnections: [],
  symbolLineConnections: [],
  rectLineConnections: [],
  svgImages: {}
};

export const processMapLineSelectionMarginPixels = 15.0;
export const processMapDeleteConnectionButtonSize = 16;
export const processMapLineArrowLength = 12;
export const processMapLineRectLength = 8;

const ProcessMapColorRuleTypes = {
  Discrete: 'discrete'
} as const;

export type ProcessMapColorRuleType = ObjectValues<
  typeof ProcessMapColorRuleTypes
>;

type ProcessMapColorRule = {
  type: typeof ProcessMapColorRuleTypes.Discrete;
  colors: [string, string];
  numSignalValues: 1;
};

export const processMapColorRules: Record<string, ProcessMapColorRule> = {
  Alarm: {
    type: ProcessMapColorRuleTypes.Discrete,
    numSignalValues: 1,
    colors: [colors.failureColor, colors.successColor]
  },
  Status: {
    type: ProcessMapColorRuleTypes.Discrete,
    numSignalValues: 1,
    colors: [colors.successColor, colors.surface3Color]
  }
};

export const ProcessMapRectResizeAreas = {
  TopLeft: 'topLeft',
  BottomRight: 'bottomRight'
} as const;

export type ProcessMapRectResizeArea = ObjectValues<
  typeof ProcessMapRectResizeAreas
>;

export const processMapNewObjectApproxHeight = 300;

export const processMapNewLineSize = 100;

export const processMapMinTextSize = 10;

export const processMapSvgDataUrlPrefix = 'data:image/svg+xml;base64,';

export type DefineAreaState = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

export const processMapRotationOptions: GenericSelectOption<number>[] = [
  {
    label: '0',
    value: 0
  },
  {
    label: '90',
    value: 90
  },
  {
    label: '180',
    value: 180
  },
  {
    label: '270',
    value: 270
  }
];
