import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDefinitionInternal,
  ModelDynamicOptionsTreeProperty,
  TreeSelectOption
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import TreeSelect from 'ecto-common/lib/Select/TreeSelect';

export type TreeOptionsModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = object
> = {
  modelType: typeof ModelType.OPTIONS_TREE;
  isClearable?: boolean;
  // All available options that can be changed for the current item.
  options?: ModelDynamicOptionsTreeProperty<
    ObjectType,
    EnvironmentType,
    ValueType
  >;
  withDivider?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, ValueType>;

type ModelEditorOptionsProps = ModelEditorProps & {
  options: TreeSelectOption[];
  model: TreeOptionsModelDefinition<object, object, unknown>;
};

const ModelEditorOptionsTree = ({
  model,
  options,
  updateItem,
  disabled,
  rawValue,
  hasError,
  helpText = null,
  isHorizontal = false,
  modelIsLoading = false,
  useTooltipHelpTexts = false
}: ModelEditorOptionsProps) => {
  const value = useMemo(() => {
    if (rawValue == null) {
      return null;
    } else if (model.isMultiOption) {
      return options.filter((x) => rawValue.includes(x.value));
    }

    return options.find((x) => x.value === rawValue);
  }, [rawValue, model.isMultiOption, options]);

  const onChange = useCallback(
    (e: TreeSelectOption) => {
      if (model.isMultiOption) {
        updateItem(_.map(e, 'value'));
      } else {
        updateItem(e?.value ?? null);
      }
    },
    [model, updateItem]
  );

  const allOptions = {
    keyText: model.label,
    disabled: disabled,
    value: value,
    onChange: onChange,
    placeholder: model.placeholder,
    options: options,
    hasError: hasError,
    isClearable: model.isClearable,
    horizontalWeights: model.horizontalWeights,
    isHorizontal,
    helpText,
    useTooltipHelpTexts
  };

  return (
    <KeyValueGeneric keyText={model.label} {...allOptions}>
      <TreeSelect
        options={options}
        value={value}
        onChange={onChange}
        isLoading={modelIsLoading}
        isClearable={model.isClearable}
        isDisabled={disabled}
      />
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorOptionsTree);
