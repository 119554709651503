import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import APIGen, {
  IoTDeviceViewResponseModel,
  IoTDeviceWithDeviceResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

export type IoTDeviceWithDeviceAndTags = IoTDeviceViewResponseModel & {
  availableTags?: string[];
};

export type UseEditIoTDeviceLoadingInfo = {
  isLoadingAvailableTags: boolean;
  isSaving: boolean;
  isLoadingDeviceInfo: boolean;
};

export type UseEditIoTDeviceResult = {
  editDevice: IoTDeviceViewResponseModel;
  hasChanges: boolean;
  loadingInfo: UseEditIoTDeviceLoadingInfo;
  saveChanges: () => void;
  clearDevice: () => void;
  onDeviceDataChanged: (name: string[], value: unknown) => void;
};

const emptyTags: string[] = [];

const useEditIoTDevice = (
  initialDevice: IoTDeviceViewResponseModel | IoTDeviceWithDeviceResponseModel
): UseEditIoTDeviceResult => {
  const [editDevice, setEditDevice] = useState(initialDevice);
  const [hasChanges, setHasChanges] = useState(false);

  const deviceId = initialDevice?.ioTDevice?.id;
  const iotDeviceInfoQuery =
    APIGen.AdminIoTDevices.getIoTDeviceViewByIoTDeviceId.useQuery(
      {
        Ids: [deviceId]
      },
      {
        enabled: deviceId != null
      }
    );

  const [lastDeviceData, setLastDeviceData] = useState(
    iotDeviceInfoQuery.data?.[0]
  );

  if (
    iotDeviceInfoQuery.data != null &&
    lastDeviceData !== iotDeviceInfoQuery.data?.[0]
  ) {
    setLastDeviceData(iotDeviceInfoQuery.data[0]);
    setEditDevice((originalDevice) => ({
      ...originalDevice,
      ...iotDeviceInfoQuery.data[0]
    }));
  }

  const hadInfoError = useRef(false);
  const hadTagsError = useRef(false);
  if (iotDeviceInfoQuery.isError && !hadInfoError.current) {
    toastStore.addErrorToast(
      T.admin.iotdevicedetails.error.failedtofetchdeviceinfo
    );
    hadInfoError.current = true;
  }

  const getTagsQuery = APIGen.AdminIoTDevices.getIoTDeviceTags.useQuery();
  const availableTags = getTagsQuery.data ?? emptyTags;
  if (getTagsQuery.isError && !hadTagsError.current) {
    toastStore.addErrorToast(T.admin.iotdevicedetails.error.failedtofetchtags);
    hadTagsError.current = true;
  }

  const clearDevice = useCallback(() => {
    setEditDevice(null);
  }, []);

  const onDeviceDataChanged = useCallback((name: string[], value: unknown) => {
    setEditDevice((originalDevice) => {
      const newDevice = { ...originalDevice };
      _.set(newDevice, name, value);
      setHasChanges(true);
      return newDevice;
    });
  }, []);

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const saveDeviceMutation =
    APIGen.AdminIoTDevices.addOrUpdateIoTDevice.useMutation({
      onSuccess: () => {
        setHasChanges(false);
        queryClient.invalidateQueries({
          queryKey:
            APIGen.AdminIoTDevices.getIoTDeviceTags.path(contextSettings)
        });
        toastStore.addSuccessToast(T.admin.iotdevicedetails.savesuccess);
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.iotdevicedetails.savefailed);
      }
    });

  const saveChanges = useCallback(() => {
    saveDeviceMutation.mutate([editDevice.ioTDevice]);
  }, [editDevice, saveDeviceMutation]);

  // Append available tags to device information so it can be used by tag selector
  const _editDevice: IoTDeviceWithDeviceAndTags = useMemo(() => {
    if (editDevice) {
      return { ...editDevice, availableTags };
    }
    return initialDevice;
  }, [editDevice, initialDevice, availableTags]);

  return {
    editDevice: _editDevice,
    hasChanges,
    loadingInfo: {
      isLoadingAvailableTags: getTagsQuery.isLoading,
      isSaving: saveDeviceMutation.isPending,
      isLoadingDeviceInfo: deviceId != null && iotDeviceInfoQuery.isLoading
    },
    saveChanges,
    clearDevice,
    onDeviceDataChanged
  };
};

export default useEditIoTDevice;
