import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import classNames from 'classnames';
import colors from 'ecto-common/lib/styles/variables/colors';
import styles from './ProcessMapObjectViews.module.css';
import _ from 'lodash';
import { Base64 } from 'js-base64';
import {
  ProcessMapSymbolObject,
  processMapDeleteConnectionButtonSize,
  ProcessMapViewSignal
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import removeIcon from '../ProcessMapDeleteIcon.svg';
import {
  evaluateSymbolRules,
  ConnectionModelPoints
} from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import { LastSignalValuesDataSetWithMetadata } from 'ecto-common/lib/Dashboard/panels/SignalListPanel';
import {
  ProcessMapObjectsProps,
  ProcessMapOverlayObjectProps
} from 'ecto-common/lib/ProcessMap/ProcessMapObjectProps';

const applyStates = (svgElement: SVGGElement, activeStates: string[]) => {
  if (svgElement == null) {
    return;
  }
  const elements = svgElement.querySelectorAll('[data-symbol-state]');

  for (const element of elements) {
    const state = element.getAttribute('data-symbol-state');
    if (activeStates.includes(state)) {
      element.setAttribute('display', 'auto');
    } else {
      element.setAttribute('display', 'none');
    }
  }
};

export const ProcessMapSymbolView = React.memo(
  ({
    selectedRectHandles,
    allSignalsBySignalTypeOrSignalId,
    signalData,
    svgImages,
    node: nodeIn,
    isHovering,
    editMode,
    onMouseOver,
    onMouseOut,
    onClick
  }: ProcessMapObjectsProps) => {
    const node = nodeIn as ProcessMapSymbolObject;
    const amongSelected = selectedRectHandles.some(
      (handle) => handle.objectId === node.id
    );
    const isDragging = amongSelected;

    let signal: ProcessMapViewSignal = null;
    const matchingRule = _.find(
      node.symbolRules,
      (rule) =>
        allSignalsBySignalTypeOrSignalId[rule.condition.signalTypeId] != null ||
        allSignalsBySignalTypeOrSignalId[rule.condition.signalId] != null
    );
    const signalIdOrTypeId =
      matchingRule?.condition?.signalTypeId ??
      matchingRule?.condition?.signalId;
    let value: LastSignalValuesDataSetWithMetadata = null;

    if (signalIdOrTypeId != null) {
      signal = allSignalsBySignalTypeOrSignalId[signalIdOrTypeId];
      value = signalData[signal?.signalId];
    }

    let shouldHide = false;

    if (node.symbolRules?.length > 0 && node.hideWhenNoSignalsForRules) {
      shouldHide = !_.some(
        node.symbolRules,
        (rule) =>
          allSignalsBySignalTypeOrSignalId[rule.condition.signalTypeId] !=
            null ||
          allSignalsBySignalTypeOrSignalId[rule.condition.signalId] != null
      );
    }

    if (
      (node.hideWhenNoSignalTypeId != null &&
        allSignalsBySignalTypeOrSignalId[node.hideWhenNoSignalTypeId] ==
          null) ||
      (node.hideWhenNoSignalId != null &&
        allSignalsBySignalTypeOrSignalId[node.hideWhenNoSignalId] == null)
    ) {
      shouldHide = true;
    }

    const _onClick = useCallback(
      (event: MouseEvent) => {
        onClick?.(event, node, signal, value?.isWritable);
      },
      [onClick, node, signal, value]
    );

    const _onMouseOver = useCallback(
      (event: MouseEvent) => {
        onMouseOver?.(event, node, signal, value?.isWritable);
      },
      [onMouseOver, node, signal, value]
    );

    const _onMouseOut = useCallback(
      (event: MouseEvent) => {
        onMouseOut?.(event, node, signal, value?.isWritable);
      },
      [onMouseOut, node, signal, value]
    );

    const svgContent = svgImages[node.svgMd5];

    const [ref, setRef] = useState<SVGGElement>(null);
    const rect = node.rects[0];
    let transform: string;

    const activeStates = useMemo(
      () =>
        evaluateSymbolRules(node, allSignalsBySignalTypeOrSignalId, signalData),
      [node, allSignalsBySignalTypeOrSignalId, signalData]
    );

    if (node.flipVertical && node.flipHorizontal) {
      transform = 'scale(-1 -1)';
    } else if (node.flipVertical) {
      transform = 'scale(1 -1)';
    } else if (node.flipHorizontal) {
      transform = 'scale(-1 1)';
    }

    useEffect(() => {
      const rootElem = ref?.getElementsByTagName('svg')[0];

      if (rootElem != null) {
        rootElem.addEventListener('mouseover', _onMouseOver);
        rootElem.addEventListener('mouseout', _onMouseOut);
        rootElem.addEventListener('click', _onClick);

        return () => {
          rootElem.removeEventListener('mouseover', _onMouseOver);
          rootElem.removeEventListener('mouseout', _onMouseOut);
          rootElem.removeEventListener('click', _onClick);
        };
      }
    }, [ref, _onMouseOver, _onMouseOut, _onClick]);

    useEffect(() => {
      const rootElem = ref?.getElementsByTagName('svg')[0];

      if (rootElem != null) {
        rootElem.setAttribute('width', node.rects[0].width + 'px');
        rootElem.setAttribute('height', node.rects[0].height + 'px');
      }

      applyStates(ref, activeStates);
    }, [activeStates, node.rects, ref]);

    let rotationTransform = '';

    if (node.rotation != null) {
      rotationTransform = ' rotate(' + node.rotation + ')';
    }
    const createContent = useCallback(() => {
      return { __html: svgContent != null ? Base64.decode(svgContent) : null };
    }, [svgContent]);

    if (!editMode && shouldHide) {
      return null;
    }

    return (
      <g
        transform={
          'translate(' +
          rect.centerX +
          ', ' +
          rect.centerY +
          ')' +
          rotationTransform
        }
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      >
        <g
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createContent()}
          ref={setRef}
          className={classNames(
            isDragging && styles.dragging,
            isHovering && styles.hovering,
            shouldHide && styles.faded
          )}
          transform={`${transform ?? ''} translate(-${rect.width / 2.0}, -${rect.height / 2.0})`}
          shapeRendering="auto"
        />
      </g>
    );
  }
);

export const ProcessMapSymbolViewOverlay = React.memo(
  ({
    node,
    selectedRectHandles,
    symbolLineConnections,
    connectionCircleRadius,
    pendingConnectionSymbol,
    showDeleteConnections,
    isHovering,
    zoom,
    isMouseDown,
    draggingSingleLinePoint
  }: ProcessMapOverlayObjectProps) => {
    const amongSelected = selectedRectHandles.some(
      (handle) => handle.objectId === node.id
    );

    const shouldRender =
      (isMouseDown && draggingSingleLinePoint && isHovering) || amongSelected;

    if (!shouldRender) {
      return;
    }

    const symbolNode = node as ProcessMapSymbolObject;

    const rect = node.rects[0];

    return (
      <g
        transform={'translate(' + rect.centerX + ', ' + rect.centerY + ')'}
        key={node.id}
      >
        <rect
          x={-rect.width * 0.5}
          y={-rect.height * 0.5}
          width={rect.width}
          height={rect.height}
          fill={'rgba(0, 0, 0, 0.05)'}
        />

        {symbolNode.connections.map((connection) => {
          const connectionPointCoords = ConnectionModelPoints(
            symbolNode.flipHorizontal,
            symbolNode.flipVertical,
            symbolNode.rotation,
            rect,
            connection
          );

          const showDeleteConnection =
            showDeleteConnections &&
            symbolLineConnections.some(
              (lineConnection) => lineConnection.connectionId === connection.id
            );
          const isPending =
            pendingConnectionSymbol?.connectionId === connection.id;
          const circleRadius = connectionCircleRadius / zoom;
          const deleteSize = processMapDeleteConnectionButtonSize / zoom;
          return (
            <Fragment key={connection.id}>
              <rect
                shapeRendering="auto"
                x={connectionPointCoords.x - circleRadius - rect.centerX}
                y={connectionPointCoords.y - circleRadius - rect.centerY}
                width={circleRadius * 2}
                height={circleRadius * 2}
                rx={circleRadius}
                ry={circleRadius}
                fill={isPending ? colors.primary1Color : colors.accent1Color}
              />
              {showDeleteConnection && (
                <image
                  href={removeIcon}
                  x={connectionPointCoords.x - deleteSize * 0.5 - rect.centerX}
                  y={connectionPointCoords.y - deleteSize * 0.5 - rect.centerY}
                  width={deleteSize}
                  height={deleteSize}
                  shapeRendering="auto"
                />
              )}
            </Fragment>
          );
        })}
      </g>
    );
  }
);
