import { useContext, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import _ from 'lodash';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  nodeHasTrait,
  useNodeChildren,
  useNodes
} from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { EditDashboardPanelEnvironment } from 'ecto-common/lib/Dashboard/panels';

type NodesDataSourceProps = {
  allowedNodeTraitIds?: string[];
  nodeIds?: string[];
  useSiblings?: boolean;
  childrenOnly?: boolean;
};

const NodesDataSource = ({
  nodeIds,
  useSiblings = false,
  childrenOnly = false,
  allowedNodeTraitIds
}: NodesDataSourceProps) => {
  const { nodeId: currentNodeId } = useContext(DashboardDataContext);
  _.noop(useSiblings);

  const allNodeIds = useMemo(() => {
    if (nodeIds && nodeIds.length > 0) {
      return nodeIds;
    }

    return [currentNodeId];
  }, [currentNodeId, nodeIds]);

  const { nodes: nodesList } = useNodes(allNodeIds);

  const parentIds = useMemo(() => {
    if (childrenOnly) {
      return _.uniq(
        _.map(nodesList, (node) => {
          return node.nodeId;
        })
      );
    } else if (useSiblings) {
      return _.uniq(
        _.compact(
          _.map(nodesList, (node) => {
            // If the node is a site, we want to show all children of the site, so we return the site node id

            if (nodeHasTrait(node, NodeTraitIds.SITE)) {
              return node.nodeId;
            }

            // Otherwise, it is a building, equipment or similar, so we return
            // the parent id of the node, meaning we want to see all siblings on
            // the same level as the node itself
            return node.parentId;
          })
        )
      );
    }

    return [];
  }, [childrenOnly, nodesList, useSiblings]);

  const allSiblingsQuery = useNodeChildren(parentIds);

  return useMemo(() => {
    let allNodes = _.uniqBy(
      nodesList.concat(allSiblingsQuery.nodeChildren),
      'nodeId'
    );

    if (allowedNodeTraitIds && allowedNodeTraitIds.length > 0) {
      allNodes = allNodes.filter((node) =>
        allowedNodeTraitIds.some((traitId) => nodeHasTrait(node, traitId))
      );
    }

    if (childrenOnly) {
      return allNodes.filter((node) => !allNodeIds.includes(node.nodeId));
    }

    return allNodes;
  }, [
    nodesList,
    allSiblingsQuery.nodeChildren,
    allowedNodeTraitIds,
    childrenOnly,
    allNodeIds
  ]);
};

export const nodesDataSourceSections: () => ModelFormSectionType<
  NodesDataSourceProps,
  EditDashboardPanelEnvironment
>[] = () => [
  {
    lines: [
      {
        models: [
          {
            key: (input) => input.nodeIds,
            label: T.admin.dashboards.panels.types.nodename.node,
            modelType: ModelType.NODE_LIST,
            placeholder:
              T.admin.dashboards.panels.types.nodename.nodeplaceholder
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.allowedNodeTraitIds,
            label: T.nodes.allowednodetraits.title,
            helpText: T.nodes.allowednodetraits.helptext,
            modelType: ModelType.OPTIONS,
            isMultiOption: true,
            options: (_unused0, _unused1, environment) => {
              return environment.nodeTraits.map((trait) => {
                return {
                  value: trait.id,
                  label: trait.name
                };
              });
            }
          }
        ]
      }
    ],
    listPriority: SectionListPriority.Location
  }
];

export default NodesDataSource;
