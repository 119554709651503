import React from 'react';
import {
  ProcessMapObjectsProps,
  ProcessMapOverlayObjectProps
} from '../ProcessMapObjectProps';
import { ProcessMapObjectTypes } from '../ProcessMapViewConstants';
import {
  ProcessMapLineView,
  ProcessMapLineViewOverlay
} from 'ecto-common/lib/ProcessMap/View/ProcessMapLineView';
import {
  ProcessMapSymbolView,
  ProcessMapSymbolViewOverlay
} from 'ecto-common/lib/ProcessMap/View/ProcessMapSymbolView';
import { ProcessMapSignalView } from 'ecto-common/lib/ProcessMap/View/ProcessMapSignalView';
import {
  ProcessMapRectView,
  ProcessMapRectViewOverlay
} from 'ecto-common/lib/ProcessMap/View/ProcessMapRectView';
import { ProcessMapTextView } from 'ecto-common/lib/ProcessMap/View/ProcessMapTextView';

type ProcessMapObjectView = {
  view: React.ComponentType<ProcessMapObjectsProps>;
  overlay?: React.ComponentType<ProcessMapOverlayObjectProps>;
};

export const objects: Record<string, ProcessMapObjectView> = {
  [ProcessMapObjectTypes.Line]: {
    view: ProcessMapLineView,
    overlay: ProcessMapLineViewOverlay
  },
  [ProcessMapObjectTypes.Symbol]: {
    view: ProcessMapSymbolView,
    overlay: ProcessMapSymbolViewOverlay
  },
  [ProcessMapObjectTypes.Text]: {
    view: ProcessMapTextView
  },
  [ProcessMapObjectTypes.Signal]: {
    view: ProcessMapSignalView
  },
  [ProcessMapObjectTypes.Rect]: {
    view: ProcessMapRectView,
    overlay: ProcessMapRectViewOverlay
  }
};
