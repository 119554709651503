import React, { useCallback } from 'react';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import _ from 'lodash';
import {
  ProcessMapSignalTextObject,
  defaultSignalSubtitleSettings,
  defaultSignalTitleSettings
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import T from 'ecto-common/lib/lang/Language';
import { ProcessMapTextViewInternal } from 'ecto-common/lib/ProcessMap/View/ProcessMapTextView';
import { ProcessMapObjectsProps } from 'ecto-common/lib/ProcessMap/ProcessMapObjectProps';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

export type ProcessMapSignalViewValue = {
  values?: { value: number }[];
  isWritable?: boolean;
};

export const ProcessMapSignalView = React.memo(
  ({
    allSignalsBySignalTypeOrSignalId,
    node: nodeIn,
    signalData,
    updateTextSize,
    objectIndex,
    selectedRectHandles,
    isLoading,
    showSignalLabelsWhenNotFound,
    isHovering,
    onClick,
    onMouseOver,
    onMouseOut
  }: ProcessMapObjectsProps) => {
    const signalTypesMap = useCommonSelector(
      (state) => state.general.signalTypesMap
    );
    const signalUnitTypesMap = useCommonSelector(
      (state) => state.general.signalUnitTypesMap
    );

    const node = nodeIn as ProcessMapSignalTextObject;
    const signal =
      allSignalsBySignalTypeOrSignalId[node.signalTypeId] ??
      allSignalsBySignalTypeOrSignalId[node.signalId];
    const value: ProcessMapSignalViewValue = signalData[signal?.signalId];
    const signalType =
      signalTypesMap[signal?.signalTypeId ?? node.signalTypeId];
    const unit = signalUnitTypesMap[signalType?.unitId];
    const amongSelected = selectedRectHandles.some(
      (handle) => handle.objectIndex === objectIndex && handle.rectIndex === 0
    );

    const _onClick = useCallback(
      (event: MouseEvent) => {
        onClick?.(event, node, signal, value?.isWritable);
      },
      [onClick, node, signal, value]
    );

    const _onMouseOver = useCallback(
      (event: MouseEvent) => {
        onMouseOver?.(event, node, signal, value?.isWritable);
      },
      [onMouseOver, node, signal, value]
    );

    const _onMouseOut = useCallback(
      (event: MouseEvent) => {
        onMouseOut?.(event, node, signal, value?.isWritable);
      },
      [onMouseOut, node, signal, value]
    );

    const isDragging = amongSelected;

    const valueNumber = _.head(value?.values)?.value;
    let subtitle: string = null;

    if (node.hideValue) {
      subtitle = null;
    } else if (node.signalTypeId == null && node.signalId == null) {
      if (showSignalLabelsWhenNotFound) {
        subtitle = T.admin.processmaps.nosignaltypeselected;
      } else {
        subtitle = '';
      }
    } else if (isLoading) {
      subtitle = '';
    } else if (signal != null || showSignalLabelsWhenNotFound) {
      subtitle = formatNumberUnit(
        valueNumber,
        unit?.unit ?? '',
        numDecimalsForUnit(unit?.unit ?? '')
      );
    } else {
      subtitle = '';
    }
    let title = '';

    if (!node.hideLabel && (signal != null || showSignalLabelsWhenNotFound)) {
      title = signal?.name ?? signalType?.name ?? '';
    }

    return (
      <ProcessMapTextViewInternal
        node={node}
        objectIndex={objectIndex}
        isDragging={isDragging}
        updateTextSize={updateTextSize}
        title={title}
        titleTextSettings={{
          ...defaultSignalTitleSettings,
          ...node.labelTextSettings
        }}
        subtitle={subtitle}
        subtitleTextSettings={{
          ...defaultSignalSubtitleSettings,
          ...node.valueTextSettings
        }}
        isHovering={isHovering}
        onClick={_onClick}
        onMouseOver={_onMouseOver}
        onMouseOut={_onMouseOut}
        shouldHide={false}
      />
    );
  }
);
