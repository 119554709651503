import React, { useMemo } from 'react';
import {
  GetAlarmUrlFunction,
  GetSignalsUrlFunction
} from 'ecto-common/lib/hooks/UrlContext';
import { NodePathItem } from 'ecto-common/lib/API/AlarmsAPIGen';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';

const AlarmDetailsColumn = ({
  tenantId,
  nodePath: _nodePath,
  getAlarmUrl,
  getSignalsUrl
}: {
  tenantId: string;
  nodePath: NodePathItem[];
  getAlarmUrl: GetAlarmUrlFunction;
  getSignalsUrl: GetSignalsUrlFunction;
}) => {
  const nodePath = useMemo(() => [...(_nodePath ?? [])].reverse(), [_nodePath]);
  const firstNoneDataPoint = nodePath.findIndex(
    (item) => item.type !== 'Datapoint'
  );
  if (firstNoneDataPoint !== -1) {
    const rootPath = nodePath[firstNoneDataPoint];
    const secondaryPath = nodePath[firstNoneDataPoint + 1];

    const subtitle = nodePath.map((pathItem) => pathItem.name).join(' > ');

    return (
      <TableColumn
        title={rootPath.name}
        titleLink={
          secondaryPath
            ? getSignalsUrl(tenantId, secondaryPath.id, rootPath.id)
            : null
        }
        subtitle={subtitle}
        subtitleLink={
          secondaryPath ? getAlarmUrl(tenantId, secondaryPath.id, null) : null
        }
      />
    );
  }
  return <> </>;
};
export default AlarmDetailsColumn;
