import {
  ProcessMapLineCapStyle,
  ProcessMapLineCapStyles,
  ProcessMapLineMode,
  ProcessMapLineModes,
  ProcessMapLineObject,
  ProcessMapLineRect,
  ProcessMapRect,
  ProcessMapRectHandle
} from '../ProcessMapViewConstants';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { xModel, yModel } from './commonModels';

const lineCapOptions: GenericSelectOption<ProcessMapLineCapStyle>[] =
  Object.values(ProcessMapLineCapStyles).map((lineCapStyle) => ({
    label: lineCapStyle,
    value: lineCapStyle
  }));

const modeOptions: GenericSelectOption<ProcessMapLineMode>[] = Object.values(
  ProcessMapLineModes
).map((value) => ({
  label: T.admin.processmaps.objecteditor.linemodes[value],
  value: value as ProcessMapLineMode
}));

export const lineSections: ModelFormSectionType<ProcessMapLineObject>[] = [
  {
    label: T.admin.processmaps.objecteditor.lineobject,
    lines: [
      {
        models: [
          {
            label: T.admin.processmaps.objecteditor.linemode,
            key: (input) => input.mode,
            modelType: ModelType.OPTIONS,
            options: modeOptions,
            placeholder: T.admin.processmaps.objecteditor.linemodes.straight
          }
        ]
      },
      {
        models: [
          {
            label: T.admin.processmaps.objecteditor.dashed,
            key: (input) => input.dashed,
            modelType: ModelType.BOOL
          }
        ]
      },
      {
        models: [
          {
            label: T.admin.processmaps.objecteditor.flipmidpoint,
            key: (input) => input.flipMidpoint,
            modelType: ModelType.BOOL,
            visible: (input: ProcessMapLineObject) =>
              (input.mode ?? ProcessMapLineModes.Straight) ===
              ProcessMapLineModes.Straight
          }
        ]
      },
      {
        models: [
          {
            label: T.common.color,
            key: (input) => input.color,
            modelType: ModelType.COLOR
          },
          {
            label: T.admin.processmaps.objecteditor.linewidth,
            key: (input) => input.lineWidth,
            modelType: ModelType.NUMBER,
            placeholder: '1'
          }
        ]
      },
      {
        models: [
          {
            label: T.admin.processmaps.objecteditor.startendcapstyle,
            key: (input) => input.startLineEndcapStyle,
            modelType: ModelType.OPTIONS,
            options: lineCapOptions,
            isClearable: true
          },
          {
            label: T.admin.processmaps.objecteditor.endendcapstyle,
            key: (input) => input.endLineCapStyle,
            modelType: ModelType.OPTIONS,
            options: lineCapOptions,
            isClearable: true
          }
        ]
      }
    ]
  }
];

export const lineRectSections = (
  selectedRectHandles: ProcessMapRectHandle[],
  showRounded: boolean
): ModelFormSectionType<ProcessMapRect[]>[] => {
  return selectedRectHandles.map((handle, handleIndex) => {
    const roundedModel: ModelDefinition<ProcessMapLineRect[]> = {
      label: T.admin.processmaps.objecteditor.rounded,
      key: (input) => input[handle.rectIndex].isRounded,
      modelType: ModelType.BOOL
    };

    const models: ModelDefinition<ProcessMapRect[]>[] = [
      xModel(handle.rectIndex),
      yModel(handle.rectIndex)
    ];

    if (showRounded) {
      models.push(roundedModel);
    }

    return {
      label: T.format(
        T.admin.processmaps.objecteditor.pointformat,
        handleIndex + 1
      ),
      lines: [{ models }]
    };
  });
};
