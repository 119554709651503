import {
  ProcessMapTextObject,
  defaultTextSettings
} from '../ProcessMapViewConstants';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { textAlignmentOption, textStyleSections } from './commonModels';

export const textSections = (
  previewNodeId: string
): ModelFormSectionType<ProcessMapTextObject>[] => {
  return [
    {
      label: T.admin.processmaps.objecteditor.textobject,
      models: [
        {
          label: T.admin.processmaps.objecteditor.text,
          key: (input) => input.text,
          modelType: ModelType.TEXT,
          multiline: true,
          rows: 4
        },
        textAlignmentOption
      ]
    },
    ...textStyleSections<ProcessMapTextObject>(
      T.admin.processmaps.objecteditor.textstyle,
      (input) => input.textSettings,
      defaultTextSettings
    ),
    {
      label: T.admin.processmaps.objecteditor.hideitem,
      initiallyCollapsed: true,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignal,
              key: (input) => input.hideWhenNoSignalTypeId,
              modelType: ModelType.SIGNAL_TYPE,
              isClearable: true,
              helpText: T.admin.processmaps.objecteditor.hiderequiredsignalhelp
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignalid,
              key: (input) => input.hideWhenNoSignalId,
              modelType: ModelType.SIGNAL,
              isClearable: true,
              helpText:
                T.admin.processmaps.objecteditor.hiderequiredsignalidhelp,
              nodeId: previewNodeId
            }
          ]
        }
      ]
    }
  ];
};
