import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import APIGen, {
  NodePropertyResponseModel,
  PropertyValidationType,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import AdminPage from 'js/components/AdminPage';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import CRUDView, {
  CRUDViewModelEnvironment,
  useSimpleCrudViewData
} from 'ecto-common/lib/CRUDView/CRUDView';
import CheckMark from 'ecto-common/lib/Icon/svg/CheckMark';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import slugify from 'slugify';
import { useAdminSelector } from 'js/reducers/storeAdmin';

function isValidRegex(pattern: string) {
  try {
    const regex = new RegExp(pattern);
    _.noop(regex);
    return true;
  } catch (e) {
    return false;
  }
}

const validationOptions: GenericSelectOption<PropertyValidationType>[] = [
  {
    label: T.properties.validationtypes.none,
    value: PropertyValidationType.None
  },
  {
    label: T.properties.validationtypes.regex,
    value: PropertyValidationType.Regex
  },
  {
    label: T.properties.validationtypes.enumlist,
    value: PropertyValidationType.EnumList
  }
];

function getPropertyModels(
  units: UnitResponseModel[]
): ModelDefinition<NodePropertyResponseModel, CRUDViewModelEnvironment>[] {
  return [
    {
      key: (input) => input.name,
      label: T.common.name,
      modelType: ModelType.TEXT,
      autoFocus: true,
      enabled: (input) => !input.isLocked,
      hasError: isNullOrWhitespace,
      onDidUpdate: (_path, value, _object, env) => {
        if (env.isNew) {
          const sluggedName = slugify(value, { lower: true });

          return [[(input) => input.id, sluggedName]];
        }
      }
    },
    {
      key: (input) => input.id,
      label: T.common.id,
      modelType: ModelType.TEXT,
      enabled: false,
      hasError: isNullOrWhitespace
    },
    {
      key: (input) => input.description,
      enabled: (input) => !input.isLocked,
      label: T.common.description,
      modelType: ModelType.TEXT
    },
    {
      key: (input) => input.validationType,
      enabled: (input) => !input.isLocked,
      label: T.properties.validationtype,
      modelType: ModelType.OPTIONS,
      options: validationOptions,
      onDidUpdate(_name, value) {
        switch (value) {
          case PropertyValidationType.None:
            return [
              [
                (input: NodePropertyResponseModel) => input.validationData,
                null
              ],
              [
                (input: NodePropertyResponseModel) =>
                  input.validationData.regexValue,
                null
              ]
            ];
          case PropertyValidationType.Regex:
            return [
              [
                (input: NodePropertyResponseModel) =>
                  input.validationData.enumValues,
                []
              ]
            ];
          case PropertyValidationType.EnumList:
            return [
              [
                (input: NodePropertyResponseModel) =>
                  input.validationData.regexValue,
                null
              ]
            ];
          default:
            return undefined;
        }
      }
    },
    {
      key: (input) => input.validationData.regexValue,
      enabled: (input) => !input.isLocked,
      visible: (input) => input.validationType === PropertyValidationType.Regex,
      label: T.properties.regex,
      hasError: (value, input) =>
        input.validationType === PropertyValidationType.Regex &&
        !isValidRegex(value),
      modelType: ModelType.TEXT
    },
    {
      key: (input) => input.validationData.enumValues,
      enabled: (input) => !input.isLocked,
      visible: (input) =>
        input.validationType === PropertyValidationType.EnumList,
      options(value: string[]) {
        return _.map(value, (enumValue) => ({
          label: enumValue,
          value: enumValue
        }));
      },
      isMultiOption: true,
      label: T.properties.enumvalues,
      modelType: ModelType.OPTIONS,
      withCreatableOption: true,
      showOptionWhenEmpty: false,
      hasError: (value, input) =>
        input.validationType === PropertyValidationType.EnumList &&
        _.isEmpty(value)
    },
    {
      key: (input) => input.unitId,
      label: T.common.unit,
      enabled: (input) => !input.isLocked,
      modelType: ModelType.OPTIONS,
      options: units.map((unit) => ({
        label: unit.name,
        value: unit.id
      })),
      isClearable: true
    },
    {
      key: (input) => input.localization.sv,
      enabled: (input) => !input.isLocked,
      label: T.format(
        T.properties.localizationformat,
        T.language.sv.toLowerCase()
      ).join(''),
      modelType: ModelType.TEXT
    },
    {
      key: (input) => input.localization.de,
      enabled: (input) => !input.isLocked,
      label: T.format(
        T.properties.localizationformat,
        T.language.de.toLowerCase()
      ).join(''),
      modelType: ModelType.TEXT
    }
  ];
}

const NodePropertiesList = () => {
  const propertiesColumns = useMemo<
    DataTableColumnProps<NodePropertyResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'name',
        label: T.properties.name,
        linkColumn: true
      },
      {
        dataKey: 'id',
        label: T.common.id,
        dataFormatter: (value: string) => {
          return (
            <CopyToClipboardTooltip valueToCopy={value}>
              {value}
            </CopyToClipboardTooltip>
          );
        }
      },
      {
        dataKey: 'description',
        label: T.common.description
      },
      {
        dataKey: 'isGlobal',
        label: T.nodes.global,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        dataFormatter: (value: boolean) => (value ? <CheckMark /> : null)
      },
      {
        dataKey: 'isLocked',
        label: T.nodes.locked,
        minWidth: 80,
        maxWidth: 80,
        align: 'center',
        dataFormatter: (value) => (value ? <Icons.Lock /> : null)
      }
    ];
  }, []);

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.listNodeProperties.path(contextSettings)
    });
  }, [contextSettings, queryClient]);

  const nodePropertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery();

  const crudData = useSimpleCrudViewData({
    listQuery: nodePropertiesQuery,
    searchItems: ['name'],
    sortBy: 'name'
  });

  useEffect(() => {
    document.title = T.nodes.nodeproperties;
  }, []);

  const createNewItem = (): NodePropertyResponseModel => ({
    id: 'new-property',
    name: 'New property',
    isGlobal: false,
    isLocked: false,
    validationType: PropertyValidationType.None
  });

  const createItemMutation = useMutation({
    mutationFn: (item: NodePropertyResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeProperties.promise(
        contextSettings,
        { properties: [item] },
        null
      )
  });

  const updateItemMutation = useMutation({
    mutationFn: (item: NodePropertyResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeProperties.promise(
        contextSettings,
        { properties: [item] },
        null
      )
  });

  const deleteItemMutation = useMutation({
    mutationFn: (item: NodePropertyResponseModel) =>
      APIGen.NodesV2.deleteNodeProperties.promise(
        contextSettings,
        { nodePropertyIds: [item.id] },
        null
      )
  });

  const enums = useAdminSelector((state) => state.general.enums);
  const propertyModels = useMemo(
    () => getPropertyModels(enums.units),
    [enums.units]
  );

  return (
    <>
      <CRUDView
        columns={propertiesColumns}
        createNewItem={createNewItem}
        itemName={'name'}
        title={T.nodes.nodeproperties}
        editTitle={T.properties.editproperty}
        addTitle={T.properties.addproperty}
        deleteItemMutation={deleteItemMutation}
        updateItemMutation={updateItemMutation}
        createItemMutation={createItemMutation}
        models={propertyModels}
        onAdded={invalidateCache}
        onUpdated={invalidateCache}
        {...crudData}
      />
    </>
  );
};

const NodeProperties = () => {
  return <AdminPage content={<NodePropertiesList />} />;
};

export default React.memo(NodeProperties);
