import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import APIGen, {
  NodePropertyTraitRelationResponseModel,
  NodeTraitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AdminPage from 'js/components/AdminPage';
import {
  BatchedGetNodesQueryKey,
  useNodeTraits
} from 'ecto-common/lib/hooks/useCurrentNode';
import CRUDView, {
  CRUDViewModelEnvironment,
  useSimpleCrudViewData
} from 'ecto-common/lib/CRUDView/CRUDView';
import CheckMark from 'ecto-common/lib/Icon/svg/CheckMark';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';
import slugify from 'slugify';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import { TreeViewNodeType } from 'ecto-common/lib/TreeView/TreeViewNode';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import Select, {
  getSelectStylesLockedOption,
  SelectOptionWithLocked
} from 'ecto-common/lib/Select/Select';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';

type TreeViewNodeTypeWithTrait = TreeViewNodeType & {
  trait: NodeTraitResponseModel;
};

const emptyColumns: DataTableColumnProps<NodeTraitResponseModel>[] = [];
const treeViewColumns = [
  {
    dataFormatter: (node: TreeViewNodeTypeWithTrait) => {
      return node.trait.name;
    },
    label: T.common.name,
    flexGrow: 1,
    linkColumn: true,
    width: 1
  },
  {
    dataKey: 'id',
    minWidth: 200,
    width: 1,
    flexGrow: 1,
    label: T.common.id,
    dataFormatter: (node: TreeViewNodeTypeWithTrait) => {
      return (
        <CopyToClipboardTooltip valueToCopy={node.trait.id}>
          {node.trait.id}
        </CopyToClipboardTooltip>
      );
    }
  },
  {
    dataKey: 'isGlobal',
    label: T.nodes.global,
    minWidth: 70,
    maxWidth: 70,
    align: HorizontalAlignments.CENTER,
    dataFormatter: (node: TreeViewNodeTypeWithTrait) =>
      node.trait.isGlobal ? <CheckMark /> : null
  },
  {
    dataKey: 'isLocked',
    label: T.nodes.locked,
    minWidth: 80,
    maxWidth: 80,
    align: HorizontalAlignments.CENTER,
    dataFormatter: (node: TreeViewNodeTypeWithTrait) =>
      node.trait.isLocked ? <Icons.Lock /> : null
  }
];

const NodeTraitsList = () => {
  const traitsQuery = useNodeTraits();

  const propertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery();

  const language = getSelectedLanguage();

  const propertyOptions = useMemo(() => {
    return sortByLocaleCompare(
      _.map(propertiesQuery.data?.items, (property) => {
        return {
          label: property.localization?.[language] ?? property.name,
          value: property.id,
          locked: false
        };
      }),
      'label'
    );
  }, [propertiesQuery.data, language]);

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
    });

    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
    });
    queryClient.invalidateQueries({
      queryKey: [BatchedGetNodesQueryKey]
    });
  }, [contextSettings, queryClient]);

  const existingIds = useMemo(() => {
    return new Set(traitsQuery.data?.items?.map((item) => item.id));
  }, [traitsQuery.data?.items]);

  const traitModels: ModelDefinition<
    NodeTraitResponseModel,
    CRUDViewModelEnvironment
  >[] = useMemo(
    () => [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT,
        enabled: (input) => !input.isLocked,
        hasError: isNullOrWhitespace,
        onDidUpdate: (_path, value, _object, env) => {
          if (env.isNew) {
            const sluggedName = slugify(value, { lower: true });

            return [[(input) => input.id, sluggedName]];
          }
        }
      },
      {
        key: (input) => input.id,
        label: T.common.id,
        modelType: ModelType.TEXT,
        enabled: false,
        errorText: (value, _val, env) => {
          if (env.isNew && existingIds.has(value)) {
            return T.traits.error.idexists;
          } else if (isNullOrWhitespace(value)) {
            return true;
          }

          return null;
        }
      },
      {
        key: (input) => input.parentId,
        label: T.traits.parenttrait,
        modelType: ModelType.OPTIONS_TREE,
        enabled: (input) => !input.isLocked,
        options: traitsQuery.data?.items?.map((trait) => {
          return {
            label: trait.name,
            value: trait.id,
            parentValue: trait.parentId,
            selectable: true
          };
        })
      },
      {
        key: (input) => input.propertyTraitRelationModels,
        label: T.nodes.nodeproperties,
        modelType: ModelType.CUSTOM,
        isMultiOption: true,
        options: propertyOptions,
        render: (
          props: CustomModelEditorProps<
            NodeTraitResponseModel,
            CRUDViewModelEnvironment,
            NodePropertyTraitRelationResponseModel[]
          >,
          model,
          input
        ) => {
          // All of the options in propertyOptions are optional. We want to remove those that have a locked equivalent in the input.
          const options = propertyOptions.filter((option) => {
            return !input.propertyTraitRelationModels.some((relation) => {
              return (
                relation.nodePropertyId === option.value && relation.isLocked
              );
            });
          });

          const allOptions = [
            ...input.propertyTraitRelationModels
              .filter((x) => x.isLocked)
              .map((relation) => {
                const correspondingOption = propertyOptions.find(
                  (option) => option.value === relation.nodePropertyId
                );
                return {
                  ...correspondingOption,
                  isLocked: true
                };
              }),
            ...options
          ];

          const value = _.orderBy(
            input.propertyTraitRelationModels.map((relation) => {
              return allOptions.find(
                (option) => option.value === relation.nodePropertyId
              );
            }),
            'isLocked',
            'asc'
          );

          const selectStyles =
            getSelectStylesLockedOption<SelectOptionWithLocked<string>>();

          return (
            <KeyValueGeneric keyText={model.label}>
              <Select<SelectOptionWithLocked<string>, true>
                isMulti
                additionalStyles={selectStyles}
                options={allOptions}
                value={value}
                onChange={(newValue) => {
                  const newNonLockedIds = newValue
                    .filter((option) => !option.isLocked)
                    .map((option) => option.value);

                  const lockedExistingValues =
                    input.propertyTraitRelationModels.filter(
                      (relation) => relation.isLocked
                    );

                  const newRelations = [
                    ...lockedExistingValues,
                    ...newNonLockedIds.map((id) => {
                      return {
                        nodePropertyId: id,
                        nodeTraitId: input.id,
                        isLocked: false
                      };
                    })
                  ];

                  props.updateItem(newRelations);
                }}
              />
            </KeyValueGeneric>
          );
        },
        isHorizontal: true
      }
    ],
    [propertyOptions, traitsQuery.data?.items, existingIds]
  );

  const crudData = useSimpleCrudViewData({
    listQuery: traitsQuery,
    searchItems: ['name'],
    sortBy: 'name'
  });

  const createItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeTraits.promise(
        contextSettings,
        { nodeTraits: [item] },
        null
      )
  });

  const updateItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeTraits.promise(
        contextSettings,
        { nodeTraits: [item] },
        null
      )
  });

  const deleteItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.deleteNodeTraits.promise(
        contextSettings,
        { nodeTraitIds: [item.id] },
        null
      )
  });

  const createNewItem = (): NodeTraitResponseModel => ({
    id: 'new-trait',
    name: 'New trait',
    isGlobal: false,
    isLocked: false,
    propertyTraitRelationModels: []
  });

  const nodesWithChildren = useMemo(() => {
    const result: Record<string, boolean> = {};
    for (const node of traitsQuery.data?.items ?? []) {
      if (node.parentId != null) {
        result[node.parentId] = true;
      }
    }

    return result;
  }, [traitsQuery.data?.items]);

  const shouldDisableDeleteForItem = (item: NodeTraitResponseModel) => {
    return item.isLocked || nodesWithChildren[item.id];
  };

  return (
    <CRUDView
      columns={emptyColumns}
      createNewItem={createNewItem}
      itemName={'name'}
      title={T.nodes.nodetraits}
      editTitle={T.traits.edittrait}
      addTitle={T.traits.addtrait}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
      models={traitModels}
      onAdded={invalidateCache}
      onUpdated={invalidateCache}
      shouldDisableDeleteForItem={shouldDisableDeleteForItem}
      treeViewColumns={treeViewColumns}
      createTreeViewNodes={(items) => {
        const rootNodes = items.filter((item) => item.parentId == null);

        const childrenForNode: Record<string, NodeTraitResponseModel[]> = {};

        for (const item of items) {
          if (item.parentId != null) {
            if (childrenForNode[item.parentId] == null) {
              childrenForNode[item.parentId] = [];
            }

            childrenForNode[item.parentId].push(item);
          }
        }

        const createTreeViewTraits = (
          node: NodeTraitResponseModel,
          path: string
        ): TreeViewNodeTypeWithTrait => {
          const children = childrenForNode[node.id] ?? [];
          const childTreeNodes = children.map((child) =>
            createTreeViewTraits(child, path + node.name + '/')
          );

          return {
            id: node.id,
            name: node.name,
            path: path + node.name,
            children: childTreeNodes.length > 0 ? childTreeNodes : null,
            trait: node
          };
        };

        return rootNodes.map((rootNode) => {
          return createTreeViewTraits(rootNode, '');
        });
      }}
      {...crudData}
    />
  );
};

const NodeTraits = () => {
  useEffect(() => {
    document.title = T.nodes.nodetraits;
  }, []);

  return <AdminPage content={<NodeTraitsList />} />;
};

export default React.memo(NodeTraits);
