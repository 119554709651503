import React, { useCallback, useContext, useEffect, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import UUID from 'uuidjs';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import { text, updateInputItem } from 'ecto-common/lib/ModelForm/formUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import IdentityServiceAPIGenV2, {
  UserDashboardModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import ModelType from '../ModelForm/ModelType';
import { KeyValueGeneric } from '../KeyValueInput/KeyValueGeneric';
import NodeTypeOptions from '../NodeTypeOptions/NodeTypeOptions';
import { ModelDefinition } from '../ModelForm/ModelPropType';
import PresentationAPIGen, {
  TenantDashboardModel
} from '../API/PresentationAPIGen';

export const BasicProperties: ModelDefinition<TenantDashboardModel>[] = [
  text<DashboardType>(
    (input) => input.name,
    T.admin.dashboards.column.name,
    isNullOrWhitespace
  ),
  text<DashboardType>(
    (input) => input.description,
    T.admin.dashboards.column.description,
    null
  )
];

export const DashboardModels: ModelDefinition<TenantDashboardModel>[] = [
  ...BasicProperties,
  {
    key: (input) => input.allowedNodeTypes,
    modelType: ModelType.CUSTOM,
    label: T.common.allowednodetypes,
    placeholder: T.common.allowednodetypes,
    hasError: (input) => _.isEmpty(input),

    render: (props, model) => (
      <KeyValueGeneric keyText={model.label}>
        <NodeTypeOptions
          placeholder={model.placeholder}
          nodeTypes={props.rawValue}
          onNodeTypesChanged={props.updateItem}
          hasError={props.hasError}
        />
      </KeyValueGeneric>
    )
  }
];

const saveUserDashboardPromise = (
  contextSettings: ApiContextSettings,
  dashboard: UserDashboardModel
) => {
  return IdentityServiceAPIGenV2.User.createOrUpdateUserDashboards.promise(
    contextSettings,
    { dashboards: [dashboard] },
    null
  );
};

type DashboardType = TenantDashboardModel | UserDashboardModel;

interface EditDashboardPropertiesModalProps {
  isOpen?: boolean;
  onCloseModal(): void;
  dashboard?: DashboardType;
  onDashboardUpdated(editedDashboard: DashboardType, isNew: boolean): void;
  isNew?: boolean;
  isCopying?: boolean;
  reducedProperties?: boolean;
}

const EditDashboardPropertiesModal = ({
  isOpen,
  onCloseModal,
  dashboard,
  onDashboardUpdated,
  isNew,
  isCopying,
  reducedProperties
}: EditDashboardPropertiesModalProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [editDashboard, setEditDashboard] = useState<DashboardType>(null);

  const { contextSettings } = useContext(TenantContext);

  const models = reducedProperties ? BasicProperties : DashboardModels;

  const queryClient = useQueryClient();

  const saveDashboardMutation = useMutation({
    mutationFn: async () => {
      if (!reducedProperties) {
        const editedDashboard = editDashboard as TenantDashboardModel;
        return PresentationAPIGen.TenantDashboards.createTenantDashboard.promise(
          contextSettings,
          { ...editDashboard, data: JSON.stringify(editedDashboard.data) },
          null
        );
      }

      if (isCopying) {
        return saveUserDashboardPromise(contextSettings, {
          ...editDashboard,
          id: UUID.generate()
        });
      }
      return saveUserDashboardPromise(contextSettings, {
        ...editDashboard,
        id: editDashboard?.id ?? UUID.generate()
      });
    },

    onSuccess: (response) => {
      setHasChanges(false);
      onCloseModal();
      queryClient.invalidateQueries({
        queryKey:
          PresentationAPIGen.TenantDashboards.listTenantDashboards.path(
            contextSettings
          )
      });
      if (!reducedProperties) {
        onDashboardUpdated(response as TenantDashboardModel, true);
      } else {
        onDashboardUpdated(editDashboard, isNew);
      }
    },

    onError: () => {
      toastStore.addErrorToast(
        isNew
          ? T.admin.dashboards.save.failure
          : T.admin.dashboards.change.failure
      );
    }
  });

  useEffect(() => {
    setEditDashboard(_.cloneDeep(dashboard));
  }, [isOpen, dashboard]);

  const isValid = modelFormIsValid(models, editDashboard);
  const isLoading = false;

  const onUpdateDashboard = useCallback((key: string[], value: unknown) => {
    setEditDashboard((oldDashboard) => {
      setHasChanges(true);
      return updateInputItem(oldDashboard, key, value);
    });
  }, []);

  const onSaveDashboard = useCallback(() => {
    if (isNew) {
      saveDashboardMutation.mutate();
    } else {
      onCloseModal();
      onDashboardUpdated(editDashboard, isNew);
    }
  }, [
    editDashboard,
    isNew,
    onCloseModal,
    onDashboardUpdated,
    saveDashboardMutation
  ]);

  let title = isNew ? T.admin.dashboards.add : T.admin.dashboards.edit;
  let icon = isNew ? Icons.Add : Icons.Edit;
  if (isCopying) {
    title = T.admin.dashboards.copy;
    icon = Icons.Copy;
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onCloseModal}
      disableActionButton={!isValid || (!isNew && !hasChanges)}
      isLoading={saveDashboardMutation.isPending}
      actionText={isCopying ? T.common.copy : undefined}
      onConfirmClick={onSaveDashboard}
      headerIcon={icon}
      title={title}
    >
      <ModelForm
        input={editDashboard}
        models={models}
        onUpdateInput={onUpdateDashboard}
        isLoading={isLoading}
      />
    </ActionModal>
  );
};

export default EditDashboardPropertiesModal;
