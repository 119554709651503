import {
  ProcessMapSignalTextObject,
  defaultSignalSubtitleSettings,
  defaultSignalTitleSettings
} from '../ProcessMapViewConstants';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { textAlignmentOption, textStyleSections } from './commonModels';

export const signalSections = (
  previewNodeId: string
): ModelFormSectionType<ProcessMapSignalTextObject>[] => [
  {
    label: T.admin.processmaps.objecteditor.signalobject,
    models: [
      {
        label: T.signals.signaltype,
        key: (input) => input.signalTypeId,
        modelType: ModelType.SIGNAL_TYPE,
        nodeId: previewNodeId,
        isClearable: true
      },
      {
        label: T.signals.signalid.label,
        helpText: T.signals.signalid.description,
        key: (input) => input.signalId,
        modelType: ModelType.SIGNAL,
        nodeId: previewNodeId,
        placeholder: T.signals.signalid.label,
        isClearable: true
      },
      {
        label: T.admin.processmaps.objecteditor.hidelabel,
        key: (input) => input.hideLabel,
        modelType: ModelType.BOOL
      },
      {
        label: T.admin.processmaps.objecteditor.hidevalue,
        key: (input) => input.hideValue,
        modelType: ModelType.BOOL
      },
      textAlignmentOption
    ]
  },
  ...textStyleSections<ProcessMapSignalTextObject>(
    T.admin.processmaps.objecteditor.labeltextstyle,
    (input) => input.labelTextSettings,
    defaultSignalTitleSettings
  ),
  ...textStyleSections<ProcessMapSignalTextObject>(
    T.admin.processmaps.objecteditor.valuetextstyle,
    (input) => input.valueTextSettings,
    defaultSignalSubtitleSettings
  )
];
