import _ from 'lodash';

import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import T from 'ecto-common/lib/lang/Language';
import { option } from 'ecto-common/lib/ModelForm/formUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { ResourceType } from 'ecto-common/lib/constants/index';
import APIGen, {
  AddOrUpdateDemandSideManagementProviderRequestModel,
  DemandSideManagementType
} from 'ecto-common/lib/API/APIGen';
import { AdminToolImplementationType } from '../useEquipmentTools';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

const DemandSideManagementTypeOptions: GenericSelectOption<DemandSideManagementType>[] =
  [
    {
      value: DemandSideManagementType.Heating,
      label: T.admin.equipment.powercontroltype.heating
    },
    {
      value: DemandSideManagementType.Cooling,
      label: T.admin.equipment.powercontroltype.cooling
    }
  ];

const errorIfNotSet = <ValueType>(value: ValueType) => value == null;

// Create a select a single signal model
const model: ModelDefinition<AddOrUpdateDemandSideManagementProviderRequestModel>[] =
  [
    option(
      (input) => input.demandSideManagementType,
      T.admin.demandsidemanagement.type,
      DemandSideManagementTypeOptions,
      errorIfNotSet
    )
  ];

const EMPTY_INPUT: Partial<AddOrUpdateDemandSideManagementProviderRequestModel> =
  {
    demandSideManagementType: null
  };

const DemanSideManagementTool: AdminToolImplementationType<AddOrUpdateDemandSideManagementProviderRequestModel> =
  {
    type: ToolSignalProviders.DEMAND_SIDE_MANAGEMENT,
    name: ToolSignalProviderTranslations[
      ToolSignalProviders.DEMAND_SIDE_MANAGEMENT
    ],
    accessRights: [ResourceType.CORE],
    allowDelete: true,
    allowDeploy: false,
    model,
    emptyInput: EMPTY_INPUT,
    save: APIGen.AdminDemandSideManagement
      .addOrUpdateDemandSideManagementProviders.promise,
    delete: (contextSettings, providerIds, abortSignal) =>
      APIGen.AdminDemandSideManagement.deleteDemandSideManagementProviders.promise(
        contextSettings,
        { providerIds },
        abortSignal
      ),
    load: (contextSettings, equipmentIds, abortSignal) =>
      APIGen.AdminDemandSideManagement.getDemandSideManagementProvidersByNodeId.promise(
        contextSettings,
        {
          nodeIds: equipmentIds
        },
        abortSignal
      )
  };

export default DemanSideManagementTool;
