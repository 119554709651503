import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import Select from 'ecto-common/lib/Select/Select';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDefinitionInternal,
  ModelDynamicOptionsTreeProperty,
  TreeSelectOption
} from 'ecto-common/lib/ModelForm/ModelPropType';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './TreeSelect.module.css';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import classNames from 'classnames';
import dimensions from 'ecto-common/lib/styles/dimensions';
import {
  ActionMeta,
  OnChangeValue,
  OptionProps,
  PropsValue,
  StylesConfig
} from 'react-select';

export type TreeOptionsModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = object
> = {
  modelType: typeof ModelType.OPTIONS_TREE;
  isClearable?: boolean;
  // All available options that can be changed for the current item.
  options?: ModelDynamicOptionsTreeProperty<
    ObjectType,
    EnvironmentType,
    ValueType
  >;
  withDivider?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, ValueType>;

type InternalOption = TreeSelectOption & {
  isParent: boolean;
  level: number;
};

type TreeSelectProps<IsMulti extends boolean = boolean> = {
  onChange?: (
    newValue: OnChangeValue<TreeSelectOption, IsMulti>,
    actionMeta: ActionMeta<TreeSelectOption>
  ) => void;
  value: PropsValue<TreeSelectOption>;
  options: TreeSelectOption[];
  styles?: StylesConfig<TreeSelectOption, true>;
  isMulti?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
};

function TreeSelect<IsMulti extends boolean = false>({
  options,
  value,
  onChange,
  isMulti,
  isDisabled = false,
  isClearable = true,
  ...otherProps
}: TreeSelectProps<IsMulti>) {
  const [open, setIsOpen] = useState<Record<string, boolean>>({});

  const internalOptions = useMemo(() => {
    const childrenForNode: Record<string, TreeSelectOption[]> = {};

    for (const option of options) {
      if (option.parentValue != null) {
        if (childrenForNode[option.parentValue] == null) {
          childrenForNode[option.parentValue] = [];
        }

        childrenForNode[option.parentValue].push(option);
      }
    }

    const rootNodes = options.filter((x) => x.parentValue == null);

    const result: InternalOption[] = [];

    const recurse = (node: TreeSelectOption, level: number) => {
      const children = childrenForNode[node.value];
      result.push({
        ...node,
        isParent: children != null,
        level: level,
        selectable: false
      });

      if (children != null) {
        for (const child of children) {
          recurse(child, level + 1);
        }
      }
    };

    for (const rootNode of rootNodes) {
      recurse(rootNode, 0);
    }

    return result;
  }, [options]);

  const internalValue: InternalOption[] = useMemo(() => {
    if (value == null) {
      return null;
    } else if (!isMulti) {
      const valueOption = value as TreeSelectOption;
      return [internalOptions.find((x) => x.value === valueOption.value)];
    } else if (_.isArray(value)) {
      const valueOptions = (value as TreeSelectOption[]).map((x) => x.value);

      return valueOptions.map((valueOption) =>
        internalOptions.find((x) => x.value === valueOption)
      );
    }
  }, [value, isMulti, internalOptions]);

  const isParentOpen = (optionValue: string): boolean => {
    if (optionValue === '' || optionValue == null) {
      return true;
    }

    return open[optionValue] === true;
  };

  const toggleOpen = (data: InternalOption) => {
    setIsOpen({
      ...open,
      [data.value]: !open[data.value]
    });
  };

  const CustomOption = ({
    data,
    innerProps,
    innerRef,
    selectProps
  }: OptionProps<InternalOption, IsMulti>) => {
    const hasInputValue =
      selectProps.inputValue != null && selectProps.inputValue !== '';

    const className = [
      styles.treeOption,
      !isParentOpen(data.parentValue) && !hasInputValue
        ? styles.treeOptionHidden
        : ''
    ].join(' ');

    const arrow = data.isParent && (
      <Icons.NavigationArrowRight
        className={classNames(styles.icon, open[data.value] && styles.open)}
      />
    );
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={className}
        style={{
          paddingLeft: hasInputValue
            ? dimensions.standardMargin
            : `${data.level * dimensions.standardMargin + dimensions.standardMargin}px`
        }}
      >
        <p>{data.label}</p>
        <div className={styles.expand} />
        {data.isParent && (
          <div
            className={styles.arrowContainer}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleOpen(data);
            }}
          >
            {arrow}
          </div>
        )}
      </div>
    );
  };

  const onChangeFromInternal = useCallback(
    (
      e: OnChangeValue<TreeSelectOption, IsMulti>,
      actionMeta: ActionMeta<TreeSelectOption>
    ) => {
      if (isMulti) {
        const values = _.map(e as TreeSelectOption[], 'value');
        const newOptions = options.filter((option) =>
          values.includes(option.value)
        );

        // @ts-ignore-next-line
        onChange(newOptions, actionMeta);
      } else {
        const correspondingOption = options.find(
          (option) => option.value === (e as TreeSelectOption)?.value
        );

        // @ts-ignore-next-line
        onChange(correspondingOption, actionMeta);
      }
    },
    [isMulti, onChange, options]
  );

  return (
    <Select<InternalOption, IsMulti>
      isDisabled={isDisabled}
      options={internalOptions}
      components={{ Option: CustomOption }}
      value={internalValue}
      onChange={onChangeFromInternal}
      hideSelectedOptions={false}
      isClearable={isClearable}
      // @ts-ignore-next-line
      isMulti={isMulti}
      {...otherProps}
    />
  );
}

export default typedMemo(TreeSelect);
