import { GenericSelectOption } from '../Select/Select';
import _ from 'lodash';
import T from '../lang/Language';

const featureFlags = ['integrations', 'eiot-signals'] as const;

export type FeatureFlag = (typeof featureFlags)[number];

const featureFlagToString: Record<FeatureFlag, string> = {
  integrations: T.featureflag.integrations,
  'eiot-signals': 'E.IOT Signals'
};

const featureFlagEnabled = (flag: FeatureFlag) =>
  localStorage.getItem(flag) === 'true';
const setFeatureFlagEnabled = (flag: FeatureFlag, enabled: boolean) =>
  localStorage.setItem(flag, enabled ? 'true' : 'false');

export type FeatureFlagState = Record<FeatureFlag, boolean>;

let featureFlagState: FeatureFlagState = {
  integrations: featureFlagEnabled('integrations'),
  'eiot-signals': featureFlagEnabled('eiot-signals')
};

export const featureFlagOptions: GenericSelectOption<FeatureFlag>[] =
  featureFlags.map((flag) => ({
    value: flag,
    label: featureFlagToString[flag]
  }));

let listeners: (() => void)[] = [];

const emitChange = () => {
  for (const listener of listeners) {
    listener();
  }
};

export const featureFlagStore = {
  getSnapshot(): FeatureFlagState {
    return featureFlagState;
  },

  subscribe(listener: () => void) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },

  update(newFeatureFlags: Record<FeatureFlag, boolean>) {
    for (const flag of featureFlags) {
      if (newFeatureFlags[flag] == null) {
        setFeatureFlagEnabled(flag, false);
      } else {
        setFeatureFlagEnabled(flag, newFeatureFlags[flag] === true);
      }
    }
    featureFlagState = newFeatureFlags;

    emitChange();
  }
};
