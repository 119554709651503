import React, { useCallback, useMemo, useRef, useState } from 'react';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import createAPIFetch, {
  createExternalAPIFetch
} from 'ecto-common/lib/utils/createAPIFetch';
import localStore from 'store';
import { DASHBOARD_LOCAL_STORAGE_KEY } from 'ecto-common/lib/utils/persistentNodeState';
import { clearReduxState } from 'ecto-common/lib/actions/clearReduxState';
import { CACHE_KEY_PREFIX } from 'ecto-common/lib/utils/cacheKeys';
import { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import {
  AuthenticationResult,
  IPublicClientApplication
} from '@azure/msal-browser';
import { UserModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import {
  setAPIFetch,
  setExternalAPIFetch
} from 'ecto-common/lib/utils/APIFetchInstance';
import { setHasSelectedAPIEnvironment } from '../utils/apiEnvironment';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';

interface UserContainerProps {
  children?: React.ReactNode;
  msalConfiguration?: IPublicClientApplication;
}

const UserContainer = ({ children, msalConfiguration }: UserContainerProps) => {
  const dispatch = useCommonDispatch();

  const [authData, setAuthData] = useState<AuthenticationResult>(null);
  const [userData, setUserData] = useState<UserModel>(null);
  const prevAccessToken = useRef(null);

  const signIn = useCallback(
    (_data: AuthenticationResult) => {
      if (prevAccessToken.current === _data?.accessToken) {
        return;
      }

      prevAccessToken.current = _data?.accessToken;

      // _data == null means that we are signing out
      if (_data == null) {
        dispatch(clearReduxState());
        setUserData(null);
        const keysToRemove: string[] = [];

        localStore.each((_value, key) => {
          if (key.startsWith(CACHE_KEY_PREFIX)) {
            keysToRemove.push(key);
          }
        });

        for (const keyToRemove of keysToRemove) {
          localStore.remove(keyToRemove);
        }

        setHasSelectedAPIEnvironment(false);
        localStore.remove(DASHBOARD_LOCAL_STORAGE_KEY);
      }
      setAuthData(_data);
    },
    [dispatch]
  );

  useMemo(() => {
    const apiEnvironment = getApiEnvironment();
    setExternalAPIFetch(createExternalAPIFetch());
    setAPIFetch(
      createAPIFetch(msalConfiguration, [apiEnvironment.scopes.gateway], signIn)
    );
  }, [msalConfiguration, signIn]);

  const value = useMemo(
    () => ({
      username: authData?.account?.username,
      userId: authData?.account?.localAccountId,
      signedIn: authData != null,
      userData,
      setUserData,
      signIn
    }),
    [authData, userData, signIn]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default React.memo(UserContainer);
