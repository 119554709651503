import T from 'ecto-common/lib/lang/Language';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';

export type DashboardType = {
  id?: string;
  name?: string;
  userId?: string;
  description?: string;
  dashboardId?: string;
};

export const InitialDashboard: DashboardType = {
  name: T.admin.dashboards.initialname,
  description: null,
  dashboardId: undefined
};

export const InitialDashboardFileData: DashboardFile = {
  version: 1,
  panels: []
};

export type DashboardFile = {
  version: number;
  panels: DashboardPanel[];
};

export type DashboardId = string;

export type DashboardData = {
  jsonData: DashboardFile;
  info: DashboardType;
};
