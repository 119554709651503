import React, {
  useCallback,
  useContext,
  useRef,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';

import ProcessMapEditor from 'ecto-common/lib/ProcessMaps/ProcessMapEditor';
import {
  ProcessMapsManagementRoute,
  TemplateManagementParams,
  TemplateManagementRoute
} from 'js/utils/routeConstants';
import {
  useNavigate,
  useParams,
  matchPath,
  useSearchParams,
  generatePath
} from 'react-router-dom';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { NavLink } from 'react-router-dom';

import { NestedTenantContainer } from 'ecto-common/lib/Application/TenantContainer';
import { EventHubServiceContainer } from 'ecto-common/lib/EventHubConnection/EventHubService';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useImmer } from 'use-immer';
import {
  ProcessMapState,
  initProcessMapState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { ProcessMapEditorActions } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActions';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import EditButton from 'ecto-common/lib/Button/EditButton';
import ProcessMapEditLibraryModal from 'ecto-common/lib/ProcessMaps/ProcessMapEditLibraryModal';
import { useProcessMapLibrary } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorHooks';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { Draft } from 'immer';
import { ProcessMapDataType } from 'ecto-common/lib/ProcessMaps/ProcessMapDataHandling';
import NodeTypeProcessMapsRelationsModal from './NodeTypeProcessMapsRelationsModal';
import { usePromptFunction } from 'ecto-common/lib/hooks/useBlockerListener';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import PresentationAPIGen, {
  AssignedPictureModel,
  PictureModel
} from 'ecto-common/lib/API/PresentationAPIGen';
import CRUDView, { useCrudViewData } from 'ecto-common/lib/CRUDView/CRUDView';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import NodeTypeOptions from 'ecto-common/lib/NodeTypeOptions/NodeTypeOptions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { emptyProcessMapDocument } from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { Base64 } from 'js-base64';
import { TEMPLATE_ADMIN_ID } from 'ecto-common/lib/constants';
import { createNodeTreeStore } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';
import ConfirmDeleteDialogWithDependencies, {
  ConfirmDeleteDialogWithDependenciesBaseProps
} from 'ecto-common/lib/ConfirmDeleteDialogWithDependencies/ConfirmDeleteDialogWithDependencies';
import { useDependencies } from 'ecto-common/lib/utils/presentationLibrary';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';

export const processMapModels: ModelDefinition<ProcessMapDataType>[] = [
  {
    key: (input) => input.name,
    modelType: ModelType.TEXT,
    label: T.common.name,
    placeholder: T.common.name,
    hasError: (input) => _.isEmpty(input)
  },
  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.common.description,
    placeholder: T.common.description
  },
  {
    key: (input) => input.allowedNodeTypes,
    modelType: ModelType.CUSTOM,
    label: T.common.allowednodetypes,
    placeholder: T.common.allowednodetypes,
    hasError: (input) => _.isEmpty(input),

    render: (props, model) => (
      <KeyValueGeneric keyText={model.label}>
        <NodeTypeOptions
          placeholder={model.placeholder}
          nodeTypes={props.rawValue}
          onNodeTypesChanged={props.updateItem}
          hasError={props.hasError}
        />
      </KeyValueGeneric>
    )
  }
];

const ProcessMapEditorPage = ({
  itemId,
  parentName,
  parentLink,
  itemLink
}: {
  itemId: string;
  parentName: string;
  parentLink: string;
  itemLink: (itemId: string) => string;
}) => {
  const hasDataForIdRef = useRef({
    [itemId]: false
  });

  const itemQuery = PresentationAPIGen.Pictures.getPicture.useQuery({
    id: itemId
  });
  const processMap = itemQuery.data;

  const [processMapState, setProcessMapState] = useImmer<ProcessMapState>(null);

  // Workaround to make sure the process map is only fetched once.
  // When going to process map list, component will be unmounted, and
  // when going back to process map, the request will be re-triggered.
  if (itemQuery.data != null && !hasDataForIdRef.current[itemId]) {
    hasDataForIdRef.current[itemId] = true;
    const initialData = itemQuery.data?.data;
    setProcessMapState(initProcessMapState(initialData));
  }
  const { tenantId, contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();
  const saveRequest = useMutation({
    mutationFn: (item: AssignedPictureModel) =>
      PresentationAPIGen.Pictures.updatePicture.promise(
        contextSettings,
        { id: item.id },
        item,
        null
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: PresentationAPIGen.Pictures.getPicture.path(contextSettings, {
          id: itemId
        })
      });

      setProcessMapState((draft: Draft<ProcessMapState>) =>
        ProcessMapEditorActions.clearHasChanges(draft)
      );
    },
    meta: { errorString: T.admin.processmaps.error.update }
  });

  const { libraryRequest, libraryItems } = useProcessMapLibrary();
  const canChangeTenant = tenantId === TEMPLATE_ADMIN_ID;

  const [searchParams, setSearchParams] = useSearchParams();
  const previewTenantId = canChangeTenant
    ? (searchParams.get('tenantId') ?? tenantId)
    : tenantId;
  const previewNodeId = searchParams.get('nodeId');

  const setPreviewTenantId = useCallback(
    (newTenantId: string) => {
      setSearchParams(
        (oldParams) => {
          oldParams.set('tenantId', newTenantId);
          return oldParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const setPreviewNodeId = useCallback(
    (newNodeId: string) => {
      setSearchParams(
        (oldParams) => {
          if (newNodeId == null) {
            oldParams.delete('nodeId');
            return oldParams;
          }

          oldParams.set('nodeId', newNodeId);
          return oldParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const titleElement = itemQuery.isPending ? (
    <Spinner size={SpinnerSize.SMALL} />
  ) : (
    <>{processMap?.name ?? T.common.unknownerror}</>
  );
  useEffect(() => {
    if (itemQuery.isPending) {
      document.title = `${parentName} > ${T.common.loading}`;
    } else {
      document.title = `${parentName} > ${itemQuery?.data?.name ?? T.common.unknownerror}`;
    }
  }, [itemQuery?.data?.name, itemQuery.isPending, parentName]);

  const title = (
    <>
      <NavLink to={parentLink}>{parentName}</NavLink>
      &nbsp;&gt;&nbsp;{titleElement}
    </>
  );

  const onSave = useCallback(
    (data: string) => {
      saveRequest.mutate({
        ...processMap,
        data
      });
    },
    [processMap, saveRequest]
  );

  const disableEdit =
    tenantId !== TEMPLATE_ADMIN_ID && processMap?.tenant === TEMPLATE_ADMIN_ID;

  usePromptFunction(
    useCallback(
      ({ nextLocation }) => {
        const newParams = matchPath<
          TemplateManagementParams,
          typeof TemplateManagementRoute.path
        >(TemplateManagementRoute.path, nextLocation.pathname)?.params;
        return newParams?.itemId !== itemId ? T.admin.form.unsavedstate : null;
      },
      [itemId]
    ),
    !disableEdit && processMapState?.hasChanges
  );

  const nodeTreeStore = useMemo(() => {
    _.noop(previewTenantId);
    return createNodeTreeStore();
  }, [previewTenantId]);

  const [
    isEditProcessMapModalOpen,
    showEditProcessMapModal,
    hideEditProcessMapModal
  ] = useSimpleDialogState();

  const onSaveFullProcessMap = useCallback(
    (data: PictureModel) => {
      saveRequest.mutate(data, {
        onSuccess: () => {
          hideEditProcessMapModal();
        }
      });
    },
    [hideEditProcessMapModal, saveRequest]
  );

  const navigate = useNavigate();
  const [copyProcessMap, setCopyProcessMap] = useState(null as PictureModel);
  const onCopyProcessMap = useCallback(() => {
    setCopyProcessMap({ ...processMap, id: undefined });
  }, [processMap]);
  const createItemMutation =
    PresentationAPIGen.Pictures.createPicture.useMutation({
      onSuccess: (newProcessMap) => {
        setCopyProcessMap(null);
        navigate(itemLink(newProcessMap.id));
        queryClient.invalidateQueries({
          queryKey:
            PresentationAPIGen.Pictures.listPictures.path(contextSettings)
        });
      },
      meta: { errorString: T.common.error }
    });

  return (
    <>
      <ToolbarContentPage
        title={title}
        showLocationPicker={false}
        wrapContent={false}
        removeTopMargin
        removeSideMargin
        removeBottomMargin
      >
        <LoadingContainer
          isLoading={
            itemQuery.isPending ||
            saveRequest.isPending ||
            libraryRequest.isPending
          }
        >
          {(itemQuery.error != null || libraryRequest.error != null) && (
            <div>{T.admin.processmaps.failedtoload}</div>
          )}
          {processMapState != null && libraryRequest.data != null && (
            <NestedTenantContainer
              tenantId={previewTenantId}
              nodeTreeStore={nodeTreeStore}
            >
              <EventHubServiceContainer>
                <ProcessMapEditor
                  onShowEditProcessMap={showEditProcessMapModal}
                  onCopyProcessMap={onCopyProcessMap}
                  onSave={onSave}
                  processMapState={processMapState}
                  setProcessMapState={setProcessMapState}
                  library={libraryItems}
                  previewTenantId={previewTenantId}
                  setPreviewTenantId={
                    canChangeTenant ? setPreviewTenantId : null
                  }
                  disableEdit={disableEdit}
                  nodeTreeStore={nodeTreeStore}
                  previewNodeId={previewNodeId}
                  setPreviewNodeId={setPreviewNodeId}
                />
                <ModelFormDialog<PictureModel, false>
                  onModalClose={() => setCopyProcessMap(null)}
                  input={copyProcessMap}
                  addTitle={T.admin.processmaps.copy}
                  actionText={T.common.save}
                  models={processMapModels}
                  isObjectNew={() => true}
                  isLoading={createItemMutation.isPending}
                  saveAsArray={false}
                  saveInput={(item) => createItemMutation.mutate(item)}
                />
                <ModelFormDialog<PictureModel, false>
                  onModalClose={hideEditProcessMapModal}
                  input={isEditProcessMapModalOpen ? processMap : null}
                  editTitle={T.admin.processmaps.edit}
                  actionText={T.common.save}
                  models={processMapModels}
                  isObjectNew={() => false}
                  isLoading={saveRequest.isPending}
                  saveAsArray={false}
                  saveInput={onSaveFullProcessMap}
                />
              </EventHubServiceContainer>
            </NestedTenantContainer>
          )}
        </LoadingContainer>
      </ToolbarContentPage>
    </>
  );
};

const DependencyItemDialog = (
  props: ConfirmDeleteDialogWithDependenciesBaseProps & {
    item?: ProcessMapDataType;
  }
) => {
  const assignedItemsQuery =
    PresentationAPIGen.Nodes.getPictureNodeAssignments.useQuery(
      {
        id: props.item?.id
      },
      {
        enabled: props.isOpen && props.item?.id != null
      }
    );

  const dependencyItems = useDependencies({
    dependencies: assignedItemsQuery.data?.items
  });

  return (
    <ConfirmDeleteDialogWithDependencies<ProcessMapDataType>
      dependencyItems={dependencyItems.nodes}
      {...props}
      isLoading={
        assignedItemsQuery.isLoading ||
        props.isLoading ||
        dependencyItems.isLoading
      }
    />
  );
};

const columns: DataTableColumnProps<{ name?: string; description?: string }>[] =
  [
    {
      dataKey: 'name',
      label: T.common.name,
      linkColumn: true,
      canSort: true
    },
    {
      dataKey: 'description',
      label: T.common.description,
      canSort: true
    }
  ];

const createNewItem = (): PictureModel => ({
  data: Base64.encode(JSON.stringify(emptyProcessMapDocument))
});

const ProcessMapsPage = () => {
  useEffect(() => {
    document.title = T.admin.processmaps.title;
  }, []);

  const { tenantId, contextSettings } = useContext(TenantContext);
  const navigate = useNavigate();

  const openEditItemPage = useCallback(
    (item: AssignedPictureModel) => {
      navigate(
        generatePath(ProcessMapsManagementRoute.path, {
          tenantId,
          itemId: item.id
        })
      );
    },
    [navigate, tenantId]
  );
  const listQueryHook =
    PresentationAPIGen.Pictures.listPictures.useInfiniteQuery;

  const updateItemMutation = useMutation({
    mutationFn: (item: AssignedPictureModel) =>
      PresentationAPIGen.Pictures.updatePicture.promise(
        contextSettings,
        { id: item.id },
        item,
        null
      )
  });

  const createItemMutation =
    PresentationAPIGen.Pictures.createPicture.useMutation({
      onSuccess: (item) => {
        openEditItemPage(item);
      }
    });

  const deleteItemMutation = useMutation({
    mutationFn: (item: AssignedPictureModel) => {
      return PresentationAPIGen.Pictures.deletePicture.promise(
        contextSettings,
        { id: item.id },
        null
      );
    }
  });

  const [isEditRelationsOpen, showEditRelations, hideEditRelations] =
    useSimpleDialogState();

  const [isShowingSymbolDialog, showSymbolDialog, hideSymbolDialog] =
    useSimpleDialogState();

  // Special case for template tenant
  const canEditProcessMapSymbols = tenantId === TEMPLATE_ADMIN_ID;

  const toolbarItems = (
    <>
      {canEditProcessMapSymbols && (
        <ToolbarItem>
          <EditButton onClick={showSymbolDialog}>
            {T.admin.processmaps.editsymbollibrary}...
          </EditButton>
        </ToolbarItem>
      )}

      <ToolbarItem>
        <EditButton onClick={showEditRelations}>
          {T.admin.processmaps.editnodetyperelation}...
        </EditButton>
      </ToolbarItem>
    </>
  );

  const shouldDisableEdit = useCallback(
    (item: PictureModel) => {
      return (
        tenantId !== TEMPLATE_ADMIN_ID && item.tenant === TEMPLATE_ADMIN_ID
      );
    },
    [tenantId]
  );

  const params = useParams<TemplateManagementParams>();

  const crudData = useCrudViewData({
    listQueryHook,
    searchItems: ['name'],
    sortBy: 'name'
  });

  if (params.itemId != null) {
    return (
      <ProcessMapEditorPage
        itemId={params.itemId}
        key={params.itemId}
        parentName={T.admin.processmaps.title}
        parentLink={generatePath(ProcessMapsManagementRoute.path, {
          tenantId
        })}
        itemLink={(itemId: string) =>
          generatePath(ProcessMapsManagementRoute.path, { tenantId, itemId })
        }
      />
    );
  }

  return (
    <>
      <CRUDView
        models={processMapModels}
        columns={columns}
        createNewItem={createNewItem}
        itemName={'name'}
        title={T.admin.processmaps.title}
        editTitle={T.admin.processmaps.edit}
        addTitle={T.admin.processmaps.add}
        deleteItemMutation={deleteItemMutation}
        updateItemMutation={updateItemMutation}
        createItemMutation={createItemMutation}
        toolbarItems={toolbarItems}
        onClickRow={openEditItemPage}
        shouldDisableEditForItem={shouldDisableEdit}
        shouldDisableDeleteForItem={shouldDisableEdit}
        {...crudData}
        confirmDeleteDialog={DependencyItemDialog}
      />
      <NodeTypeProcessMapsRelationsModal
        isOpen={isEditRelationsOpen}
        onModalClose={hideEditRelations}
        itemSelectComponent={SelectProcessMapDialog}
      />
      <ProcessMapEditLibraryModal
        isOpen={isShowingSymbolDialog}
        onModalClose={hideSymbolDialog}
      />
    </>
  );
};

export default ProcessMapsPage;
