import _ from 'lodash';
import NodeNamePanel, { NodeNamePanelData } from './NodeNamePanel';
import TextPanel, { TextPanelData } from './TextPanel';
import LineChartPanel, { LineChartPanelData } from './LineChartPanel';
import BarChartPanel, { BarChartPanelData } from './BarChartPanel';
import ProcessMapPanel, { ProcessMapPanelData } from './ProcessMapPanel';
import AlarmListPanel, { AlarmListPanelData } from './AlarmListPanel';
import SignalListPanel, { SignalListPanelData } from './SignalListPanel';
import AlarmCountPanel, { AlarmCountPanelData } from './AlarmCountPanel';
import LocationMapPanel, {
  LocationMapPanelData
} from './LocationMapPanel/LocationMapPanel';
import GaugePanel, { GaugePanelData } from './GaugePanel';
import PanelDataType from 'ecto-common/lib/Dashboard/panels/PanelDataType';
import BarChartComparePanel, {
  BarChartComparePanelData
} from './BarChartComparePanel';
import ScatterChartPanel, { ScatterChartPanelData } from './ScatterChartPanel';
import AlarmStatusList, { AlarmStatusPanelData } from './AlarmStatusPanel';
import SignalCurveEditorPanel, {
  SignalCurveEditorPanelData
} from './SignalCurveEditorPanel';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { CustomPanelProps, PanelTarget } from 'ecto-common/lib/Dashboard/Panel';
import {
  PanelMigration,
  PanelType
} from 'ecto-common/lib/Dashboard/panels/PanelTypes';
import {
  EquipmentTypeResponseModel,
  NodeTraitResponseModel,
  SignalProviderType,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import ProcessMapSignalsTablePanel, {
  ProcessMapSignalsTablePanelData
} from './ProcessMapSignalsTablePanel';
import FilesPanel, { FilesPanelData } from './FilesPanel';

export type PanelDataDefinition = {
  sections?: ModelFormSectionType<object>[];
  helpPath: string;
  minWidth?: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dataSourceSectionsConfig?: Record<string, any>;
  emptyTargets?: Record<string, PanelTarget>;
  migrations?: PanelMigration[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  modelPlugins?: Record<string, any>;
  dataType?: PanelDataType;
  fixedSizeInRelayout?: boolean;
  helpText?: string;
};

export type PanelDefinition = {
  type: PanelType;
  component: React.FC<CustomPanelProps>;
  data: PanelDataDefinition;
};

export type EditDashboardPanelEnvironment = {
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  equipmentTypes: EquipmentTypeResponseModel[];
  signalProviderTypes: SignalProviderType[];
  signalNamesModels: ModelDefinition<
    SignalInputType,
    EditDashboardPanelEnvironment
  >[];
  allSignalInputs: SignalInputType[];
  nodeTraits: NodeTraitResponseModel[];
};

/**
 * Export a type mapped to component info dictionary { [type]: {component:*, type: string} }
 * So dashboard/panel creation can look up components by type.
 */
const panels: Record<string, PanelDefinition> = _.keyBy(
  [
    {
      type: PanelType.LOCATION_MAP,
      component: LocationMapPanel,
      data: LocationMapPanelData
    },
    {
      type: PanelType.SIGNALS_TABLE,
      component: ProcessMapSignalsTablePanel,
      data: ProcessMapSignalsTablePanelData
    },
    {
      type: PanelType.ALARM_LIST,
      component: AlarmListPanel,
      data: AlarmListPanelData
    },
    {
      type: PanelType.SIGNAL_LIST,
      component: SignalListPanel,
      data: SignalListPanelData
    },
    {
      type: PanelType.ALARM_COUNT,
      component: AlarmCountPanel,
      data: AlarmCountPanelData
    },
    {
      type: PanelType.LINE_CHART,
      component: LineChartPanel,
      data: LineChartPanelData
    },
    {
      type: PanelType.BAR_CHART,
      component: BarChartPanel,
      data: BarChartPanelData
    },
    {
      type: PanelType.SCATTER_CHART,
      component: ScatterChartPanel,
      data: ScatterChartPanelData
    },
    {
      type: PanelType.BAR_CHART_COMPARE,
      component: BarChartComparePanel,
      data: BarChartComparePanelData
    },
    {
      type: PanelType.NODE_NAME,
      component: NodeNamePanel,
      data: NodeNamePanelData
    },
    {
      type: PanelType.PROCESS_MAP,
      component: ProcessMapPanel,
      data: ProcessMapPanelData
    },
    { type: PanelType.GAUGE, component: GaugePanel, data: GaugePanelData },
    { type: PanelType.TEXT, component: TextPanel, data: TextPanelData },
    {
      type: PanelType.ALARM_STATUS_LIST,
      component: AlarmStatusList,
      data: AlarmStatusPanelData
    },
    {
      type: PanelType.SIGNAL_CURVE_SIGNAL_EDITOR,
      component: SignalCurveEditorPanel,
      data: SignalCurveEditorPanelData
    },
    {
      type: PanelType.FILES,
      component: FilesPanel,
      data: FilesPanelData
    }
  ],
  'type'
);

export default panels;

export const panelsWithCharts = _.reduce(
  panels,
  (result, { type, data }) => {
    if (data.dataType === PanelDataType.CHART) {
      result.push(type);
    }
    return result;
  },
  []
);
